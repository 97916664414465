import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ValidatorService } from 'src/app/services/validator.service';
import * as $ from "jquery";
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  sub_email: any;
  m_sub_email: any;
  message: any;
  constructor(private spinner: NgxSpinnerService, private apiService: ApiService,
    private validatorService: ValidatorService, public appService: AppService) { }

  sendSubscription() {
    this.m_sub_email = '';
    if (this.sub_email === '' || this.sub_email === undefined) {
      this.m_sub_email = 'Email required';
      return false;
    } else if (!this.validatorService.isEmailValid(this.sub_email)) {
      this.m_sub_email = 'Enter valid email';
      return false;
    } else {
      this.spinner.show('footer');
      this.apiService.subscribeByEmail(this.sub_email).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide('footer');
          if (res.status) {
            this.message = res.message;
            this.showMessage();
            this.sub_email = '';
          }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('footer');

        }
      );
    }
  }

  onTCClick() {
    const url = 'https://app.myzolve.com/term-of-service.html';
    window.open(url, "_system", "location=yes,enableViewportScale=yes,hidden=no");
  }
  
  onPCClick() {
    const url = 'https://app.myzolve.com/privacy-policy.html';
    window.open(url, "_system", "location=yes,enableViewportScale=yes,hidden=no");
  }

  showMessage() {
    $("#footer-popup").addClass("show");
    $("#footer-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }
  onClose() {
    $("#footer-popup").removeClass("show");
    $("#footer-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  ngOnInit() {
  }

  facebook() {
    window.open('https://www.facebook.com/myzolve', '_blank');
  }

  twitter() {
    window.open('https://twitter.com/zolvework', '_blank');
  }

  linkedin() {
    window.open('https://www.linkedin.com/showcase/trueemployeeadvocacy', '_blank');
  }

  instagram() {
    window.open('https://www.instagram.com/myzolve/', '_blank');
  }
}
