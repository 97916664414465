import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private _router: Router, private appService: AppService) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {

    if (localStorage.getItem('token') ) {
      //&& this.appService.getUserType() != 'Anonymous'
      const res = await this.appService.getTokenVerificationResult();
      console.log(res);
      if (res.status) {
        return true;
      }
   
      localStorage.clear();
      this._router.navigate(['/login']);
      return false;
    } else {
      localStorage.clear();
      this._router.navigate(['/login']);
      return false;
    }

  

  }
}
