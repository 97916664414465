import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  notes: any[] = [];
  dataLoaded = false;
  showNote: any = '';
  note: any; note_error: any = '';
  showMore :boolean;
  noteListingPage = 1;

  constructor(public appService: AppService, private apiService: ApiService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute) {

  }

  onAddNoteBtnClick() {
    $("#allCases-popup").addClass("show");
    $("#allCases-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onClose() {
    this.note = undefined;
    $("#allCases-popup").removeClass("show");
    $("#allCases-popup").css("display", "none");
    $(".modal-backdrop").remove();

  }

  ngOnInit() {

    this.getNotes();
  }

  getNotes(page = 1, event?: any) {

    this.dataLoaded = false;
    this.spinner.show('notes');
    if (event) {
      this.noteListingPage = this.noteListingPage + 1;
      this.apiService.getNotes(this.appService.getToken(), this.route.snapshot.queryParams.case_id, this.noteListingPage).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            if (res.data.length == 0) {
              this.showMore = false;
            } else {
              this.notes.push(...res.data);
              this.showMore =true;
            }

             if(res.data.length < 9){
              this.showMore = false;
             }
           
          }
          this.dataLoaded = true;

          this.spinner.hide('notes');
        },
        (err: any) => {
          this.dataLoaded = true;

          console.log(err);
          this.spinner.hide('notes');
        }
      );
    } else {
      this.apiService.getNotes(this.appService.getToken(), this.route.snapshot.queryParams.case_id, page).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            if (res.data.length == 0) {
              this.showMore = false;
            }
            this.notes = res.data;

            if(res.data.length < 9){
              this.showMore = false;
             }else{
              this.showMore =true;
             }
          }
          this.dataLoaded = true;

          this.spinner.hide('notes');
        },
        (err: any) => {
          this.dataLoaded = true;

          console.log(err);
          this.spinner.hide('notes');
        }
      );
    }
  }

  addNote() {
    this.note_error = '';
    if (!this.note) {
      this.note_error = 'Write something to add note';
      return false;
    }
    this.spinner.show('notes');
    this.apiService.addNote(this.note, this.route.snapshot.queryParams.case_id, this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.showSuccessToast(res.message);
          this.dataLoaded = false;
          this.apiService.getNotes(this.appService.getToken(), this.route.snapshot.queryParams.case_id, 1).subscribe(
            (res: any) => {
              console.log(res);
              if (res.status) {
                this.notes = res.data;
              }
              this.dataLoaded = true;

              this.spinner.hide('notes');
              this.onClose();
            },
            (err: any) => {
              this.dataLoaded = true;

              console.log(err);
              this.spinner.hide('notes');
              this.onClose();

            }
          );
        }
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide('notes');
        this.onClose();

      }
    );
  }

}
