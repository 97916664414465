import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
	message: any = ''; m_message: any = '';
	type: any = 'Advocate'; m_type: any = '';
	// success_message: any; err_message: any;
	constructor(private route: ActivatedRoute, public appService: AppService, private router: Router,
		private apiService: ApiService, private spinner: NgxSpinnerService) { }

	ngOnInit() {
	}
	sendFeedback() {
		this.m_message = ''; this.m_type = '';
		if (this.type == '') {
			this.m_type = "Please select type.";
			return false;
		}
		if (this.message == '') {
			this.m_message = "Please type some feedback.";
			return false;
		}
		this.spinner.show('feedback');
		let token = this.appService.getToken();

		this.apiService.addFeedBack(token, this.message, this.type).subscribe((result: any) => {
			this.spinner.hide('feedback');
			this.appService.showSuccessToast('You have successfully add the feedback');
			// this.success_message = 'You have successfully add the feedback';
			this.message = '';
			this.type = '';
			// var obj = this;
			this.type = 'Suggestion';
			// setTimeout(function () { obj.success_message = ''; }, 5000);

		}, (err: any) => {
			this.spinner.hide('feedback');
			this.appService.showSuccessToast('There is some error occured');
			// this.err_message = 'There is some error occured';
			// var obj = this;
			this.type = 'Suggestion';
			// setTimeout(function () { obj.err_message = ''; }, 5000);

		});

	}
}
