import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";
import { ValidatorService } from 'src/app/services/validator.service';
//import { setInterval } from 'timers';
import { AuthService, SocialUser, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  interval:any;
  loginMessage: any;
  sub_email: any;
  message: any;
  m_sub_email: any;
  subscription_email:any='';
  sub_email_error:any='';
  email: any;
  password: any;
  isLogin: boolean = false;
  isLoginPopUpShow: boolean = false;
  profileImage = '';
  userName = '';

  device_id: any;
  device_type: any;
  firstName: any;
  lastName: any;
  phone: any;
  confirmpassword: any;
  firstName_error: any;
  lastName_error: any;
  phone_error: any;
  email_error: any;
  password_error: any;
  confirmpassword_error: any;
  termsC: boolean = false;
  termsC_error: any;
  constructor(private spinner: NgxSpinnerService, private apiService: ApiService, private router: Router, public authService: AuthService,
    private appService: AppService, private validatorService: ValidatorService) { }

  ngOnInit() {
  //  localStorage.clear();
    if(this.router.url.includes('login-s')){
      this.router.navigate(['/signup']);
  
        }
        if(this.router.url.includes('whats-keeping-self')){
          this.router.navigate(['/whats-keeping-self']);
      
            }
  }

  socialSignIn(socialProvider: string) {
    let socialPlatformProvider;
    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.authService.signIn(socialPlatformProvider).then(socialusers => {
      console.log('yesss');
      console.log(socialusers);
      this.register(socialusers);
    }, (err: any) => {
      console.log('errrrrrrrrr');
      console.log(err);

    });
  }
  register(userData: any) {
    this.spinner.show('header');
    this.apiService.socialLogin(userData.name, 'browser', userData.email,
      '', localStorage.getItem('notificationToken'), 'browser', '', '').subscribe(
        (result: any) => {
          if (result.status) {
            localStorage.clear();
            this.isLogin = true;
           
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                this.appService.setToken(result.jwtoken);
                this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');
                  this.router.navigate(['/home']);
                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/chat-listing']);
                  } else {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/profile']);
                  }
                }
                this.email = '';
                this.password = '';
              },
              (err: any) => {
                this.spinner.hide('header');
                this.appService.showErrorToast(err.error.message);
              }
            );
          }
        },
        (err: any) => {
          this.appService.showErrorToast(err.error.message);
        }
      );
  }
  validateLoginFields() {
    this.email_error = ''; this.password_error = '';
    // this.login_error = '';
    if (this.email === '' || this.email === undefined) {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter valid email address';
      return false;
    }
    if (this.password === '' || this.password === undefined) {
      this.password_error = 'Password required';
      return false;
    }
    return true;
  }

  onLoginBtnClick() {
    if (this.validateLoginFields()) {
      this.spinner.show('header');
      this.apiService.login(this.email, this.password, localStorage.getItem('notificationToken'), 'browser').subscribe(
        (result: any) => {
          if (result.status) {
            localStorage.clear();
            this.isLogin = true;
            
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                this.appService.setToken(result.jwtoken);
                // this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');

                  this.router.navigate(['/home']);
                  this.spinner.hide('header');

                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/chat-listing']);
                    this.spinner.hide('header');

                  } else {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/profile']);
                    this.spinner.hide('header');

                  }
                }
                this.email = '';
                this.password = '';
              }, (err: any) => {
                this.spinner.hide('header');
                // alert(err.error);
                // this.login_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              });
          }
        }, (err: any) => {
          this.spinner.hide('header');
          // this.login_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }
  onForgotBtnClick() {
    $(".now-close").trigger('click');
    $("#login-popup .close").trigger('click');
    this.router.navigate(['/forgot-password']);
  }
}
