import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";

@Component({
  selector: 'app-case-listing',
  templateUrl: './case-listing.component.html',
  styleUrls: ['./case-listing.component.css']
})
export class CaseListingComponent implements OnInit {

  cases = [];
  casee: any;
  casesCount: number;
  caseListingPage = 1;
  userType: any;
  userId: any;
  showMore: boolean = false;
  rate: any = 0;

  constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute, public appService: AppService, private router: Router,
    private apiService: ApiService) {
    if (this.route.snapshot.queryParams.user_id) {
      this.userId = this.route.snapshot.queryParams.user_id;
    } else {
      if (localStorage.getItem('token')) {
        if (localStorage.getItem('userType') == 'Counselor' || localStorage.getItem('userType') == 'Admin') {
          this.router.navigate(['/chat-listing']);
        } else if (localStorage.getItem('userType') == 'Anonymous') {
          this.router.navigate(['/councillor-home']);
        }
      }
    }
    this.getCases(this.caseListingPage);
  }

  onRateBtnClick(casee: any) {
    this.casee = casee;
    this.openRatePopUp();
  }

  onRateChange(ev: any) {
    console.log(ev);
    this.rate = ev.newValue;
  }

  openRatePopUp() {
    $("#rate").addClass("show");
    $("#rate").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onClose(r: any) {
    this.rate = r;
    $("#rate").removeClass("show");
    $("#rate").css("display", "none");
    $(".modal-backdrop").remove();
    this.rateCase();
  }

  rateCase() {
    this.spinner.show('spinner');
    this.apiService.rateCase(this.appService.getToken(), this.casee.id, this.rate).subscribe(
      (res: any) => {
        this.appService.showSuccessToast(res.message);
        this.apiService.getCases(localStorage.getItem('token'), 1, this.userId).subscribe(
          (result: any) => {
            if (result.cases.length !== 0 && result.count > 10) {
              this.showMore = true;
            } else {
              this.showMore = false;
            }
            this.cases = result.cases;
            this.casesCount = result.count;
            console.log(result);
            this.spinner.hide('spinner');
          },
          (err) => {
            console.log(err.error);
            this.spinner.hide('spinner');
          }
        );
      },
      (err) => {
        this.spinner.hide('spinner');
        console.log(err.error);
      }
    );
  }

  onCaseCardClick(caseId: any, tab: any) {
    this.router.navigate(['/case-details'], {
      queryParams: {
        case_id: caseId,
        selected_tab: tab
      }
    });
    // this.isUsersDetailOpen = false;
    // this.userId = userId;
    // console.log(userId);
    // this.getCases(1, null, userId);
  }

  ngOnInit() {

  }

  onCaseCloseBtnClick(caase: any) {
    this.spinner.show('spinner');
    this.apiService.closeCase(this.appService.getToken(), caase.id).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.showSuccessToast(res.message);
          this.apiService.getCases(this.appService.getToken(), 1, this.userId.toString()).subscribe(
            (result: any) => {
              this.cases = result.cases;
              this.casesCount = result.count;
              this.spinner.hide('spinner');
            },
            (err) => {
              this.spinner.hide('spinner');
            }
          );
        }
      },
      (err: any) => {
        this.spinner.hide('spinner');
        console.log(err);
      }
    );
  }


  getCases(page = 1, event?: any) {
    this.spinner.show('spinner');
    if (event) {
      this.caseListingPage += 1;
      console.log(this.caseListingPage);
      this.apiService.getCases(localStorage.getItem('token'), this.caseListingPage.toString(), this.userId).subscribe(
        (result: any) => {
          if (result.cases.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.cases.push(...result.cases);
          console.log(this.cases);
          this.casesCount = result.count;
          this.spinner.hide('spinner');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner');
        }
      );
    } else {

      this.apiService.getCases(localStorage.getItem('token'), page.toString(), this.userId).subscribe(
        (result: any) => {
          if (result.cases.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.cases = result.cases;
          this.casesCount = result.count;
          console.log(result);
          this.spinner.hide('spinner');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner');
        }
      );

    }
  }
}
