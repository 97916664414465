import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import $ from "jquery";
import { not } from '@angular/compiler/src/output/output_ast';
import { ApiService } from '../services/api.service';
import * as firebase from 'firebase';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireDB: AngularFireDatabase, private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging, private appService: AppService,
    private router: Router,private apiService: ApiService) {

  }

  /**
  * update token in firebase database
  * 
  * @param userId userId as a key 
  * @param token token as a value
  */
  // updateToken(userId, token) {
  //   // we can change this function to request our backend service
  //   this.angularFireAuth.authState.pipe(take(1)).subscribe(
  //     () => {
  //       const data = {};
  //       data[userId] = token;
  //       console.log(data);
  //       this.angularFireDB.object('fcmTokens/').update(data)
  //     })
  // }

  /**
  * request permission for notification from firebase cloud messaging
  * 
  * @param userId userId
  */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        localStorage.setItem('notificationToken', token);
        
        // this.updateToken(userId, token);
        if(this.appService.getToken()){
        this.apiService.updateToken(this.appService.getToken(),token).subscribe(
          (res: any) => {
          })
        }
      },
      (err) => {
        
       this.angularFireMessaging.requestPermission.subscribe(
        (too) => {
          console.log('------------------zzzzzzzzzzzzzzzzzzzzz.'+too);
          this.requestPermission()
        })
      alert('Your notification are blocked')
      }
    );
  }

  /**
  * hook method when new notification received in foreground
  */
  receiveMessage() {



    this.angularFireMessaging.messaging.subscribe(
      (messaging: any) => {
        messaging.onMessageCallback = (payload: any) => {
        }
      })



   // alert('called method');
    // this.angularFireMessaging.messages.subscribe(
    //   (payload: any) => {
    //     console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);

    //     const notification = payload.data;
    //     console.log(notification);

    //     // general call notification

    //     if (notification.type == 'gen_call_request') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     } else if (notification.type == 'gen_call_accept') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case call notification

    //     if (notification.type == 'call_request') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // chat notification

    //     if (notification.type == 'chat_request') {
    //       if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {
    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });

    //       }
    //     }

    //     if (notification.type == 'chat_request_accepted') {
    //       if (this.appService.getUserType() == 'User') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });

    //       }
    //     }

    //     if (notification.type == 'end_chat') {
    //       this.router.navigate(['/chat-listing']);
    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     if (notification.type == 'chat_message') {
    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // transfer credit notification

    //     if (notification.type == 'transfer_credit') {

    //       this.appService.setCredit(+notification.credit);

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case message notification

    //     if (notification.type == 'case_message') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case document notification

    //     if (notification.type == 'case_document') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // add case notification

    //     if (notification.type == 'add_case') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     if (notification.type == 'assign_case') {
    //       if (this.appService.getUserType() != 'Admin') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });


    //       }

    //     }

    //     if (notification.type == 'reassign_case') {
    //       if (this.appService.getUserType() != 'Admin') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });
    //       }

    //     }
    //   }
    // );
  }
}
