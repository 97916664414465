import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";

@Component({
  selector: 'app-selfhelp',
  templateUrl: './selfhelp.component.html',
  styleUrls: ['./selfhelp.component.css']
})
export class SelfhelpComponent implements OnInit {

  selfHelpTopic: any;
  isStarted: boolean;
  questionCount: number = 0;
  question: any = {};
  questions = [];
  selectedOption: any;
  solution: any;
  solutionPosted: boolean;
  rate: any = 0;
  response: any;

  selectedSolutionSegment: any = 'overview';

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService,
    private appService: AppService) {
    this.selfHelpTopic = {
      id: this.route.snapshot.queryParams.self_help_topic_id,
      name: this.route.snapshot.queryParams.self_help_topic_name
    };
    this.onStartedBtnClick()
  }

  onStartedBtnClick() {
    this.isStarted = true;
    this.questionCount += 1;
    console.log(this.questionCount);
    this.apiService.getFirstQuestion(this.appService.getToken(), this.selfHelpTopic.id).subscribe(
      (result: any) => {
        if (result.status) {
          this.question = result.question;
          this.questions.push(this.question);
          if (result.question && Object.keys(result.question).length == 0) {
            this.router.navigate(['/whats-keeping'], {
              queryParams: {
                'noquestion': true
              }
            });
          } else {
            for (let value of this.question.options) {
              console.log(value.preSelected);
              if (value['preSelected'] == 1) {
                this.change_option_id(value['optionId'])
              }
            }
            console.log(this.questions);
          }

        }
        // loader.dismiss();
      },
      (err) => {
        console.log(err.error);
        // loader.dismiss();
      }
    );
  }

  onOverviewTabClick() {
    this.selectedSolutionSegment = 'overview';
  }

  onRecommendationTabClick() {
    this.selectedSolutionSegment = 'recommendation';
  }

  onResourcesTabClick() {
    this.selectedSolutionSegment = 'resources';
  }

  onOptionChange(ev: any) {
    console.log(ev.target.value);
    for (let value of this.question.options) {
      if (value['optionId'] == ev.target.value) {
        value['class'] = 'custom-checked in-item interactive hydrated';
      } else {
        value['class'] = 'not-checked in-item interactive hydrated';
      }
    }
    console.log(ev.target.value);
    this.selectedOption = ev.target.value;
  }

  change_option_id(val) {
    for (let value of this.question.options) {
      if (value['optionId'] == val) {
        value['class'] = 'custom-checked in-item interactive hydrated';
        this.selectedOption = value['optionId'];
      } else {
        value['class'] = 'not-checked in-item interactive hydrated';
      }
    }
  }
  onChatStartBtnClick() {
    this.router.navigate(['/chat']);
  }
  onNotNowClick() {
    this.router.navigate(['/whats-keeping']);
  }

  onNextBtnClick() {
    //this.selectedOption =1;
    console.log(this.questionCount);
    this.apiService.getSolutionOrNextQuestion(this.appService.getToken(), this.selectedOption).subscribe(
      (result: any) => {
        if (result.status) {
          if (!result.question && !result.solution) {
            this.router.navigate(['/whats-keeping'], {
              queryParams: {
                'noquestion': true
              }
            });
          }
          if (result.solution) {
            this.solution = result.solution;
          } else if (result.question) {
            this.question = result.question;
            this.questions.push(this.question);
            for (let value of this.question.options) {
              if (value['preSelected'] == 1) {
                this.change_option_id(value['optionId'])
              }
            }

            console.log(this.questions);
          }
        }
        console.log(result);
        this.questionCount += 1;

        // loader.dismiss();
      },
      (err: any) => {
        console.log(err.error);
        // loader.dismiss();
      }
    );
  }

  onBackBtnClick() {
    // this.questionCount -= 1;
    // if (this.questionCount === 0) {
    //   this.isStarted = false;
    // } else if (this.questionCount === -1) {
    // this.questionCount = -1;
    this.router.navigate(['/leftmenu/tabs/whats-keeping']);
    // }
    // console.log(this.questionCount);
  }

  ngOnInit() {
  }

  postResponse(response: any) {
    this.apiService.postSolutionResponse(this.appService.getToken(), this.solution.id,
      this.selfHelpTopic.id, response, this.rate).subscribe(
        (result: any) => {
          if (result.status) {
            this.solutionPosted = true;
            this.question = result.question;
            this.questions.push(this.question);
            this.router.navigate(['/whats-keeping'], {
              queryParams: {
           //     'solutionPosted': true
              }
            });
          }
          console.log(result);
          // loader.dismiss();
        },
        (err: any) => {
          console.log(err.error);
          // loader.dismiss();
        }
      );
  }

  onRateChange(ev: any){
    console.log(ev);
    this.rate = ev.newValue;
  }

  openRatePopUp() {

    $("#rate").addClass("show");
    $("#rate").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.appService.showSuccessToast('Your response has been posted!');
       
  }

  onClose(r: any) {
    this.rate = r;
    $("#rate").removeClass("show");
    $("#rate").css("display", "none");
    $(".modal-backdrop").remove();
    this.postResponse(this.response);
  }

  onNoBtnClick() {
    this.response = 'No';
    this.openRatePopUp();
  }

  onYesBtnClick() {
    this.response = 'Yes';
    this.openRatePopUp();
  }

  isChecked(value) {
    if (value == 1) {
      return true;
    } else {
      return false;
    }
  }

}
