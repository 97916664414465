import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-all-cases',
  templateUrl: './all-cases.component.html',
  styleUrls: ['./all-cases.component.css']
})
export class AllCasesComponent implements OnInit {
  caseSearch: any;
  caseListingPage = 1;
  cases: any[]; case_id: any;
  casesCount: number; counselor: any;
  alertInputs = []; showMore: boolean = false;
  councilors: any[];
  user_id: any;
  lastCounselorId: any;
  // success_message: any = ''; error_message: any = '';
  constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute, public appService: AppService, private router: Router,private notificationService: NotificationService,
    private apiService: ApiService) {
    if (appService.getUserType() != 'Admin') {
      this.router.navigate(['/home']);
    }
    else {
      this.getCases();
      this.getCounsilors();
    }
  }

  ngOnInit() {
    this.notificationService.requestPermission();
  }

  onCaseCardClick(casee: any) {
    console.log(casee);
    console.log(casee.counselor);
    this.router.navigate(['/case-details'], {
      queryParams: {
        case_id: casee.id,
        isCaseAssigned: casee.counselor,
        selected_tab: 'details',
        isAdminView: true,
      }
    });
  }

  submitCaseSearch(page = 1, event?: any) {

    if (event) {
      this.caseListingPage += 1;
      this.apiService.getAllCasesByAdmin(this.appService.getToken(), this.caseListingPage.toString(), this.caseSearch).subscribe(
        (result: any) => {
          console.log(result);
          if (result.status) {
            this.cases.push(...result.cases);
            console.log(this.cases);
            this.casesCount = result.count;
            event.target.complete();

          }
        },
        (err) => {
          console.log(err.error);
        }
      );
    } else {
      this.caseListingPage = 1;
      this.spinner.show('spinner-allcases');
      this.apiService.getAllCasesByAdmin(this.appService.getToken(), page.toString(), this.caseSearch).subscribe(
        (result: any) => {
          console.log(result);
          if (result.status) {
            this.casesCount = result.count;
            this.cases = result.cases;
          }
          this.spinner.hide('spinner-allcases');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner-allcases');

        }

      );
    }
  }
  getCases(page = 1, event?: any) {
    if (event) {
      this.caseListingPage += 1;
      console.log(this.caseListingPage);
      this.spinner.show('spinner-allcases');
      this.apiService.getAllCasesByAdmin(this.appService.getToken(), this.caseListingPage.toString(), this.caseSearch).subscribe(
        (result: any) => {
          this.cases.push(...result.cases);
          console.log(this.cases);
          this.casesCount = result.count;
          this.spinner.hide('spinner-allcases');
          if (result.cases.length !== 0 && result.count > 10) {
            console.log('show more');
            this.showMore = true;
          } else {
            this.showMore = false;
          }

        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner-allcases');
        }
      );
    } else {
      this.spinner.show('spinner-allcases');
      this.apiService.getAllCasesByAdmin(this.appService.getToken(), page.toString(), this.caseSearch).subscribe(
        (result: any) => {
          this.cases = result.cases;
          this.casesCount = result.count;
          console.log(result);
          if (result.cases.length !== 0 && result.count > 10) {
            console.log('show more');
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.spinner.hide('spinner-allcases');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner-allcases');
        }

      );
    }
  }
  getCounsilors() {

    this.apiService.getAllCouncilorByAdmin(localStorage.getItem('token')).subscribe(
      (result: any) => {
        console.log(result);
        if (result.status) {
          this.alertInputs = [];
          this.councilors = result.counselors;
          console.log(this.councilors);
          this.counselor = this.councilors[0];
          console.log(this.counselor);

        }
      },
      (err) => {
        console.log(err.error);

      }
    );
  }
  async assign_to_counselor() {
    console.log(this.counselor);
    this.onClose_assign();
    this.apiService.assignCouncilorByAdmin(localStorage.getItem('token'), this.case_id, this.counselor).subscribe(
      (result: any) => {
        console.log(result);
        if (result.status) {
          this.case_id = '';
          this.appService.showSuccessToast('Case is assigned successfully.');
          this.getCases();

        }
      },
      (err) => {
        console.log(err.error);
        this.appService.showErrorToast('Error');
      }

    );
  }

  async re_assign_to_counselor() {
    console.log(this.counselor);
    this.onClose_reassign();
    this.apiService.reAssignCouncilorByAdmin(localStorage.getItem('token'), this.case_id, this.counselor, this.lastCounselorId, this.user_id).subscribe(
      (result: any) => {
        console.log(result);
        if (result.status) {
          this.case_id = '';
          this.appService.showSuccessToast('Case is reassigned successfully.');
          this.getCases();

        }
      },
      (err) => {
        console.log(err.error);
        this.appService.showErrorToast('Error');
      }

    );
  }

  open_pop_up_assign(id) {
    $("#allCases-popup").addClass("show");
    $("#allCases-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.case_id = id;
  }

  open_pop_up_reassign(id, c_id, u_id) {
    $("#allCases-popup_reassign").addClass("show");
    $("#allCases-popup_reassign").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.case_id = id;
    this.lastCounselorId = c_id;
    this.user_id = u_id;
  }

  onClose_assign() {

    $("#allCases-popup").removeClass("show");
    $("#allCases-popup").css("display", "none");
    $(".modal-backdrop").remove();

  }

  onClose_reassign() {

    $("#allCases-popup_reassign").removeClass("show");
    $("#allCases-popup_reassign").css("display", "none");
    $(".modal-backdrop").remove();

  }

  // async getSuccess(msg) {
  //   this.success_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.success_message = ''; }, 6000);
  // }
  // async getErrr(msg) {
  //   this.error_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.error_message = ''; }, 6000);
  // }

}
