import { Component } from '@angular/core';
import { AppService } from './app.service';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from './services/notification.service';
import { ApiService } from './services/api.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Events} from '../app/components/profile/events';
import * as firebase from 'firebase';
import $ from "jquery";
import {  ViewChild, AfterViewInit } from '@angular/core';
import {CallsComponent} from '../app/components/calls/calls.component'
import { environment } from '../environments/environment';
declare let gtag: Function;
declare let fbq: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  notificationData:any={title:'',body:''};
  notificationAllData:any;
  constructor(private appService: AppService, private router: Router, private notificationService: NotificationService,   private angularFireMessaging: AngularFireMessaging,public events: Events,
    private apiService: ApiService) {
     //firebase.initializeApp(environment.firebase);
  // CallsComponent.credit_error
     firebase.database().ref('CheckDataRefresh').on('value', resp => {
      console.log('resp call');
      console.log(resp);
      localStorage.setItem('call_req','yes')
    })
    firebase.database().ref('DataRefreshChat').on('value', resp => {
      console.log('resp chat');
      console.log(resp);
      localStorage.setItem('msg_req','yes')
    })
    this.notificationService.requestPermission();
    this.notificationService.receiveMessage();
    this.angularFireMessaging.messaging.subscribe(
      (messaging: any) => {
        messaging.onMessageCallback = (payload: any) => {
          console.log('payload');
          console.log(payload);
          $("#card-popup-notify").addClass("show");
          $("#card-popup-notify").css("display", "block");
            console.log("new message received. ", payload);
         
          
            // this.currentMessage.next(payload);
    
            const notification = payload.data;
            
                if (notification.type == 'end_chat') {
                  this.router.navigate(['/chat-listing']);
             
                
                }
            this.notificationAllData =notification;
            console.log(notification);
            this.notificationData.title = notification.title;
            this.notificationData.body = notification.body;
            $("#NotifyText").text(notification.title);
            $("#NotifyTextP").text(notification.body);
            // general call notification
            $("#notify").trigger('click');

            if (notification.type == 'transfer_credit') {
             this.UpdateCredits()
            }
            // this.router.navigate(['/case-details'], {
            //   queryParams: {
            //     case_id: 26,
            
            //     selected_tab: 'details',
            //     isAdminView: true,
            //   }
            // });        
         
        };
      }
    );
    console.log('this.notificationService.currentMessage');
    console.log(this.notificationService.currentMessage);
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        gtag('config', 'UA-G-VLKY6J8BQL', { 'page_path': y.url });
        fbq('track', 'PageView');
      }
    })
  }
  onCancel() {
    $("#card-popup-notify").removeClass("show");
    $("#card-popup-notify").css("display", "none");
    $(".modal-backdrop").remove();
  }
  RedirectOnNotification(){
    this.onCancel();
   
   var notification = this.notificationAllData;
   console.log('notification  console.log(notification) console.log(notification)')
   console.log(notification)
    if (notification.type == 'gen_call_request') {
      
      $('.close').on("click", function () {
        $(this).parent().hide();
      });
      this.router.navigate(['/calls']);
    } else if (notification.type == 'gen_call_accept') {

      $('.close').on("click", function () {
        $(this).parent().hide();
      });
     // this.router.navigate(['/calls']);
     if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {
     this.router.navigate(['/video-call'], {
      queryParams: {
        session: notification.data.session,
        token: notification.data.token,
        isCaseCall: false,
        callId: notification.data.rec_id
      }
    });
  }
    }

    // case call notification

    if (notification.type == 'call_request') {
      this.events.publish('call_request');
      $('.close').on("click", function () {
        $(this).parent().hide();
      });
      this.router.navigate(['/calls']);
    }

    // chat notification

    if (notification.type == 'chat_request') {
      this.events.publish('chat_request');
      if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
        localStorage.setItem('msg_req','yes')
      } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
        localStorage.setItem('msg_req','yes')
      }
      if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {

        $('.close').on("click", function () {
          $(this).parent().hide();
        });

      }
      this.router.navigate(['/chat-listing']);
    }

    if (notification.type == 'chat_started_by_user') {
      console.log('lllllllllllllllllllllllllllllllooooooooooooooooooooooooooo')
      console.log(notification)
      this.events.publish('chat_request');
    //   if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
    //   //  this.getChatReqByUserId();
    //   } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
    // //    this.getChatReq();
    //   }
    //   if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {
    //     $('.close').on("click", function () {
    //       $(this).parent().hide();
    //     });

    //   }
    //   this.router.navigate(['/chat-listing']);

    this.router.navigate(['/chat'],
    {
      queryParams: {
        user_id: notification.userId,
        user_img: environment.profilePicBaseUrl+notification.profilePic,
        user_name: notification.name,
        date_time: notification.updatedAt,
        user_topic: notification.caseTopic,
        islive: true,
        chat_req_id: notification.chatRequestId,
        recp_id: (this.appService.getUserType() == 'User') ? notification.userId : notification.userId,
        chatRoomName: notification.chatRoomName
      }
    }
    )
    }

    if (notification.type == 'chat_request_accepted') {
      this.events.publish('chat_request');
      if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      //  this.getChatReqByUserId();
      } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
       // this.getChatReq();
      }
      if (this.appService.getUserType() == 'User') {
        $('.close').on("click", function () {
          $(this).parent().hide();
        });

      }
      this.router.navigate(['/chat-listing']);
    }

    if (notification.type == 'end_chat') {
     // this.router.navigate(['/chat-listing']);
      $('.close').on("click", function () {
        $(this).parent().hide();
      });

//-listing
if(this.appService.getUserType() == 'User'){
  this.router.navigate(['/chat-listing'], {});
  // this.router.navigate(['/chat-listing'], {
  //   queryParams: {
  //     user_id: notification.user_id,
  //     user_img: notification.profile_pic,
  //     user_name: notification.fullname,
  //     chat_req_id: notification.chat_id,
  //     recp_id: (this.appService.getUserType() == 'User') ? notification.counselor : notification.user_id,
  //     user_topic: notification.topic,
  //     isHistory: true,
  //     chatRoomName: notification.chatRoomName
  //   }
  // });
}else{
  this.router.navigate(['/chat-listing'], {});
}

     
    }

    if (notification.type == 'chat_message') {
      $('.close').on("click", function () {
        $(this).parent().hide();
      });

      // this.router.navigate(['/chat'],
      // {
      //   queryParams: {
      //     user_id: notification.userId,
      //     user_img: notification.profilePic,
      //     user_name: notification.name,
      //     user_topic: notification.caseTopic,
      //     date_time: notification.updatedAt,
      //     islive: true,
      //     chat_req_id: notification.chatRequestId,
      //     recp_id: notification.userId,
      //     chatRoomName: notification.chatRoomName,

      //   }
      // } );
      if(this.router.url.includes('/chat?')){

      }else{
        this.router.navigate(['/chat-listing']);
      }
   
    }

    // transfer credit notification

    if (notification.type == 'transfer_credit') {

      this.appService.setCredit(+notification.credit);
      $('.close').on("click", function () {
        $(this).parent().hide();
      });
     this.UpdateCredits()
    }

    // case message notification

    if (notification.type == 'case_message') {
      $('.close').on("click", function () {
        $(this).parent().hide();
      });

      if (this.appService.getUserType() == 'User' || this.appService.getUserType() == 'Counselor') {
      this.router.navigate(['/case-details'], {
        queryParams: {
          case_id: notification.caseId,
      
          selected_tab: 'details',
        }
      }); 
    }    if (this.appService.getUserType() == 'Admin') {
      this.router.navigate(['/case-details'], {
        queryParams: {
          case_id: notification.caseId,
      
          selected_tab: 'details',
          isAdminView: true,
        }
      }); 
    }
    }

    // case document notification

    if (notification.type == 'case_document') {
      $('.close').on("click", function () {
        $(this).parent().hide();
      });
      if (this.appService.getUserType() == 'Admin') {

        this.router.navigate(['/case-details'], {
              queryParams: {
                case_id: notification.caseId,
            
                selected_tab: 'details',
                isAdminView: true,
              }
            });  
          }else{
            this.router.navigate(['/case-details'], {
              queryParams: {
                case_id: notification.caseId,
            
                selected_tab: 'details',
          
              }
            });
          }
    }

    // add case notification

    if (notification.type == 'add_case') {
      $('.close').on("click", function () {
        $(this).parent().hide();
      });
      this.router.navigate(['/case-listing']);
    }

    if (notification.type == 'assign_case') {
      if (this.appService.getUserType() != 'Admin') {
        $('.close').on("click", function () {
          $(this).parent().hide();
        });

        this.router.navigate(['/case-listing']);
      }

    }

    if (notification.type == 'reassign_case') {
      if (this.appService.getUserType() != 'Admin') {
        $('.close').on("click", function () {
          $(this).parent().hide();
        });
      }
      this.router.navigate(['/case-listing']);
    }
  }
  UpdateCredits(){
    this.apiService.getCreditHistoryOfUser(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
     
          localStorage.setItem('credit', (res.user.credit));
        }
  
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  RunEvent(){
    this.events.publish('call_request','');
  }
}
