import { Component, OnInit } from '@angular/core';
import { ValidatorService } from 'src/app/services/validator.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  // success_message: any; err_message: any;
  name: any;
  email: any;
  mEmail: any;
  mName: any;
  type: any = 'Counselor'; m_type: any = '';
  constructor(private validatorService: ValidatorService, private router: Router, private apiService: ApiService,
    public appService: AppService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  send() {
    this.mEmail = '', this.mName = ''; this.m_type = '';
    if (this.type === undefined || this.type === '') {
      this.m_type = 'Type is required.';
      return false;
    }
    if (this.name === undefined || this.name === '') {
      this.mName = 'Name is required.';
      return false;
    }
    if (!this.validatorService.isUserNameValid(this.name)) {
      this.mName = 'Enter valid name';
      return false;
    }
    if (this.email === undefined || this.email === '') {
      this.mEmail = 'Email is required.';
      return false;
    } else if (!this.validatorService.isEmailValid(this.email)) {
      this.mEmail = 'Enter valid email address';
      return false;
    }
    let token = this.appService.getToken();
    this.spinner.show('invite');
    this.apiService.addInvite(token, this.name, this.email, this.type,  this.appService.getProfile().fullName).subscribe(
      (result: any) => {
        if (result.status) {
          console.log(result);
          this.appService.showSuccessToast('You have send invitation successfully.');
          // this.success_message = 'You have send invitation successfully.';
          // var obj = this;
          // setTimeout(function () { obj.success_message = ''; }, 5000);
          this.resetfields();
          this.spinner.hide('invite');
        } else {
          console.log(result);
          this.appService.showErrorToast(result.message);
          // this.err_message = result.message;
          // var obj = this;
          // setTimeout(function () { obj.err_message = ''; }, 5000);
          this.resetfields();
          this.spinner.hide('invite');
        }
      },
      (err: any) => {
        this.resetfields();
        this.spinner.hide('invite');
        this.appService.showErrorToast('There is some error occured');
        // this.err_message = 'There is some error occured';
        // var obj = this;
        // setTimeout(function () { obj.err_message = ''; }, 5000);
      });
  }
  
  resetfields() {
    this.type = '';
    this.email = '';
    this.name = '';
  }
}
