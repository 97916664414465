import { Injectable } from '@angular/core';
import { ApiService } from './services/api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AppService {

	public static DOC: any;
	profilePicBaseUrl: any = environment.profilePicBaseUrl;

	public setDoc(doc) {
		AppService.DOC = doc;
	}
	constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) {
	}

	setAnonymousUser(user: any) {
		localStorage.setItem('userFullName', user.fullname);
		localStorage.setItem('userType', user.userType);
		localStorage.setItem('userProfilePic', user.profile_pic);
	}

	getAnonymousUser() {
		let user = {
			fullName: localStorage.getItem('userFullName'),
			userType: localStorage.getItem('userType'),
			profile_pic: localStorage.getItem('userProfilePic'),
		};
		return user
	}

	setToken(token: any) {
		localStorage.setItem('token', token);
		return;
	}
	getToken() {
		return localStorage.getItem('token');
	}

	setCredit(c: number) {
		localStorage.setItem('credit', (+localStorage.getItem('credit') + c).toString());
	}

	spendOneCredit() {
		localStorage.setItem('credit', (+localStorage.getItem('credit') - 1).toString());
	}

	spendCredit(credit: any) {
		localStorage.setItem('credit', (+localStorage.getItem('credit') - credit).toString());
	}

	getCredit() {
		return localStorage.getItem('credit');
	}

	setUserType(userType: any) {
		localStorage.setItem('userType', userType);
	}
	getUserType() {
		return localStorage.getItem('userType');
	}
	setFirstLogin(FirstLogin: any) {
		localStorage.setItem('FirstLogin', FirstLogin);
	}
	getFirstLogin() {
		return localStorage.getItem('FirstLogin');
	}

	setProfile_image(img) {
		localStorage.setItem('userProfilePic', img);
	}
	setProfile_password(password) {
		localStorage.setItem('password', password);
	}
	getProfile_password() {
		return localStorage.getItem('password');
	}
	setProfile(user: any) {
		localStorage.setItem('userId', user.id);
		localStorage.setItem('userEmail', user.email);
		localStorage.setItem('credit', user.credit == null ? 0 : user.credit);
		localStorage.setItem('userFullName', user.fullname);
		localStorage.setItem('startTime', user.startTime);
		localStorage.setItem('endTime', user.endTime);
		localStorage.setItem('userPhone', user.phone);
		localStorage.setItem('schedule', JSON.stringify(user.schedule));
		localStorage.setItem('userProfilePic', user.profile_pic);
		localStorage.setItem('company', user.company);
		localStorage.setItem('position', user.position);
		localStorage.setItem('userAddress', user.address);
		if (localStorage.getItem('userType') != 'User' && user.education !== null) {
			// localStorage.setItem('education', user.education.id);
			// localStorage.setItem('education_name', user.education.name);
			localStorage.setItem('education_name', user.education);
		}
		if (user.city !== null) {
			if (user.city.id) {
				localStorage.setItem('userCity', user.city.id);
			}
			if (user.city.name) {
				localStorage.setItem('userCity_name', user.city.name);
			}
		}
		if (user.country !== null) {
			if (user.country.id) {
				localStorage.setItem('userCountry', user.country.id);
			}
			if (user.country.name) {
				localStorage.setItem('userCountry_name', user.country.name);
			}
		}

		if (user.state !== null) {
			if (user.state.id) {
				localStorage.setItem('userState', user.state.id);
			}
			if (user.state.name) {
				localStorage.setItem('userState_name', user.state.name);
			}
		}
		return;
	}
	setProfile_edit(user: any) {
		localStorage.setItem('userPhone', user.phone);
		localStorage.setItem('userAddress', user.address);
		localStorage.setItem('userCity', user.city);
		localStorage.setItem('userState', user.state);
		localStorage.setItem('userCountry', user.country);
		return;
	}
	getProfile() {

		let userProfile = {
			id: localStorage.getItem('userId'),
			email: localStorage.getItem('userEmail'),
			fullName: localStorage.getItem('userFullName'),
			credit: localStorage.getItem('credit'),
			phon: localStorage.getItem('userPhone'),
			profilePic: localStorage.getItem('userProfilePic'),
			address: localStorage.getItem('userAddress'),
			scheduleData: localStorage.getItem('schedule'),
			city: localStorage.getItem('userCity'),
			startTime: localStorage.getItem('startTime'),
			endTime: localStorage.getItem('endTime'),
			city_name: localStorage.getItem('userCity_name'),
			userState_name: localStorage.getItem('userState_name'),
			country: localStorage.getItem('userCountry'),
			country_name: localStorage.getItem('userCountry_name'),
			state: localStorage.getItem('userState'),
			education: localStorage.getItem('education'),
			education_name: localStorage.getItem('education_name'),
			company: localStorage.getItem('company'),
			position: localStorage.getItem('position'),
		};

		return userProfile;
	}

	setSecurityToken(securityToken: any) {
		localStorage.setItem('securityToken', securityToken);
		return;
	}

	getSecurityToken() {
		return localStorage.getItem('securityToken');
	}

	setSecureToken(secureToken: any) {
		localStorage.setItem('secureToken', secureToken);
		return;
	}

	getSecureToken() {
		return localStorage.getItem('secureToken');
	}

	async getNotificationCount() {
		return new Promise<any>(resolve => {
			this.apiService.getNotificationCount(this.getToken()).subscribe(
				(result: any) => {
					console.log(result);
					resolve(result);
				},
				(err: any) => {
					resolve(err);
					console.log(err.error);
				}
			);
		});
	}

	async getTokenVerificationResult() {
		return new Promise<any>(resolve => {
			this.apiService.verifyToken(localStorage.getItem('token')).subscribe(
				(result: any) => {
					console.log(result);
					resolve(result);
				},
				(err: any) => {
					resolve(err.error);
					console.log(err.error);
				}
			);
		});
	}

	getDate(date: any) {
		return moment(date).format('MMMM D, YYYY');
	}

	getTime(date: any) {
		return moment(date).format('hh:mm a');
	}

	getDayName(date: any) {
		return moment(date).format('dddd');
	}
	getShortDayName(date: any) {
		return moment(date).format('ddd');
	}

	isPassed(t: any, d: any) {
		var now = moment().format('YYYY-MM-DD HH:mm').toString();
		var time = moment(t).format('HH:mm').toString();
		var date = moment(d).format('YYYY-MM-DD').toString();
		var dt = date + " " + time;

		var _now = moment(now, 'YYYY-MM-DD HH:mm');
		var _date = moment(dt, 'YYYY-MM-DD HH:mm');


		// console.log(_now);
		// console.log(_date);
		if (_date.isBefore(_now)) {
			return true;
		}
		return false;
	}

	isDatePassed(d: any) {
		var now = moment().format('YYYY-MM-DD HH:mm:00').toString();
		var date = moment(d).format('YYYY-MM-DD HH:mm:00').toString();

		var _now = moment(now, 'YYYY-MM-DD HH:mm');
		var _date = moment(date, 'YYYY-MM-DD HH:mm');

		if (_date.isBefore(_now)) {
			return true;
		}
		return false;
	}

	// isDateSame(evDate: any, nowDate: any) {
	// 	var ev = moment(evDate).format('YYYY-MM-DD');
	// 	var now = moment(nowDate).format('YYYY-MM-DD');
	// 	return moment(ev).isSame(now);
	// }

	isDateTimeSame(evDate: any, t: any, selectedDate: any) {
		var evD = moment(evDate).format('YYYY-MM-DD HH:mm').toString();
		console.log(t);
		var time = moment(t).format('HH:mm').toString();
		var date = moment(selectedDate).format('YYYY-MM-DD').toString();
		var dt = date + " " + time;
		console.log(time);
		console.log(dt);
		console.log(evD);
		var _slot = moment(dt, 'YYYY-MM-DD HH:mm');
		var _date = moment(evD, 'YYYY-MM-DD HH:mm');

		if (_slot.isSame(_date)) {
			console.log(true);
			return true;
		}
		return false;
	}

	add_Minutes(date: any) {
		return moment(date).add(15, 'm').format('YYYY-MM-DD HH:mm');
	}

	add_chat_minutes(date: any) {
		console.log('9999999999999999999999')
		console.log(date)
		return moment(date).add(24, 'h').format('YYYY-MM-DD HH:mm');
	}

	isAfter(timeA: any, timeB: any) {
		var a = moment(timeA).format('HH:mm').toString();
		var b = moment(timeB).format('HH:mm').toString();
		var _a = moment(a, 'HH:mm');
		var _b = moment(b, 'HH:mm');

		if (_a.isAfter(_b)) {
			return true;
		} else {
			return false;
		}
	}

	isBefore(timeA: any, timeB: any) {
		var a = moment(timeA).format('HH:mm').toString();
		var b = moment(timeB).format('HH:mm').toString();
		var _a = moment(a, 'HH:mm');
		var _b = moment(b, 'HH:mm');

		if (_a.isBefore(_b)) {
			return true;
		} else {
			return false;
		}
	}

	isDateTimeSameee(date1: any, date2: any) {
		var d1 = moment(date1).format('YYYY-MM-DD HH:mm').toString();
		var d2 = moment(date2).format('YYYY-MM-DD HH:mm').toString();
		var _slot = moment(d1, 'YYYY-MM-DD HH:mm');
		var _date = moment(d2, 'YYYY-MM-DD HH:mm');

		if (_slot.isSame(_date)) {
			console.log(true);
			return true;
		}
		return false;
	}

	isChatTimeAway(date: any,server_time:any) {
		var currentDate = moment().format('YYYY-MM-DD HH:mm').toString();
		var userDate = this.add_chat_minutes(date);
		userDate = moment(userDate).format('YYYY-MM-DD HH:mm').toString();
		console.log(userDate);
		console.log(currentDate);
		var cd = moment(currentDate, 'YYYY-MM-DD HH:mm');
		var ud = moment(userDate, 'YYYY-MM-DD HH:mm');

		if (cd.isBefore(ud) || cd.isSame(ud)) {
			return false;
		}
		return true;
	}
	isChatTimeAwayMy(date: any,server_time:any) {
		var startTime = new Date(date); ; 
		var endTime = new Date(server_time);
		var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
		var resultInMinutes = Math.round(difference / 60000);
	
		console.log(resultInMinutes)
	}
	isTimeAway(date: any) {
		var currentDate = moment().format('YYYY-MM-DD HH:mm').toString();
		var userDate = this.add_Minutes(date);
		userDate = moment(userDate).format('YYYY-MM-DD HH:mm').toString();
		console.log(userDate);
		console.log(currentDate);
		var cd = moment(currentDate, 'YYYY-MM-DD HH:mm');
		var ud = moment(userDate, 'YYYY-MM-DD HH:mm');

		if (cd.isBefore(ud) || cd.isSame(ud)) {
			return false;
		}
		return true;
	}

	isTimeSame(timeA: any, timeB: any) {
		var a = moment(timeA).format('HH:mm').toString();
		var b = moment(timeB).format('HH:mm').toString();
		var _a = moment(a, 'HH:mm');
		var _b = moment(b, 'HH:mm');

		if (_a.isSame(_b)) {
			return true;
		} else {
			return false;
		}
	}

	getTimeWeb(date: any) {
		return moment(date).format('HH:mm:00');
	}
	getTimeComplete(date: any) {
		return moment(date).format('hh mm a');
	}
	getTime_date(date: any) {
		return moment(date).format('MMMM D, YYYY hh:mm');
	}

	showSuccessToast(msg: any) {
		this.toastr.success(msg, undefined, {
			timeOut: 3000,
		});
	}

	async showNotificationToast(msg: any) {
		const toast = this.toastr.success(msg, undefined, {
			disableTimeOut: true,
			closeButton: true,
		});
		return toast;
	}

	showErrorToast(msg: any) {
		this.toastr.error(msg, undefined, {
			timeOut: 3000
		});
	}


	getTodayDate() {
		return moment(new Date()).format('YYYY-MM-DD');
	}

	_getDate() {
		return moment();
	}

	isTimeNotComes(d: any) {
		var now = moment().format('YYYY-MM-DD HH:mm:00').toString();
		var date = moment(d).format('YYYY-MM-DD HH:mm:00').toString();

		var _now = moment(now, 'YYYY-MM-DD HH:mm');
		var _date = moment(date, 'YYYY-MM-DD HH:mm');

		if (_date.isAfter(_now)) {
			return true;
		}
		return false;
	}
	isTimeNotComesNew(d: any,server: any) {
		var now = moment(server).format('YYYY-MM-DD HH:mm:00').toString();
		var date = moment(d).format('YYYY-MM-DD HH:mm:00').toString();

		var _now = moment(now, 'YYYY-MM-DD HH:mm');
		var _date = moment(date, 'YYYY-MM-DD HH:mm');

		if (_date.isAfter(_now)) {
			return true;
		}
		return false;
	}

	getEventDateTime(selectedDate: any, time: any) {
		const d = moment(selectedDate).format('YYYY-MM-DD').toString();
		const t = moment(time).format('HH:mm').toString();

		return d + ' ' + t;
	}

	setStartTime(t: any) {
		var time = moment(new Date()).format('YYYY-MM-DDT' + t).toString();
		localStorage.setItem('startTime', time);
	}

	setEndTime(t: any) {
		var time = moment(new Date()).format('YYYY-MM-DDT' + t).toString();
		localStorage.setItem('endTime', time);
	}

	getDatee(date: any) {
		return moment(date).format('YYYY-MM-DD').toString();
	}

	getTime24Format(date: any) {
		return moment(date).format('HH:mm');
	}
	add15Minutes(date: any) {
		return moment(date).add(15, 'm').toDate();
	}

	getNewDate(date: any, time: any) {
		var d = moment(date).format('YYYY-MM-DD');
		var t = moment(time).format('HH:mm');
		var timeAndDate = d + ' ' + t + ':00';
		return timeAndDate;
	}

	isTimeComes(evDate: any, nowDate: any) {

		var hour = moment.utc(moment(new Date()).diff(moment(evDate))).format("H");
		var min = moment.utc(moment(new Date()).diff(moment(evDate))).format("m");

		return { h: hour, m: min }

	}

	compareTime(timeA: any, timeB: any) {
		var a = moment(timeA).format('HH:mm').toString();
		var b = moment(timeB).format('HH:mm').toString();
		var _a = moment(a, 'HH:mm');
		var _b = moment(b, 'HH:mm');

		if (_a.isAfter(_b)) {
			return true;
		} else {
			return false;
		}
	}

	isTimePassed(date: any, time: any) {

		var currentDate = moment().format('YYYY-MM-DD HH:mm').toString();
		var d = moment(date.toString()).format('YYYY-MM-DD').toString();
		var t = moment(time.toString()).format('HH:mm').toString();
		var dt = d + " " + t;

		var cd = moment(currentDate, 'YYYY-MM-DD HH:mm');

		var newd = moment(dt, 'YYYY-MM-DD HH:mm');
		if (cd.isAfter(newd) || cd.isSame(newd)) {
			return true;
		}
		return false;
	}

	getDateeTime(date: any) {
		return moment(date).format('YYYY-MM-DD HH:mm:00').toString();
	}

	makeDate(t: any) {
		var d = moment().format('YYYY-MM-DD').toString();
		return d + 'T' + t + ':00'
	}

	isDateSame(evDate: any, nowDate: any) {
		var ev = moment(evDate).format('YYYY-MM-DD');
		var now = moment(nowDate).format('YYYY-MM-DD');
		return moment(ev).isSame(now);
	}

	getCost(c: any) {
		return (c * 19.99).toFixed(2);
	}

	getMinutes(date: any) {

		var now = moment(new Date(), 'YYYY-MM-DD HH:mm'); //todays date
		var end = moment(this.add_Minutes(date), 'YYYY-MM-DD HH:mm'); // another date
		var duration = moment.duration(end.diff(now));

		return duration.asMinutes().toFixed(0);
	}

	getChatRoomName() {
		return 'chat_' + this.makeid(5);
	}

	makeid(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
}
