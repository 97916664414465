import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidatorService } from '../../services/validator.service';
import * as $ from "jquery";
declare var google;
@Component({
	selector: 'app-edit-profile',
	templateUrl: './edit-profile.component.html',
	styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
	fileData: File = null;
	previewUrl: any = null; files: any;
	// success_message: any; error_message: any;
	newPassword: any; old_error: any = ''; new_error: any = ''; confirm_error: any = '';
	oldPassword: any; confirm_password: any;
	// For Profile
	show_profile: boolean = true;
	education = '';
	all_loader: any; userType: any; relatedSearch: any;
	fullname: any;
	address: any = ''; address_error: any = '';
	Company: any = ''; Company_error: any = '';
	position: any = ''; position_error: any = '';
	email: any = ''; country_name: any = '';
	phone: any = '';
	country: any = '';
	state: any = ''; image: any = '';
	city: any = '';
	fullName_error: any = '';
	email_error: any = '';
	phone_error: any = '';
	password_error: any = '';
	state_error: any = '';
	city_error: any = '';
	countries: any;
	states: any;
	Country: any = ''; Country_error: any = '';
	cities: any;
	images: any; education_list: any; education_id: any = ''; education_error: any = '';
	startTime: any;
	endTime: any;
	start_error: any;
	end_error: any;
	//For Password
	userProfilePic: any = ''; userName: any = '';
	constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute, public appService: AppService, private router: Router, private apiServices: ApiService, public validator: ValidatorService) {


		this.startTime = this.appService.getTimeWeb(this.appService.getProfile().startTime);
		this.endTime = this.appService.getTimeWeb(this.appService.getProfile().endTime);
		// this.startTime = '11:11:00';
		// console.log(this.appService.getTimeWeb(this.appService.getProfile().startTime));

		this.userProfilePic = this.appService.getProfile().profilePic;

		this.userName = this.appService.getProfile().fullName;
		if (this.appService.getProfile().email != 'null') {
			this.email = this.appService.getProfile().email;
		}
		if (this.appService.getProfile().company != 'null') {
			this.Company = this.appService.getProfile().company;
		}
		if (this.appService.getProfile().position != 'null') {
			this.position = this.appService.getProfile().position;
		}
		if (this.appService.getProfile().phon != 'null') {
			this.phone = this.appService.getProfile().phon;
		}
		if (this.appService.getProfile().city != 'null') {
			this.city = this.appService.getProfile().city;
		}
		if (this.appService.getProfile().country != 'null') {
			this.Country = this.appService.getProfile().country;
		}
		if (this.appService.getProfile().state != 'null') {
			this.state = this.appService.getProfile().state;
		}
		if (this.appService.getProfile().address != 'null') {
			this.address = this.appService.getProfile().address;
		}
		this.get_counteries();
		this.userType = localStorage.getItem('userType');
		if (localStorage.getItem('userType') != 'User') {
			this.get_education();
		}
	}
	get_counteries() {

		this.spinner.show('edit-pro');
		let token = this.appService.getToken();
		this.apiServices.getAllCountries(token).subscribe(
			(result: any) => {
				this.countries = result.countries;
				this.spinner.hide('edit-pro');
				if (this.Country) {
					this.get_states();
				}
			},
			(err: any) => {
				this.spinner.hide('edit-pro');
			});
	}
	get_states() {

		let token = this.appService.getToken();
		this.apiServices.getStatesList(token, this.Country).subscribe(
			(result: any) => {

				this.states = result.states;
				if (this.state) {
					this.get_cities();
				}

			},
			(err: any) => {

			});

	}
	getAddress() {
		var address_check = this.address;
		var geocoder = new google.maps.Geocoder();

		geocoder.geocode({ 'address': this.address }, function (results, status) {


			if (status == 'OK') {
				$("#sujjested").hide();
				if (results[0].formatted_address != undefined) {
					var latss = results[0].geometry.location.lat();
					var lngss = results[0].geometry.location.lng();

					this.relatedSearch = results[0].formatted_address;

					$("#sujjested").text(results[0].formatted_address);


					if (this.relatedSearch != address_check) {
						$("#sujjested").show();
					}
				}
				//sujjested
			}
		});

	}
	adress_appointed() {
		this.address = $("#sujjested").text();
		$("#sujjested").hide();
		//this.address = this.relatedSearch;
		//	this.relatedSearch='';
	}
	get_cities() {

		let token = this.appService.getToken();
		this.apiServices.getCitiesList(token, this.state).subscribe(
			(result: any) => {

				this.cities = result.cities;

			},
			(err: any) => {


			});
	}
	onEdChangee(ev: any) {
		console.log(ev);
		this.education_id = ev.target.value;
	}

	get_education() {

		let token = this.appService.getToken();
		this.apiServices.getEducationList(token).subscribe(
			(result: any) => {

				this.education_list = result.educations;
				this.education_id = this.appService.getProfile().education;
				this.education = this.appService.getProfile().education_name;
			},
			(err: any) => {



			});
	}
	ngOnInit() {
	}
	updateProfile() {
		console.log(this.startTime);
		console.log(this.endTime);
		this.appService.setStartTime(this.startTime);
		this.appService.setEndTime(this.endTime);
		this.address_error = ''; this.phone_error = ''; this.city_error = ''; this.state_error = '';
		this.education_error = ''; this.education_error = '';
		if (localStorage.getItem('userType') != 'User') {
			// if ((this.education_id == null) || (this.education_id == '')) {
			// 	this.education_error = "Education is required.";
			// 	return false;
			// }
			if (!this.education) {
				this.education_error = "Education is required.";
				return false;
			}
		}
		if (localStorage.getItem('userType') == 'User') {
			this.education_id = 1;
		}

		if ((this.phone == null) || (this.phone == '')) {
			this.phone_error = "Phone is required.";
			return false;
		}
		let phoneTest = this.validator.isPhoneValid(this.phone);
		if (phoneTest == false) {
			this.phone_error = "Phone Number should be in proper format";
			return false;
		}
		if ((this.Country == null) || (this.Country == '')) {
			this.Country_error = "Country is required.";
			return false;
		}
		if ((this.state == null) || (this.state == '')) {
			this.state_error = "State is required.";
			return false;
		}
		if ((this.city == null) || (this.city == '')) {
			this.city_error = "City is required.";
			return false;
		}
		if (this.startTime == undefined && (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin')) {
			this.start_error = 'choose start time';
			return false;
		}
		if (this.endTime == undefined && (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin')) {
			this.end_error = 'choose end time';
			return false;
		}
		if ((this.address == null) || (this.address == '')) {
			this.address_error = "Address is required.";
			return false;
		}
		// if ((this.education_id == null) || (this.education_id == '')) {
		// 	this.address_error = "Education is required";
		// 	return false;
		// }
		let token = this.appService.getToken();
		this.spinner.show('edit-pro');
		this.apiServices.editUserProfile(token, this.fullname, this.address, this.phone, this.Country, this.state, this.city, this.education, this.Company, this.position, '', this.startTime, this.endTime, '', '').subscribe(
			(result: any) => {
				if (this.image) {
					this.uploadImageData();
					this.spinner.hide('edit-pro');
				} else {

					this.get_user_profile();
					this.spinner.hide('edit-pro');
					this.appService.showSuccessToast('You have successfully updated your profile');
					// this.success_message = 'You have successfully updated your profile';
					// var obj = this;
					// setTimeout(function () { obj.success_message = ''; }, 5000);

				}
			},
			(err: any) => {
				this.spinner.hide('edit-pro');
				this.appService.showErrorToast('There is some error occured');
				// this.error_message = 'There is some error occured';
				// var obj = this;
				// setTimeout(function () { obj.error_message = ''; }, 5000);
			});
	}
	get_user_profile() {
		let token = this.appService.getToken();
		this.apiServices.getUserProfile(token).subscribe(
			(res: any) => {

				this.appService.setProfile(res.user);

			}, (err: any) => {
				console.log(err.error);
			});
	}
	go_change() {
		this.show_profile = !this.show_profile;
	}
	updatePassword() {
		this.old_error = ''; this.new_error = ''; this.confirm_error = '';
		if ((this.oldPassword == null) || (this.oldPassword == '')) {
			this.old_error = "Current Password is required.";
			return false;
		}
		if (this.oldPassword != localStorage.getItem("password")) {
			this.old_error = 'Current Password is miss match.';
			return false;
		}
		if ((this.newPassword == null) || (this.newPassword == '')) {
			this.new_error = "New Password is required.";
			return false;
		}
		let newPasswordCheck = this.validator.isPasswordValid(this.newPassword);
		if (newPasswordCheck == false) {
			this.new_error = "Please enter minimum 6 digits as a password.";
			return false;
		}
		if ((this.confirm_password == null) || (this.confirm_password == '')) {
			this.confirm_error = "Confirm Password is required.";
			return false;
		}
		if (this.confirm_password != this.newPassword) {
			this.confirm_error = 'Confirm Password is miss match.';
			return false;
		}
		this.spinner.show('edit-pro');
		let token = this.appService.getToken();

		this.apiServices.changeUserPassword(token, this.oldPassword, this.newPassword).subscribe(
			(result: any) => {
				this.newPassword = '';
				this.oldPassword = '';
				this.confirm_password = '';
				this.spinner.hide('edit-pro');
				this.appService.setProfile_password(this.newPassword);
				this.appService.showSuccessToast('Password Changed Successfully');

			},
			(err: any) => {
				this.newPassword = '';
				this.oldPassword = '';
				this.confirm_password = '';
				this.spinner.hide('edit-pro');
				this.appService.showErrorToast('Some error occured.');

			});

	}

	// async getSuccess(msg) {
	// 	this.success_message = msg;
	// 	var obj = this;
	// 	setTimeout(function () { obj.success_message = ''; }, 6000);
	// }
	// async getErrr(msg) {
	// 	this.error_message = msg;
	// 	var obj = this;
	// 	setTimeout(function () { obj.error_message = ''; }, 6000);
	// }

	onUploadChange(evt: any) {
		const file = evt.target.files[0];

		if (file) {
			const reader = new FileReader();

			reader.onload = this.handleReaderLoaded.bind(this);
			reader.readAsBinaryString(file);
		}
	}

	handleReaderLoaded(e) {
		this.image = 'data:image/png;base64,' + btoa(e.target.result);

	}
	uploadImageData() {
		console.log('6');
		//this.componentService.getLoader().then((loader) => {
		let token = this.appService.getToken();
		//	loader.present().then(() => {

		this.apiServices.uploadProfilePic(token, this.image).subscribe((result: any) => {

			this.get_user_profile();
			this.appService.setProfile_image(result.profile_pic);
			//this.events.publish('changeImage', result.profile_pic);
			//this.userProfilePic =result.profile_pic;
			console.log(result);
			this.appService.showSuccessToast('You have successfully updated your profile');
			// this.success_message = 'You have successfully updated your profile';
			// var obj = this;

			// setTimeout(function () { obj.success_message = ''; }, 5000);
		}, (err: any) => {


			//	loader.dismiss()

		})
		//	})
		//	})
	}



	fileProgress(fileInput: any) {
		this.fileData = <File>fileInput.target.files[0];
		this.userProfilePic = 'data:image/jpeg;base64,' + this.fileData;
		this.preview();
	}

	preview() {
		// Show preview 
		var mimeType = this.fileData.type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(this.fileData);
		reader.onload = (_event) => {
			this.previewUrl = reader.result;
		}
	}
	//   onSubmit() {
	//     const formData = new FormData();
	//     formData.append('files', this.fileData);

	//     this.fileUploadProgress = '0%';

	//     this.http.post('https://us-central1-tutorial-e6ea7.cloudfunctions.net/fileUpload', formData, {
	//       reportProgress: true,
	//       observe: 'events'   
	//     })
	//     .subscribe(events => {
	//       if(events.type === HttpEventType.UploadProgress) {
	//         this.fileUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
	//         console.log(this.fileUploadProgress);
	//       } else if(events.type === HttpEventType.Response) {
	//         this.fileUploadProgress = '';
	//         console.log(events.body);          
	//         alert('SUCCESS !!');
	//       }

	//     }) 
	// }




}