import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";
import { ValidatorService } from 'src/app/services/validator.service';
//import { setInterval } from 'timers';
import { AuthService, SocialUser, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  interval:any;
  loginMessage: any;
  sub_email: any;
  message: any;
  m_sub_email: any;
  subscription_email:any='';
  sub_email_error:any='';
  email: any;
  password: any;
  isLogin: boolean = false;
  isLoginPopUpShow: boolean = false;
  profileImage = '';
  userName = '';

  device_id: any;
  device_type: any;
  firstName: any;
  lastName: any;
  phone: any;
  confirmpassword: any;
  firstName_error: any;
  lastName_error: any;
  phone_error: any;
  email_error: any;
  password_error: any;
  confirmpassword_error: any;
  termsC: boolean = false;
  termsC_error: any;
  constructor(private spinner: NgxSpinnerService, private apiService: ApiService, private router: Router, public authService: AuthService,
    private appService: AppService, private validatorService: ValidatorService) { }

  ngOnInit() {
    // localStorage.setItem('userType','');
    // localStorage.setItem('token','');
  }
  validateFields() {
    this.firstName_error = ''; this.lastName_error = '';
    this.email_error = ''; this.password_error = ''; this.confirmpassword_error = '';
    this.phone_error = ''; this.termsC_error = '';
    // this.signup_error = '';
    if (this.firstName === undefined || this.firstName === '') {
      this.firstName_error = 'First Name required';
      return false;
    }
    if (this.lastName === undefined || this.lastName === '') {
      this.lastName_error = 'Last Name required';
      return false;
    }
    if (this.phone === undefined || this.phone === '') {
      this.phone_error = 'Phone Number required';
      return false;
    }
    if (!this.validatorService.isPhoneValid(this.phone)) {
      this.phone_error = 'Enter Valid Phone Number';
      return false;
    }
    if (this.email === undefined || this.email === '') {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter Valid Email Address';
      return false;
    }
    if (this.password === undefined || this.password === '') {
      this.password_error = 'Password required';
      return false;
    }
    if (!this.validatorService.isPasswordValid(this.password)) {
      this.password_error = 'Minimum 6 Chatacter required';
      return false;
    }
    if (this.password !== this.confirmpassword) {
      this.confirmpassword_error = 'Confirm Password missmatch. Try Again';
      return false;
    }
    if (this.termsC === false) {
      this.termsC_error = 'You need to agree with our Terms & Conditions';
      return false;
    }

    return true;
  }
  onSignUpBtnClick() {
    if (this.validateFields()) {
      this.spinner.show('header');
      this.apiService.registerUser(this.firstName + ' ' + this.lastName, this.email, this.phone, this.password, localStorage.getItem('notificationToken'), 'browser', localStorage.getItem('notificationToken')).subscribe(
        (result: any) => {
          if (result.status) {
       
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                if (res.status) {
                  this.appService.setUserType('User');
                  this.isLogin = true;
                  this.profileImage = res.user.profile_pic;
                  this.userName = res.user.fullname;
                  this.appService.setProfile(res.user);
                  this.appService.setProfile_password(this.password);
                  this.spinner.hide('header');
                  console.log('asdjgkl');
                  this.appService.setToken(result.jwtoken);
                  this.router.navigate(['/home']);
                  $("#sign-popup .close").trigger('click');
                }
              },
              (err) => {
                console.log(err.error);
                this.spinner.hide('header');
                // this.signup_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              })
          } else {
            this.spinner.hide('header');
            // this.signup_error = result.message;
            this.appService.showErrorToast(result.message);
          }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('header');
          // this.signup_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }
  onTCClick() {
    const url = 'https://app.myzolve.com/term-of-service.html';
    window.open(url, "_system", "location=yes,enableViewportScale=yes,hidden=no");
  }
}
