import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  // Regular Expressions to validate data
  private EMAIL_REG_EXP = new RegExp('[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+');
  private PASSWORD_REG_EXP = new RegExp('^.{6,}$');
  private PHONE_REG_EXP = new RegExp('^([+]?)([0-9]*)?$');
  private USER_NAME = new RegExp('^([a-z A-Z]+)$');
  constructor() { }

  // Regular Expression functions
  public isEmailValid(email: any) {
    return this.EMAIL_REG_EXP.test(email);
  }

  public isPasswordValid(password: any) {
    return this.PASSWORD_REG_EXP.test(password);
  }

  public isPhoneValid(phone: any) {
    return this.PHONE_REG_EXP.test(phone);
  }

  public isUserNameValid(name: any) {
    return this.USER_NAME.test(name);
  }
}
