import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import $ from "jquery";
import { StripeService, Element as StripeElement, ElementOptions, ElementsOptions } from "@nomadreservations/ngx-stripe";
import { Events} from '../profile/events';
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-calls',
  templateUrl: './calls.component.html',
  styleUrls: ['./calls.component.css']
})
export class CallsComponent implements OnInit {


  notificationCount: any;
  alertInputs = [];
  categories = [];
  selectedCategory: any = '';
  category_error: any;
  server_time:any;
  dataLoaded: boolean = false;

  // Calls Fields

  generalCallInterval: any;
  callSegment: any = 'unassign';
  callsHistory: any[] = [];
  callRequests: any[] = [];
  callPendings: any[] = [];

  userEvents: any[] = [];
  userGenEvents: any[] = [];
  counselorEvents: any[] = [];
  counselorGenEvents: any[] = [];
  counselorsSchedule: any[] = [];
  scheduleData: any[] = [];

  selectedDate: any;
  selectedDay: any;
  selectedDayShort: any;
  selectedDateError: any;

  slots: any[] = [];
  timeLoaded: boolean = false;

  stripeKey = '';
  error: any;
  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  isPackageTab: boolean = true;
  isCreditsTab: boolean = false;

  creditHistory: any[] = [];
  packages: any[] = [];
  packagee_error: any = '';
  packagee: any = '';
  credit: number = 0;
  credit_error: any = '';

  constructor(public appService: AppService, private apiService: ApiService, private spinner: NgxSpinnerService,public events: Events,private notificationService: NotificationService,
    private router: Router, private stripe: StripeService) {
    if (this.appService.getUserType() == 'User') {
      this.callSegment = 'active';
    } else {
      this.callSegment = 'unassign';
    }
    this.events.subscribe('call_request', (data) => {
    //  this.getPendingCalls();
    })
    setInterval(() => {
      var check = localStorage.getItem('call_req');
      var obj = this;
      if(check == 'yes'){
        setTimeout(() => {
          localStorage.setItem('call_req','')
        }, 500);
        obj.getPendingCalls();
 
    }
    }, 1000);

  }

  // click events

  onActiveTabClick() {
    this.callSegment = 'active';
    this.getPendingCalls();
  }

  onUnassignTabClick() {
    this.callSegment = 'unassign';
    this.getCallRequests();
  }

  onHistoryTabClick() {
    this.callSegment = 'history';
    this.getCallsHistory();
  }

  onTimeClick(t: any) {
    console.log(t);
    var eventDate = this.appService.getEventDateTime(this.selectedDate, t);

    this.sendCallRequestToCounselor(eventDate);

  }


  onPacakgeTabClick() {
    this.isPackageTab = true;
    this.isCreditsTab = false;
  }

  onCreditsTabClick() {
    this.isCreditsTab = true;
    this.isPackageTab = false;
  }

  onBuyNowBtnClick() {
    console.log(this.packagee);
    this.credit_error = '';
    if (this.isPackageTab) {
      this.onCancelBtnClick();
      $("#card-popup").addClass("show");
      $("#card-popup").css("display", "block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      if (this.credit > 0) {
        this.onCancelBtnClick();

        $("#card-popup").addClass("show");
        $("#card-popup").css("display", "block");
        $("body").append("<div class='modal-backdrop fade show'></div>");
      } else {
        this.credit_error = 'Invalid credit';
      }
    }
  }

  onCancel() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onCancelPopup() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onConfirm() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
    this.spinner.show('calls');
    this.stripe.createToken(this.element, {
    }).subscribe(
      (res: any) => {
        console.log(res.token.id);
        if (this.isPackageTab) {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), this.packages[this.packagee - 1].cost, this.packages[this.packagee - 1].credit, res.token.id, this.packages[this.packagee - 1].name).subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              localStorage.setItem('credit', (this.packages[this.packagee - 1].credit + +this.appService.getCredit()).toString());
              this.spinner.hide('calls');
              this.onCancel();
              this.onCancelPopup();
              this.onCategoryChange();
              // this.getCreditHistory();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('calls');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        } else {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), (this.credit * 19.99), this.credit, res.token.id, 'no_package').subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              var credit = +this.credit + +this.appService.getCredit();
              localStorage.setItem('credit', credit.toString());
              this.spinner.hide('calls');
              this.onCancelPopup();
              this.onCancel();
              this.onCategoryChange();
              // this.getCreditHistory();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('calls');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide('calls');
        this.onCancelPopup();
        this.onCancel();
      }
    );
  }

  cardUpdated(result) {
    console.log('asdg');
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  onCancelBtnClick() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onAddCreditBtnClick() {
    $("#buy-credit-popup").addClass("show");
    $("#buy-credit-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.getPackages();
  }

  getPackages() {
    this.spinner.show('calls');
    this.apiService.getCreditPackages(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.packages = res.data;
          this.packagee = this.packages[0].id;

        }
        this.dataLoaded = true;
        this.spinner.hide('calls');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('calls');
      }
    );
  }


  onConfirmationCallClose() {
    $("body").removeClass("modal-open");
    $("#call-confirmation-popup").removeClass("show");
    $("#call-confirmation-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  openCallConfirmationPopUp() {
    $("body").addClass("modal-open");
    $("#call-confirmation-popup").addClass("show");
    $("#call-confirmation-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }
  // change events

  onCategoryChange(ev?: any) {
    console.log(ev);
    $("#category-popup .close").trigger('click');
    if (+this.appService.getCredit() > 0) {
      this.spinner.show('calls').then(
        (r) => {
          console.log(r);
          setTimeout(() => {
            this.openCallConfirmationPopUp();
            this.selectedDate = this.appService.getTodayDate();
            this.selectedDay = this.appService.getDayName(this.selectedDate);
            this.selectedDayShort = this.appService.getShortDayName(this.selectedDate);

            this.timeLoaded = false;
            this.slots = [];
            this.scheduleData = [];

            this.selectedDayShort = this.appService.getShortDayName(this.selectedDate);

            for (const sc of this.counselorsSchedule) {
              this.scheduleData.push(...JSON.parse(sc));
            }

            this.getSlots();
          }, 2000);
        }
      );

    } else {
      this.appService.showErrorToast('Your credit is not enough for call');
      this.onAddCreditBtnClick();
    }
  }

  onSelectedDateChange(ev: any) {
    console.log(ev);
    this.spinner.show('calls').then(
      (r) => {
        console.log(r);
        // this.aaa(ev);
        setTimeout(() => {
          this.selectedDay = this.appService.getDayName(ev.target.value);
          this.selectedDate = ev.target.value;
          this.selectedDayShort = this.appService.getShortDayName(ev.target.value);

          this.timeLoaded = false;
          this.slots = [];
          this.scheduleData = [];

          this.selectedDayShort = this.appService.getShortDayName(this.selectedDate);

          for (const sc of this.counselorsSchedule) {
            this.scheduleData.push(...JSON.parse(sc));
          }

          this.getSlots();
        }, 500);

      }
    );
  }

  getSlots() {
    var temp_slots = [];
    if (this.scheduleData.length != 0) {

      this.scheduleData.forEach(data => {
        const day = data.substring(0, 3);

        if (day == this.selectedDayShort) {
          const a = data.split(';')
          let start_time = a[1];
          let end_time = a[2];

          start_time = this.appService.getEventDateTime(this.selectedDate, start_time);
          end_time = this.appService.getEventDateTime(this.selectedDate, end_time);

          temp_slots.push({
            t: start_time,
          });

          for (let index = 1; index < 500; index++) {

            temp_slots.push({
              t: this.appService.add15Minutes(temp_slots[index - 1].t),
            });

            if (this.appService.getTime24Format(temp_slots[index].t) == this.appService.getTime24Format(end_time)) {
              break;
            }
          }
        }
      });
    }
    this.slots.push(...temp_slots);
   
    this.sortSlots();
    this.slots.splice(-1,1)
  }

  sortSlots() {

    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < this.slots.length - 1; i++) {

        if (this.appService.isAfter(this.slots[i].t, this.slots[i + 1].t)) {
          let tmp = this.slots[i];
          this.slots[i] = this.slots[i + 1];
          this.slots[i + 1] = tmp;
          swapped = true;
        }

      }
    } while (swapped);

    this.deleteDuplicate();
  }

  deleteDuplicate() {

    let count = 0;
    let indexes = [];
    for (let i = 0; i < this.slots.length - 1; i++) {

      if (this.appService.isTimeSame(this.slots[i].t, this.slots[i + 1].t)) {
        indexes.push(i);
      }

    }

    for (let j = 0; j < indexes.length; j++) {
      this.slots.splice(indexes[j] - count, 1);
      ++count;
    }

    this.disablePassedTime();
  }

  disablePassedTime() {

    // remove passed time slots
    var swapped;
    do {
      swapped = false;
      for (var i = 0; i < this.slots.length - 1; i++) {
        if (this.appService.isPassed(this.slots[i].t, this.selectedDate)) {
          this.slots.splice(this.slots.indexOf(this.slots[i], 0), 1);
          swapped = true;
          break;
        }

      }
    } while (swapped);

    // check user events

    if (this.userEvents.length != 0) {
      var temp_slots1 = [];
      temp_slots1 = this.slots;
      this.slots = [];
      var isSame1 = false;
      for (const slot of temp_slots1) {
        isSame1 = false;
        for (const call of this.userEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame1 = true;
            break;
          }
        }
        if (!isSame1) {
          this.slots.push(slot);
        }
      }
    }

    // check user general events

    if (this.userGenEvents.length != 0) {
      var temp_slots2 = [];
      temp_slots2 = this.slots;
      this.slots = [];
      var isSame2 = false;
      for (const slot of temp_slots2) {
        isSame2 = false;
        for (const call of this.userGenEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame2 = true;
            break;
          }
        }
        if (!isSame2) {
          this.slots.push(slot);
        }
      }
    }

    // check counselor events

    if (this.counselorEvents.length != 0) {
      var temp_slots3 = [];
      temp_slots3 = this.slots;
      this.slots = [];
      var isSame3 = false;
      for (const slot of temp_slots3) {
        isSame3 = false;
        for (const call of this.counselorEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame3 = true;
            break;
          }
        }
        if (!isSame3) {
          this.slots.push(slot);
        }
      }
    }

    // check counselor general events

    if (this.counselorGenEvents.length != 0) {
      var temp_slots4 = [];
      temp_slots4 = this.slots;
      this.slots = [];
      var isSame4 = false;
      for (const slot of temp_slots4) {
        isSame4 = false;
        for (const call of this.counselorGenEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame4 = true;
            break;
          }
        }
        if (!isSame4) {
          this.slots.push(slot);
        }
      }
    }

    this.timeLoaded = true;
    this.spinner.hide('calls');
  }


  ngOnInit() {
    this.notificationService.requestPermission();
    this.getCategories();
    this.getCallRequests();
    this.generalCallInterval = setInterval(() => {
     // this.getCallRequestsInterval();
    }, 1000 * 10);
  }

  getCategories(l?: any) {
    if (l) {
      this.apiService.getCaseCategories(localStorage.getItem('token')).subscribe(
        (result: any) => {
          if (result.status) {
            this.alertInputs = [];
            this.categories = result.categories;
            this.categories.forEach(category => {
              this.alertInputs.push(
                {
                  name: 'category',
                  type: 'radio',
                  label: category.name,
                  value: category.name,
                }
              );
            });
          }
          l.hide('calls');
        },
        (err) => {
          console.log(err.error);
          l.hide('calls');
        }
      );

    } else {
      this.spinner.show('calls');
      this.apiService.getCaseCategories(localStorage.getItem('token')).subscribe(
        (result: any) => {
          if (result.status) {
            this.alertInputs = [];
            this.categories = result.categories;
            this.categories.forEach(category => {
              this.alertInputs.push(
                {
                  name: 'category',
                  type: 'radio',
                  label: category.name,
                  value: category.name,
                }
              );
            });
          }
          this.spinner.hide('calls');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('calls');
        }
      );
    }
  }

  getCallRequests(l?: any) {
    this.dataLoaded = false;

    if (l) {
      this.apiService.getGeneralCallsRequestOfUser(this.appService.getToken()).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.callRequests = res.data;

            if (this.appService.getUserType() == 'User') {
              this.counselorsSchedule = [];
              var counselors = res.counselors;
              counselors.forEach(counselor => {
                this.counselorsSchedule.push(counselor.schedule);
              });

              this.userEvents = res.userEvents;
              this.userGenEvents = res.userGenEvents;

            } else {
              this.counselorEvents = res.counselorEvents;
              this.counselorGenEvents = res.counselorGenEvents;

              this.removeCallsRequestOfUser();
            }
            this.getPendingCalls(l);
          }
          this.dataLoaded = true;
        },
        (err: any) => {
          this.dataLoaded = true;
          this.spinner.hide('calls');
        }
      );
    } else {
      var loader = this.spinner.show('calls');
      this.apiService.getGeneralCallsRequestOfUser(this.appService.getToken()).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.callRequests = res.data;

            if (this.appService.getUserType() == 'User') {

              this.counselorsSchedule = [];
              var counselors = res.counselors;
              counselors.forEach(counselor => {
                this.counselorsSchedule.push(counselor.schedule);
              });

              this.userEvents = res.userEvents;
              this.userGenEvents = res.userGenEvents;

            } else {
              this.counselorEvents = res.counselorEvents;
              this.counselorGenEvents = res.counselorGenEvents;

              this.removeCallsRequestOfUser();
            }
            this.getPendingCalls(loader);
            var obj =this;
            setTimeout(() => {
              obj.dataLoaded = true;
            }, 1000);
          }else{
            this.dataLoaded = true;
          }
         
        },
        (err: any) => {
          this.dataLoaded = true;
          this.spinner.hide('calls');
        }
      );
    }
  }

  getCallRequestsInterval() {
    this.apiService.getGeneralCallsRequestOfUser(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          console.log(res);
          if (res.status) {
            this.callRequests = res.data;

            if (this.appService.getUserType() == 'User') {

              this.counselorsSchedule = [];
              var counselors = res.counselors;
              counselors.forEach(counselor => {
                this.counselorsSchedule.push(counselor.schedule);
              });

              this.userEvents = res.userEvents;
              this.userGenEvents = res.userGenEvents;

            } else {
              this.counselorEvents = res.counselorEvents;
              this.counselorGenEvents = res.counselorGenEvents;

              this.removeCallsRequestOfUser();
            }
            this.getPendingCallsInterval();
          }
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  removeCallsRequestOfUser() {
    // check counselor events

    if (this.counselorEvents.length != 0) {
      var temp_slots3 = [];
      temp_slots3 = this.callRequests;
      this.callRequests = [];
      var isSame3 = false;
      for (const slot of temp_slots3) {
        isSame3 = false;
        for (const call of this.counselorEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.eventDate)) {
            isSame3 = true;
            break;
          }
        }
        if (!isSame3) {
          this.callRequests.push(slot);
        }
      }
    }

    // check counselor general events

    if (this.counselorGenEvents.length != 0) {
      var temp_slots4 = [];
      temp_slots4 = this.callRequests;
      this.callRequests = [];
      var isSame4 = false;
      for (const slot of temp_slots4) {
        isSame4 = false;
        for (const call of this.counselorGenEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.eventDate)) {
            isSame4 = true;
            break;
          }
        }
        if (!isSame4) {
          this.callRequests.push(slot);
        }
      }
    }
  }

  getPendingCallsInterval() {
    this.apiService.getGeneralPendingCalls(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.callPendings = res.data;

          // this.callPendings.forEach(call => {
          //   if (this.appService.isTimeAway(call.eventDate)) {
          //     this.apiService.changeGeneralCallStatus(this.appService.getToken(), call.id).subscribe(
          //       (res: any) => {
          //         console.log(res);
          //         if (res.status) {

          //         }
          //       },
          //       (err: any) => {
          //         console.log(err);
          //       }
          //     );
          //   }
          // });
        }
      },
      (err: any) => {
      }
    );
  }

  getPendingCalls(l?: any) {
    this.dataLoaded =false;
   // this.no_record_found = false;
    if (l) {
      this.apiService.getGeneralPendingCalls(this.appService.getToken()).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.server_time = res.current_time;
         
            this.callPendings = res.data;

            // this.callPendings.forEach(call => {
            //   if (this.appService.isTimeAway(call.eventDate)) {
            //     this.apiService.changeGeneralCallStatus(this.appService.getToken(), call.id).subscribe(
            //       (res: any) => {
            //         console.log(res);
            //         if (res.status) {

            //         }
            //       },
            //       (err: any) => {
            //         console.log(err);
            //       }
            //     );
            //   }
            // });
            
          }
          this.spinner.hide('calls');
          this.dataLoaded = true;
        },
        (err: any) => {
          this.dataLoaded = true;
          this.spinner.hide('calls');
        }
      );
    } else {
      this.dataLoaded = false;
      this.spinner.show('calls');
      this.apiService.getGeneralPendingCalls(this.appService.getToken()).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.callPendings = res.data;

            // this.callPendings.forEach(call => {
              // if (this.appService.isTimeAway(call.eventDate)) {
            //     this.apiService.changeGeneralCallStatus(this.appService.getToken(), call.id).subscribe(
            //       (res: any) => {
            //         console.log(res);
            //         if (res.status) {

            //         }
            //       },
            //       (err: any) => {
            //         console.log(err);
            //       }
            //     );
            //   }
            // });
          }
          this.spinner.hide('calls');
          this.dataLoaded = true;
        },
        (err: any) => {
          this.dataLoaded = true;
          this.spinner.hide('calls');
        }
      );
    }
  }

  getCallsHistory() {
    this.dataLoaded = false;
    this.spinner.show('calls');
    this.apiService.getGeneralCallsHistory(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.callsHistory = res.data;
          console.log(this.callsHistory)
          this.dataLoaded = true;
          console.log(this.dataLoaded)
          console.log(this.callSegment)
        }else{
          this.dataLoaded = true;
        }
        this.spinner.hide('calls')
        
      },
      (err: any) => {
        this.dataLoaded = true;
        this.spinner.hide('calls')
      }
    );
  }

  startCall(call: any) {
    if (this.appService.getUserType() == 'User') {
      this.spinner.show('calls');
      this.apiService.generalCallStartedByUser(this.appService.getToken(), call.id).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide('calls');
          this.router.navigate(['/video-call'], {
            queryParams: {
              session: call.session_id,
              token: call.counselor_token,
              isCaseCall: false,
              callId: call.id.toString()
            }
          });
        },
        (err: any) => {
          this.spinner.hide('calls');
          console.log(err);
        }
      );
    } else {
      this.spinner.show('calls');

      this.apiService.generalCallStartedByCounselor(this.appService.getToken(), call.id).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide('calls');
          this.router.navigate(['/video-call'], {
            queryParams: {
              session: call.session_id,
              token: call.counselor_token,
              isCaseCall: false,
              callId: call.id.toString()
            }
          });
        },
        (err: any) => {
          this.spinner.hide('calls');
          console.log(err);
        }
      );
    }
  }

  sendCallRequestToCounselor(eventDate: any) {
    var loader = this.spinner.show('calls');
    this.apiService.sendGeneralCallRequestByUser(this.appService.getToken(), this.selectedCategory, eventDate).subscribe(
      (res: any) => {
        this.onConfirmationCallClose();
        console.log(res);
        if (res.status) {
          this.appService.spendOneCredit();
          this.appService.showSuccessToast(res.message);
          this.getCallRequests(loader);
        }
        this.dataLoaded = true;
      },
      (err: any) => {
        this.dataLoaded = true;
        this.spinner.hide('calls');
      }
    );
  }

  cancelCallRequest(callId: any) {
    console.log(callId);
    var loader = this.spinner.show('calls');
    this.apiService.generalCallRequestCancelByUser(this.appService.getToken(), callId).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.getCallRequests(loader);
        }
        this.dataLoaded = true;
      },
      (err: any) => {
        this.dataLoaded = true;
        this.spinner.hide('calls');
      }
    );
  }

  callAcceptByCounselor(call: any) {
    var loader = this.spinner.show('calls');
    this.apiService.generalCallRequestAcceptByCounselor(this.appService.getToken(), call.id).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.showSuccessToast(res.message);
          this.getCallRequests(loader);
        }
        this.dataLoaded = true;
      },
      (err: any) => {
        this.dataLoaded = true;
        this.spinner.hide('calls');
      }
    );
  }

}
