import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CouncillorHomeComponent } from './components/councillor-home/councillor-home.component';
import { CouncillorHomeAfterLoginComponent } from './components/councillor-home-after-login/councillor-home-after-login.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatListingComponent } from './components/chat-listing/chat-listing.component';
import { CaseListingComponent } from './components/case-listing/case-listing.component';
import { UserListingComponent } from './components/user-listing/user-listing.component';
import { CasesDetailsComponent } from './components/cases-details/cases-details.component';
import { HomeComponent } from './components/home/home.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import {NgxAutoScrollModule} from "ngx-auto-scroll";

import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angular-6-social-login';
import { AuthServiceConfig } from 'angular-6-social-login';
import { WhatsKeepingComponent } from './components/whats-keeping/whats-keeping.component';
import { SelfhelpComponent } from './components/selfhelp/selfhelp.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AddcaseComponent } from './components/addcase/addcase.component';

import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { environment } from 'src/environments/environment';
import { AllCasesComponent } from './components/all-cases/all-cases.component';
import { InviteComponent } from './components/invite/invite.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';

import { AsyncPipe } from '../../node_modules/@angular/common';
import { NotificationService } from './services/notification.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { CreditComponent } from './components/credit/credit.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { CallsComponent } from './components/calls/calls.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { CaseChatComponent } from './components/case-chat/case-chat.component';
import { NotesComponent } from './components/notes/notes.component';
import { RatingModule } from 'ng-starrating';
import { PaymentComponent } from './components/payment/payment.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';

export function socialConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('1237504806432284')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        // provider: new GoogleLoginProvider('319449065662-9cavf7fanpi5d75ts2b58g8qnq6b0j4i.apps.googleusercontent.com')
        provider: new GoogleLoginProvider('319449065662-3feldl44r30s2d0if6laeu1pch37k4dq.apps.googleusercontent.com')

        //provider: new GoogleLoginProvider('com.googleusercontent.apps.454643478808-a4elh2tnp3lrqiri06jmla590ho0p6oh')
      }
    ]
  );
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FeedbackComponent,
    ProfileComponent,
    NotificationComponent,
    CouncillorHomeComponent,
    CouncillorHomeAfterLoginComponent,
    ChatComponent,
    ChatListingComponent,
    CaseListingComponent,
    UserListingComponent,
    CasesDetailsComponent,
    HomeComponent,
    EditProfileComponent,
    WhatsKeepingComponent,
    SelfhelpComponent,
    ForgotPasswordComponent,
    AddcaseComponent,
    AllCasesComponent,
    InviteComponent,
    CreditComponent,
    TransferComponent,
    CallsComponent,
    VideoCallComponent,
    CaseChatComponent,
    NotesComponent,
    PaymentComponent,
    LoginComponent,
    SignupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RatingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPageScrollModule,
    NgxAutoScrollModule,
    NgxPageScrollCoreModule.forRoot({ duration: 500 }),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxDocViewerModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AsyncPipe,
    NotificationService,
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: socialConfigs
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
