import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";
import { ValidatorService } from 'src/app/services/validator.service';
//import { setInterval } from 'timers';
import { AuthService, SocialUser, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
@Component({
  selector: 'app-councillor-home',
  templateUrl: './councillor-home.component.html',
  styleUrls: ['./councillor-home.component.css']
})
export class CouncillorHomeComponent implements OnInit {
  interval:any;
  loginMessage: any;
  sub_email: any;
  message: any;
  m_sub_email: any;
  subscription_email:any='';
  sub_email_error:any='';
  email: any;
  password: any;
  isLogin: boolean = false;
  isLoginPopUpShow: boolean = false;
  profileImage = '';
  userName = '';

  device_id: any;
  device_type: any;
  firstName: any;
  lastName: any;
  phone: any;
  confirmpassword: any;
  firstName_error: any;
  lastName_error: any;
  phone_error: any;
  email_error: any;
  password_error: any;
  confirmpassword_error: any;
  termsC: boolean = false;
  termsC_error: any;
  constructor(private spinner: NgxSpinnerService, private apiService: ApiService, private router: Router, public authService: AuthService,
    private appService: AppService, private validatorService: ValidatorService) {
      if(this.router.url.includes('/counselor-home-login')){
     setTimeout(() => {
      $(".login-trigger").trigger("click");
     }, 1000);
      }
      if(this.router.url.includes('/counselor-home-signup')){
        setTimeout(() => {
          $(".sign-trigger").trigger('click');
        }, 1000);
     
         }
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
      console.log(this.router.url)
  }

  sendSubscription() {
    this.m_sub_email = '';
    if (this.sub_email === '' || this.sub_email === undefined) {
      this.m_sub_email = 'Email required';
      return false;
    } else if (!this.validatorService.isEmailValid(this.sub_email)) {
      this.m_sub_email = 'Enter valid email';
      return false;
    } else {
      this.spinner.show('councillor-home');
      this.apiService.subscribeByEmail(this.sub_email).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide('councillor-home');
          if (res.status) {
            this.message = res.message;
            this.showMessage();
            this.sub_email = '';
          }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('councillor-home');

        }
      );
    }
  }

  showMessage() {
    $("#footer-popup").addClass("show");
    $("#footer-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }
  onCloseSubs() {
    $("#footer-popup").removeClass("show");
    $("#footer-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onChatItemClick() {
    this.loginMessage = 'You need to Login for Chat'
    $("#login-error").addClass("show");
    $("#login-error").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onCallItemClick() {
    this.loginMessage = 'You need to Login for Call'
    $("#login-error").addClass("show");
    $("#login-error").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }
  onTCClick() {
    const url = 'https://app.myzolve.com/term-of-service.html';
    window.open(url, "_system", "location=yes,enableViewportScale=yes,hidden=no");
  }
  validateFields() {
    this.firstName_error = ''; this.lastName_error = '';
    this.email_error = ''; this.password_error = ''; this.confirmpassword_error = '';
    this.phone_error = ''; this.termsC_error = '';
    // this.signup_error = '';
    if (this.firstName === undefined || this.firstName === '') {
      this.firstName_error = 'First Name required';
      return false;
    }
    if (this.lastName === undefined || this.lastName === '') {
      this.lastName_error = 'Last Name required';
      return false;
    }
    if (this.phone === undefined || this.phone === '') {
      this.phone_error = 'Phone Number required';
      return false;
    }
    if (!this.validatorService.isPhoneValid(this.phone)) {
      this.phone_error = 'Enter Valid Phone Number';
      return false;
    }
    if (this.email === undefined || this.email === '') {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter Valid Email Address';
      return false;
    }
    if (this.password === undefined || this.password === '') {
      this.password_error = 'Password required';
      return false;
    }
    if (!this.validatorService.isPasswordValid(this.password)) {
      this.password_error = 'Minimum 6 Chatacter required';
      return false;
    }
    if (this.password !== this.confirmpassword) {
      this.confirmpassword_error = 'Confirm Password missmatch. Try Again';
      return false;
    }
    if (this.termsC === false) {
      this.termsC_error = 'You need to agree with our Terms & Conditions';
      return false;
    }

    return true;
  }
  onSignUpBtnClick() {
    if (this.validateFields()) {
      this.spinner.show('header');
      this.apiService.registerUser(this.firstName + ' ' + this.lastName, this.email, this.phone, this.password, localStorage.getItem('notificationToken'), 'browser', localStorage.getItem('notificationToken')).subscribe(
        (result: any) => {
          if (result.status) {
            this.appService.setToken(result.jwtoken);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                if (res.status) {
                  this.appService.setUserType('User');
                  this.isLogin = true;
                  this.profileImage = res.user.profile_pic;
                  this.userName = res.user.fullname;
                  this.appService.setProfile(res.user);
                  this.appService.setProfile_password(this.password);
                  this.spinner.hide('header');
                  console.log('asdjgkl');
                  this.router.navigate(['/home']);
                  $("#sign-popup .close").trigger('click');
                }
              },
              (err) => {
                console.log(err.error);
                this.spinner.hide('header');
                // this.signup_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              })
          } else {
            this.spinner.hide('header');
            // this.signup_error = result.message;
            this.appService.showErrorToast(result.message);
          }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('header');
          // this.signup_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }
  
  onCloseBtnClickSignup() {
    this.resetSignupFields();
  }

  resetSignupFields() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = '';
    this.confirmpassword = '';
    this.phone = '';
    this.termsC = false;
    this.termsC_error = '';
    this.phone_error = '';
    this.confirmpassword_error = '';
    this.password_error = '';
    this.email_error = '';
    this.lastName_error = '';
    this.firstName_error = '';
  }
  onCasesItemClick() {
    this.loginMessage = 'You need to Login for Cases'
    $("#login-error").addClass("show");
    $("#login-error").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }
  onClose() {
    $("#login-error").removeClass("show");
    $("#login-error").css("display", "none");
    $(".modal-backdrop").remove();
  }
  onSelfHelpItemClick() {
    this.spinner.show('councillor-home');
    this.apiService.registerAnonymousUser('browser', 'browser', 'browser').subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.setToken(res.jwtoken);
          this.appService.setAnonymousUser(res.user);
          this.spinner.hide('councillor-home');
          this.router.navigate(['/whats-keeping']);
        }
      },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide('councillor-home');
      }
    );
  }
  ngOnInit() {
  this.interval =  setInterval(() => {
      if(localStorage.getItem('userId') == null && localStorage.getItem('allready_shown') == null){
      //  $(".modal-trigger").trigger("click");
        localStorage.setItem('allready_shown','yes');
      }
    }, 10000);
  }
  ClearInterval(){
    localStorage.setItem('allready_shown','yes')
    clearInterval(this.interval);
  }
  subscription(){
    this.sub_email_error = '';
    // this.login_error = '';
    if (this.subscription_email === '' || this.subscription_email === undefined) {
      this.sub_email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.subscription_email)) {
      this.sub_email_error = 'Enter valid email address';
      return false;
    }
    $(".now-close").trigger('click');
    this.spinner.show('councillor-home');
    this.apiService.subscribeByEmail(this.subscription_email).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide('councillor-home');
        if (res.status) {
          this.message = res.message;
          this.showMessage();
          this.subscription_email = '';
        }
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('councillor-home');

      }
    );
  }
  GoToLogin(){
    this.ClearInterval();
    $(".now-close").trigger('click');
    $(".login-trigger").trigger("click");
  }
  socialSignIn(socialProvider: string) {
    let socialPlatformProvider;
    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.authService.signIn(socialPlatformProvider).then(socialusers => {
      console.log('yesss');
      console.log(socialusers);
      this.register(socialusers);
    }, (err: any) => {
      console.log('errrrrrrrrr');
      console.log(err);

    });
  }
  register(userData: any) {
    this.spinner.show('header');
    this.apiService.socialLogin(userData.name, 'browser', userData.email,
      '', localStorage.getItem('notificationToken'), 'browser', '', '').subscribe(
        (result: any) => {
          if (result.status) {
            this.isLogin = true;
            this.appService.setToken(result.jwtoken);
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');
                  this.router.navigate(['/home']);
                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/chat-listing']);
                  } else {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/profile']);
                  }
                }
                this.email = '';
                this.password = '';
              },
              (err: any) => {
                this.spinner.hide('header');
                this.appService.showErrorToast(err.error.message);
              }
            );
          }
        },
        (err: any) => {
          this.appService.showErrorToast(err.error.message);
        }
      );
  }
  validateLoginFields() {
    this.email_error = ''; this.password_error = '';
    // this.login_error = '';
    if (this.email === '' || this.email === undefined) {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter valid email address';
      return false;
    }
    if (this.password === '' || this.password === undefined) {
      this.password_error = 'Password required';
      return false;
    }
    return true;
  }
  onLoginBtnClick() {
    if (this.validateLoginFields()) {
      this.spinner.show('header');
      this.apiService.login(this.email, this.password, localStorage.getItem('notificationToken'), 'browser').subscribe(
        (result: any) => {
          if (result.status) {
            this.isLogin = true;
            this.appService.setToken(result.jwtoken);
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                // this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');

                  this.router.navigate(['/home']);
                  this.spinner.hide('header');

                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/chat-listing']);
                    this.spinner.hide('header');

                  } else {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/profile']);
                    this.spinner.hide('header');

                  }
                }
                this.email = '';
                this.password = '';
              }, (err: any) => {
                this.spinner.hide('header');
                // alert(err.error);
                // this.login_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              });
          }
        }, (err: any) => {
          this.spinner.hide('header');
          // this.login_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }
  onForgotBtnClick() {
    $(".now-close").trigger('click');
    $("#login-popup .close").trigger('click');
    this.router.navigate(['/forgot-password']);
  }
}
