import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient) {
  }

  // Auth Apis

  public verifyToken(Token: any) {
    const url = environment.apiBaseUrl + 'auth/token-verify';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public login(Username: any, Password: any, DeviceId: any, DeviceType: any) {
    const url = environment.apiBaseUrl + 'auth/login';
    DeviceId = (DeviceId == null ? 'null' : DeviceId);
    DeviceType = (DeviceType == null ? 'null' : DeviceType);
    const body = { username: Username, password: Password, device_id: DeviceId, device_type: DeviceType };
    return this.http.post(url, body);
  }

  public logout(Token: any, DeviceId: any) {
    const url = environment.apiBaseUrl + 'auth/logout';
    DeviceId = (DeviceId == null ? 'null' : DeviceId);
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    const body = { device_id: DeviceId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public socialLogin(Username: any, UUID: any, Email: any, Phone: any, DeviceId: any, DeviceType: any, FbId: any, GoogleId: any) {
    const url = environment.apiBaseUrl + 'user/signup-fb-google';
    const body = {
      fullname: Username, uuid: UUID, email: Email, phone: Phone, device_id: DeviceId, device_type: DeviceType,
      social_id_facebook: FbId, social_id_google: GoogleId
    };
    return this.http.post(url, body);
  }

  public passwordResetRequest(Email: any) {
    const url = environment.apiBaseUrl + 'auth/password-reset-request';
    const body = { email: Email };
    return this.http.post(url, body);
  }

  public passwordResetToken(EmailOtp: any, SecurityToken: any) {
    const url = environment.apiBaseUrl + 'auth/password-reset-token';
    const body = { email_otp: EmailOtp, security_token: SecurityToken };
    return this.http.post(url, body);
  }

  public PasswordReset(PasswordNew: any, SecureToken: any) {
    const url = environment.apiBaseUrl + 'auth/password-reset';
    const body = { password_new: PasswordNew, secure_token: SecureToken };
    return this.http.post(url, body);
  }

  // Country Apis

  public getAllCountries(Token: any) {
    const url = environment.apiBaseUrl + 'country/list';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public getStatesList(Token: any, CountryId: any) {
    const url = environment.apiBaseUrl + 'country/states';
    const body = { country_id: CountryId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCitiesList(Token: any, StateId: any) {
    const url = environment.apiBaseUrl + 'country/cities';
    const body = { state_id: StateId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Education Apis

  public getEducationList(Token: any) {
    const url = environment.apiBaseUrl + 'user/educations';
    const body = { Token: Token };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Cases Apis

  public getUsers(Token: any, Page: any, search_str: any) {
    const url = environment.apiBaseUrl + 'cases/user-wise-list';
    const body = { page: Page, search_str: search_str,userId:localStorage.getItem('userId') };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCases(Token: any, Page: any, userId = '') {
    const url = environment.apiBaseUrl + 'cases/list';
    const body = { page: Page, user_id: userId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public rateCase(Token: any, caseId: any, Rating: any) {
    const url = environment.apiBaseUrl + 'cases/rate_case';
    const body = { caseId: caseId, rating: Rating };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public closeCase(Token: any, caseId: any) {
    const url = environment.apiBaseUrl + 'cases/close';
    const body = { caseId: caseId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCasesBySearch(Token: any, Search: any, Page: any, user_id: any) {
    const url = environment.apiBaseUrl + 'cases/search';
    const body = { search_str: Search, page: Page, user_id: user_id };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCaseById(Token: any, CaseId: any) {
    const url = environment.apiBaseUrl + 'cases/infoGet';
    const body = { case_id: CaseId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCasePrice(Token: any) {
    const url = environment.apiBaseUrl + 'cases/priceGet';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public getCaseCategories(Token: any) {
    const url = environment.apiBaseUrl + 'cases/categories';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public addNewCase(Token: any, CaseType: any, CategoryId: any, IncidentDate: any, Description: any, incident_end_date: any, management_notified: any, hr_notified: any, desired_outcome: any, doc_recipient: any, doc_date: any, doc_type: any, chat_room_name: any) {
    const url = environment.apiBaseUrl + 'cases/add';
    const body = { case_type: CaseType, category_id: CategoryId, incident_date: IncidentDate, description: Description, incident_end_date: incident_end_date, management_notified: management_notified, hr_notified: hr_notified, desired_outcome: desired_outcome, doc_recipient: doc_recipient, doc_date: doc_date, doc_type: doc_type, chat_room_name: chat_room_name };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public uploadCaseDocument(Token: any, CaseData: FormData) {
    const url = environment.apiBaseUrl + 'cases/documentUpload';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, CaseData, { headers: httpHeaders });
  }

  public getCaseDocuments(Token: any, CaseId: any, Page: any) {
    const url = environment.apiBaseUrl + 'cases/documentsGet';
    const body = { case_id: CaseId, page: Page };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public addNewMessage(Token: any, formdata: any) {
    const url = environment.apiBaseUrl + 'cases/message-new';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, formdata, { headers: httpHeaders });
  }

  public messageReply(Token: any, formdata: any) {
    const url = environment.apiBaseUrl + 'cases/message-reply';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, formdata, { headers: httpHeaders });
  }

  public getInboxMessages(Token: any, CaseId: any, Page: any) {
    const url = environment.apiBaseUrl + 'cases/messages-inbox';
    const body = { case_id: CaseId, page: Page };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getSentMessages(Token: any, CaseId: any, Page: any) {
    const url = environment.apiBaseUrl + 'cases/messages-sent';
    const body = { case_id: CaseId, page: Page };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getMessageConversation(Token: any, ConversationId: any) {
    const url = environment.apiBaseUrl + 'cases/message-conversation';
    const body = { conversation_id: ConversationId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Feedback Apis

  public addFeedBack(Token: any, Feedback: any, type: any) {
    const url = environment.apiBaseUrl + 'feedback/add';
    const body = { feedback: Feedback, feedback_type: type };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getFeedBack(Token: any) {
    const url = environment.apiBaseUrl + 'feedback​/get';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  // Notification Apis

  public getNotificationCount(Token: any) {
    const url = environment.apiBaseUrl + 'notifications/count';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  clearNotificationCount(Token: any, fcm: any) {
    const url = environment.apiBaseUrl + 'notifications/clear_not';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: fcm };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getNotificationList(Token: any, Page: any) {
    const url = environment.apiBaseUrl + 'notifications/list';
    const body = { page: Page };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Payment Apis

  public getPaymentList(Token: any, Page: any) {
    const url = environment.apiBaseUrl + 'payment/list';
    const body = { page: Page };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Self-Help Apis

  public getSelfHelpTopics(Token: any) {
    const url = environment.apiBaseUrl + 'selfhelp/relevant-topics';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public getFirstQuestion(Token: any, HelpTopicId: any) {
    const url = environment.apiBaseUrl + 'selfhelp/question-first';
    const body = { help_topic_id: HelpTopicId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getSolutionOrNextQuestion(Token: any, OptionId: any) {
    const url = environment.apiBaseUrl + 'selfhelp/next-question-solution';
    const body = { option_id: OptionId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public postSolutionResponse(Token: any, SolutionId: any, HelpTopicId: any, WasSolutionHelpful: any, Rating: any) {
    const url = environment.apiBaseUrl + 'selfhelp/solution-helpful';
    const body = { solution_id: SolutionId, help_topic_id: HelpTopicId, was_solution_helpful: WasSolutionHelpful, rating: Rating };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // User Apis

  public registerUser(Name: any, Email: any, Phone: any, Password: any, DeviceId: any, DeviceType: any, uuid: any) {
    const url = environment.apiBaseUrl + 'user/signup';
    if (DeviceId == null || uuid == null) {
      DeviceId = ' ';
      uuid = ' ';
    }
    const body = { fullname: Name, email: Email, phone: Phone, password: Password, device_id: DeviceId, device_type: DeviceType, uuid: uuid };
    return this.http.post(url, body);
  }

  public registerAnonymousUser(Uuid: any, DeviceId: any, DeviceType: any) {
    const url = environment.apiBaseUrl + 'user/signup-uuid';
    let body: any;
    if (Uuid === null) {
      body = { uuid: 'browser', device_id: 'browser', device_type: 'browser' };
    } else {
      body = { uuid: Uuid, device_id: DeviceId, device_type: DeviceType };
    }
    return this.http.post(url, body);
  }

  public getUserProfile(Token: any,) {
    const url = environment.apiBaseUrl + 'user/profileGet';
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public editUserProfile(Token: any, Name: any, Address: any, Phone: any, Country: any, State: any, City: any, education: any, Company: any, position: any, calendlyUrl: any, startTime: any, endTime: any, startDay: any, endDay: any) {
    const url = environment.apiBaseUrl + 'user/profileEdit';
    const body = { fullname: Name, address: Address, phone: Phone, country: Country, state: State, city: City, education: education, company: Company, position: position, calendlyUrl: calendlyUrl, startTime: startTime, endTime: endTime, endDay: endDay, startDay: startDay };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public uploadProfilePic(Token: any, ProfileImage: any) {
    const url = environment.apiBaseUrl + 'user/profilePicUpload';
    const body = { profile_image: ProfileImage, base64image: ProfileImage };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public changeUserPassword(Token: any, OldPassword: any, NewPassword: any) {
    const url = environment.apiBaseUrl + 'user/change-password';
    const body = { password_old: OldPassword, password_new: NewPassword };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getUsersBySearch(Token: any, Search: any, Page: any) {
    const url = environment.apiBaseUrl + 'user/users';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { search: Search, page: Page };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  setSchedule(Token: any, data: any) {
    const url = environment.apiBaseUrl + 'user/setSchedule';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { schedule: data };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Subscription api

  public subscribeByEmail(Email: any) {
    const url = environment.apiBaseUrl + 'newsletter/subscribe';
    const body = { email: Email };
    return this.http.post(url, body);
  }

  // Admin Apis

  public getAllCasesByAdmin(Token: any, Page: any, Search: any, userId?: any) {
    const url = environment.apiBaseUrl + 'admin/cases-list';
    const body = { page: Page, search_str: Search, userId: userId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }
  public getAllCasesByAdminnew(Token: any, Page: any, Search: any, userId?: any) {
    const url = environment.apiBaseUrl + 'admin/cases-list';
    const body = { page: Page, search_str: Search, userId: localStorage.getItem('userId') };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getAllUsersByAdmin(Token: any, Page: any, Search: any) {
    const url = environment.apiBaseUrl + 'admin/users-list';
    const body = { page: Page, search_str: Search };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public assignCouncilorByAdmin(Token: any, CaseId: any, CouncilorId: any) {
    const url = environment.apiBaseUrl + 'admin/assign-counselor';
    const body = { case_id: CaseId, counselor_id: CouncilorId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public reAssignCouncilorByAdmin(Token: any, CaseId: any, CouncilorId: any, lastCounselorId: any, userId: any) {
    const url = environment.apiBaseUrl + 'admin/reassign-counselor';
    const body = { case_id: CaseId, counselor_id: CouncilorId, last_counselor_id: lastCounselorId, user_id: userId };
    const httpHeaders = new HttpHeaders({
      token: Token
    });
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getAllCouncilorByAdmin(Token: any) {
    console.log(Token);
    const url = environment.apiBaseUrl + 'admin/counselor-list';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { Token: Token };
    return this.http.post(url, body, { headers: httpHeaders });
  }
  public addInvite(Token: any, name: any, email: any, type: any, senderName: any) {
    const url = environment.apiBaseUrl + 'admin/counselor-invite';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, email: email, fullname: name, user_type: type, sender_name: senderName };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Chat Apis

  public uploadChatAttachment(Token: any, FileData: FormData) {
    const url = environment.chatFileUploadUrl + 'chat/file-upload';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, FileData, { headers: httpHeaders });
  }

  public uploadCaseChatAttachment(Token: any, FileData: FormData) {
    const url = environment.chatFileUploadUrl + 'chat/file-upload-case';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, FileData, { headers: httpHeaders });
  }

  public getChatRequests(Token: any) {
    const url = environment.apiBaseUrl + 'chat/get_chat_requests';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public getChatHistory(Token: any, userId: any, userType: any) {
    const url = environment.apiBaseUrl + 'chat/get_chat_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { user_id: userId, user_type: userType };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getChatRequestsByUserId(Token: any) {
    const url = environment.apiBaseUrl + 'chat/get_chat_requests_by_userid';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public sendChatRequest(Token: any, caseTopic: any, status: any, chatRoomName: any) {
    const url = environment.apiBaseUrl + 'chat/send_chat_request';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, case_topic: caseTopic, status: status, chat_room_name: chatRoomName };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public acceptChatRequest(Token: any, reqId: any, userId: any,) {
    const url = environment.apiBaseUrl + 'chat/accept_chat_request';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, req_id: reqId, user_id: userId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public startChatByUser(Token: any, reqId: any, userId: any,) {
    const url = environment.apiBaseUrl + 'chat/start_chat_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, req_id: reqId, counselor_id: userId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public payment_history(Token: any) {
    const url = environment.apiBaseUrl + 'payment/payment_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token,userId: localStorage.getItem('userId') };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public endChat(Token: any, reqId: any, userId: any, Rating?: any) {
    const url = environment.apiBaseUrl + 'chat/end_chat';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, user_id: userId, req_id: reqId, rating: Rating };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public sendChatMsgNotificationToUser(Token: any, reqId: any, userId: any) {
    const url = environment.apiBaseUrl + 'chat/notify_to_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: Token, user_id: userId, req_id: reqId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Credit Apis

  public getCreditPackages(Token: any) {
    const url = environment.apiBaseUrl + 'payment/credit_packages';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.get(url, { headers: httpHeaders });
  }

  public purchaseCredit(Token: any, UserId: any, UserType: any, Cost: any, Credit: any, StripeToken: any, PackageName: any) {
    const url = environment.apiBaseUrl + 'payment/purchase_credit';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { token: StripeToken, userId: UserId, userType: UserType, cost: Cost, credit: Credit, package_name: PackageName };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public transferCredit(Token: any, Credit: any, SenderId: any, SenderType: any, ReceiverId: any, ReceiverType: any) {
    const url = environment.apiBaseUrl + 'payment/transfer_credit';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = {
      credit: +Credit,
      senderId: SenderId,
      sender_type: SenderType,
      receiverId: ReceiverId,
      receiver_type: ReceiverType
    };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  public getCreditTransferHistoryByUser(Token: any) {
    const url = environment.apiBaseUrl + 'payment/transfer_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public getCreditHistoryOfUser(Token: any) {
    const url = environment.apiBaseUrl + 'payment/credit_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  public creditTransaction(Token: any, Credit: any, Cost: any, Activity: any) {
    const url = environment.apiBaseUrl + 'payment/credit_transaction';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { credit: Credit, cost: Cost, activity: Activity };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Case Chat Apis

  sendCaseMsgNotificationToUser(Token: any, UserId: any, CaseId: any) {
    const url = environment.apiBaseUrl + 'cases/notify_to_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { user_id: UserId, case_id: CaseId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Case Calls Apis

  getCaseCallsHistory(Token: any, CaseId: any) {
    const url = environment.apiBaseUrl + 'casecalls/calls_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { caseId: CaseId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  getCaseCallsRequestOfUser(Token: any, CaseId: any, CounselorId: any, UserId: any) {
    const url = environment.apiBaseUrl + 'casecalls/calls_request';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { caseId: CaseId, counselorId: CounselorId, userId: UserId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  sendCaseCallRequestByUser(Token: any, CaseId: any, CounselorId: any, UserId: any, eventDate: any) {
    const url = environment.apiBaseUrl + 'casecalls/call_request_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { caseId: CaseId, counselorId: CounselorId, userId: UserId, eventDate: eventDate };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  caseCallStartedByUser(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'casecalls/call_started_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  caseCallStartedByCounselor(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'casecalls/call_started_by_counselor';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  completeCaseCallFromBothSide(Token: any, CallId: any, Rating?: any,call_type?: any) {
    const url = environment.apiBaseUrl + 'casecalls/com_call_from_both_side';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId, rating: Rating,call_type:call_type };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  changeCaseCallStatus(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'casecalls/change_case_call_status';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // General calls Apis

  getGeneralCallsHistory(Token: any) {
    const url = environment.apiBaseUrl + 'generalcalls/calls_history';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  getGeneralCallsRequestOfUser(Token: any) {
    const url = environment.apiBaseUrl + 'generalcalls/calls_request';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  getGeneralPendingCalls(Token: any) {
    const url = environment.apiBaseUrl + 'generalcalls/calls_pending';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    return this.http.post(url, null, { headers: httpHeaders });
  }

  sendGeneralCallRequestByUser(Token: any, Category: any, EventDate: any) {
    const url = environment.apiBaseUrl + 'generalcalls/call_request_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { category: Category, eventDate: EventDate };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  generalCallRequestAcceptByCounselor(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'generalcalls/call_request_accept_by_counselor';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  generalCallRequestCancelByUser(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'generalcalls/call_request_cancel_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  generalCallStartedByUser(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'generalcalls/call_started_by_user';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }
  updateToken(Token: any, firebase: any) {
    const url = environment.apiBaseUrl + 'chat/update_token';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { fire_base: firebase };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  generalCallStartedByCounselor(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'generalcalls/call_started_by_counselor';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  completeGenCallFromBothSide(Token: any, CallId: any, Rating?: any,call_type?: any) {
    const url = environment.apiBaseUrl + 'generalcalls/com_call_from_both_side';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId, rating: Rating,call_type:call_type };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  changeGeneralCallStatus(Token: any, CallId: any) {
    const url = environment.apiBaseUrl + 'generalcalls/change_gen_call_status';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { callId: CallId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  // Notes Apis

  addNote(Description: any, CaseId: any, Token: any) {
    const url = environment.apiBaseUrl + 'cases/add-note';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { description: Description, case_id: CaseId };
    return this.http.post(url, body, { headers: httpHeaders });
  }

  getNotes(Token: any, CaseId: any, Page: any) {
    const url = environment.apiBaseUrl + 'cases/note-list';
    const httpHeaders = new HttpHeaders({
      token: Token,
    });
    const body = { case_id: CaseId, page: Page };
    return this.http.post(url, body, { headers: httpHeaders });
  }
}
