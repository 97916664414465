import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StripeService, Element as StripeElement, ElementOptions, ElementsOptions } from "@nomadreservations/ngx-stripe";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { Events} from '../profile/events';
import * as firebase from 'firebase';
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-chat-listing',
  templateUrl: './chat-listing.component.html',
  styleUrls: ['./chat-listing.component.css']
})
export class ChatListingComponent implements OnInit {
  showFirst: any = 'false';
  startChat: boolean = true;
  chatSearch: any;
  FirstTimeLogin: any; message: any;
  notificationCount: any; conversation: any = [];
  alertInputs = []; sender_profile_pic: any = '';
  // success_message: any; error_message: any;
  name: any;
  chatHistory: any[] = [];
  requests = []; id_ForConversation: any;
  chatUsers = []; show_history: any = 'false'; go_conversation: any = 'false';
  picurePath = environment.profilePicBaseUrl;
  chatReqId: any;
  recpId: any;
  chatSegment = 'Active';
  fileBaseUrl: any;
  thumbBaseUrl: any;
  messageListingPage = 1;
  socket: any;
  isPending:boolean =true;
  isHistoryTab: boolean = false;
  isActiveTab: boolean = false;
  categories: any[];
  category: any;
  selectedCategory: any;
  category_error: any;
  requestedUsers: any[] = [];

  dataLoaded: boolean = false;
  server_time:any;
  stripeKey = '';
  error: any;
  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  isPackageTab: boolean = true;
  isCreditsTab: boolean = false;

  creditHistory: any[] = [];
  packages: any[] = [];
  packagee_error: any = '';
  packagee: any = '';
  // currentMessage = new BehaviorSubject(null);
  credit: number = 0;
  credit_error: any = '';
  class:any='margin-s';
  constructor(public appService: AppService, private router: Router, private apiService: ApiService,
    private route: ActivatedRoute, private spinner: NgxSpinnerService, private stripe: StripeService,
    private angularFireMessaging: AngularFireMessaging,public events: Events,private notificationService: NotificationService) {
    // this.openConnection();


    this.events.subscribe('chat_request', (data) => {
 
    })
   
    setInterval(() => {
      var obj = this;
      var check = localStorage.getItem('msg_req');
      //console.log(check)
      if(check == 'yes'){
      setTimeout(() => {
        localStorage.setItem('msg_req','')
      }, 500);  
      if (obj.appService.getUserType() === 'User' || obj.appService.getUserType() === 'Anonymous') {
        obj.getCategories();
        obj.getChatReqByUserId();
      } else if ((obj.appService.getUserType() == 'Admin' || obj.appService.getUserType() == 'Counselor')) {
       // obj.onPendingTabClick();
       if(obj.isActiveTab){
        obj.isActiveTab = true;
        obj.isHistoryTab = false;
        obj.isPending = false;
        obj.onActiveTabClick();
    
      }
      if(obj.isHistoryTab){
        obj.isActiveTab = false;
        obj.isHistoryTab = true;
        obj.isPending = false;
        obj.onHistoryTabClick();
      }
      if(obj.isPending){
      obj.isActiveTab = false;
      obj.isHistoryTab = false;
      obj.isPending = true;
      obj.onPendingTabClick();
      }
      }
    }
    }, 1000);


    if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      this.getCategories();
      this.getChatReqByUserId();
    } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
      this.requestedUsers =[];
      this.isPending= true;
      this.isHistoryTab = false;
      this.isActiveTab = false;
      //this.onPendingTabClick();
      //$("#pending-tab").trigger('click');
    
      //pending-tab

    }

    setTimeout(() => {
      if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      //  this.getChatReqByUserId();
      } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
        //this.getChatReq();
      }
    }, 4000);

    // this.angularFireMessaging.messages.subscribe(
    //   (payload: any) => {
    //     console.log("new message received. ", payload);
    //     // this.currentMessage.next(payload);

    //     const notification = payload.data;
    //     console.log(notification);

    //     // general call notification

    //     if (notification.type == 'gen_call_request') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     } else if (notification.type == 'gen_call_accept') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case call notification

    //     if (notification.type == 'call_request') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // chat notification

    //     if (notification.type == 'chat_request') {
    //       if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
    //         this.getChatReqByUserId();
    //       } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
    //         this.getChatReq();
    //       }
    //       if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {
    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });

    //       }

    //     }

    //     if (notification.type == 'chat_started_by_user') {
    //       if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
    //         this.getChatReqByUserId();
    //       } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
    //         this.getChatReq();
    //       }
    //       if (this.appService.getUserType() == 'Counselor' || this.appService.getUserType() == 'Admin') {
    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });

    //       }

    //     }

    //     if (notification.type == 'chat_request_accepted') {
    //       if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
    //         this.getChatReqByUserId();
    //       } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
    //         this.getChatReq();
    //       }
    //       if (this.appService.getUserType() == 'User') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });

    //       }

    //     }

    //     if (notification.type == 'end_chat') {
    //       this.router.navigate(['/chat-listing']);
    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     if (notification.type == 'chat_message') {
    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // transfer credit notification

    //     if (notification.type == 'transfer_credit') {

    //       this.appService.setCredit(+notification.credit);

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case message notification

    //     if (notification.type == 'case_message') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // case document notification

    //     if (notification.type == 'case_document') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     // add case notification

    //     if (notification.type == 'add_case') {

    //       $(".custom-toastr").show();
    //       $(".custom-toastr .text").text(notification.body);

    //       $('.close').on("click", function () {
    //         $(this).parent().hide();
    //       });

    //     }

    //     if (notification.type == 'assign_case') {
    //       if (this.appService.getUserType() != 'Admin') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });


    //       }

    //     }

    //     if (notification.type == 'reassign_case') {
    //       if (this.appService.getUserType() != 'Admin') {

    //         $(".custom-toastr").show();
    //         $(".custom-toastr .text").text(notification.body);

    //         $('.close').on("click", function () {
    //           $(this).parent().hide();
    //         });
    //       }

    //     }
    //   }
    // );
  }
  SignInFirebase(){
    console.log('Logging in Firebase User');

    firebase.auth().signInWithEmailAndPassword("pukarpukar@gmail.com", "123456789")
        .then(function (callback)
        {
          console.log('callback');
          console.log(callback);
        })
        .catch(function(login_error)
        {
            let loginErrorCode = login_error.code;
            let loginErrorMessage = login_error.message;

            console.log(loginErrorCode);
            console.log(loginErrorMessage);

            if (loginErrorCode === 'auth/user-not-found')
            {
             
            }
        });
  }
  onStartChatBtnClick() {
    this.startChat = !this.startChat;

    this.isPending= false;
    this.isHistoryTab = false;
    this.isActiveTab = true;
    if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      this.getChatReqByUserId();
    }
  }

  onPacakgeTabClick() {
    this.isPackageTab = true;
    this.isCreditsTab = false;
  }

  onCreditsTabClick() {
    this.isCreditsTab = true;
    this.isPackageTab = false;
  }

  onBuyNowBtnClick() {
    console.log(this.packagee);
    this.credit_error = '';
    if (this.isPackageTab) {
      this.onCancelBtnClick();
      $("#card-popup").addClass("show");
      $("#card-popup").css("display", "block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      if (this.credit > 0) {
        this.onCancelBtnClick();

        $("#card-popup").addClass("show");
        $("#card-popup").css("display", "block");
        $("body").append("<div class='modal-backdrop fade show'></div>");
      } else {
        this.credit_error = 'Invalid credit';
      }
    }
  }

  onCancel() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onCancelPopup() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onConfirm() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
    this.spinner.show('chat');
    this.stripe.createToken(this.element, {
    }).subscribe(
      (res: any) => {
        console.log(res.token.id);
        if (this.isPackageTab) {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), this.packages[this.packagee - 1].cost, this.packages[this.packagee - 1].credit, res.token.id, this.packages[this.packagee - 1].name).subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              localStorage.setItem('credit', (this.packages[this.packagee - 1].credit + +this.appService.getCredit()).toString());
              this.spinner.hide('chat');
              this.onCancel();
              this.onCancelPopup();
              // this.getCreditHistory();
              this.onCategoryChange();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('chat');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        } else {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), (this.credit * 19.99), this.credit, res.token.id, 'no_package').subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              var credit = +this.credit + +this.appService.getCredit();
              localStorage.setItem('credit', credit.toString());
              this.spinner.hide('chat');
              this.onCancelPopup();
              this.onCancel();
              this.onCategoryChange();

              // this.getCreditHistory();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('chat');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide('chat');
        this.onCancelPopup();
        this.onCancel();
      }
    );
  }

  cardUpdated(result) {
    console.log('asdg');
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  onCancelBtnClick() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  ngOnInit() {
    this.SignInFirebase();
    this.notificationService.requestPermission();
    if (this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor') {
      this.onStartChatBtnClick();
      this.onPendingTabClick();
      // this.getChatReq();
    }
    // else {
    //   this.getChatReqByUserId();
    // }
  }

  // getChatReq() {
  //   this.spinner.show('chat');
  //   this.apiService.getChatRequests(localStorage.getItem('token')).subscribe(
  //     (result: any) => {
  //       console.log(result);
  //       if (result.status) {
  //         let requests = [];
  //         requests = result.data;

  //         requests.forEach(req => {

  //           const prev_status = req.status;

  //           req.status = 'Sent';
  //           const data = {
  //             message: req.user.fullname + " requested you to have a chat.",
  //             chatRequestId: req.id,
  //             status: req.status,
  //             createdAt: req.createdAt,
  //             user: {
  //               name: req.user.fullname,
  //               userType: 'User',
  //               profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
  //               caseTopic: req.caseTopic,
  //               userId: req.user.id,
  //               chatRequestId: req.id
  //             }
  //           }

  //           if (prev_status == 'Sent') {
  //             if (this.appService.isChatTimeAway(req.createdAt)) {
  //             } else {
  //               this.requests.push(data);
  //             }
  //           } else if (prev_status == 'Accepted') {
  //             if (this.appService.isTimeAway(req.createdAt)) {
  //             } else {
  //               this.requests.push(data);
  //             }
  //           }

  //         });
  //       }
  //       this.spinner.hide('chat');
  //     },
  //     (err) => {
  //       console.log(err.error);
  //       this.spinner.hide('chat');
  //     }
  //   );
  // }

  getCategories() {
    this.apiService.getCaseCategories(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.categories = res.categories;
        }
      },
      (err: any) => {
        console.log(err.error);
      }
    );
  }

  onConfirmationCallClose() {
    $("body").removeClass("modal-open");
    $("#call-confirmation-popup").removeClass("show");
    $("#call-confirmation-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onCategoryChange() {
    console.log(this.category);
    $("#category-popup .close").trigger('click');
    if (+this.appService.getCredit() > 0) {
      // this.addUser(this.category);
      this.selectedCategory = this.category;
      this.category = '';
      $("body").addClass("modal-open");
      $("#call-confirmation-popup").addClass("show");
      $("#call-confirmation-popup").css("display", "block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      this.appService.showErrorToast('Your credit is not enough for chat');
      this.onAddCreditBtnClick();
    }
  }

  onAddCreditBtnClick() {
    $("#buy-credit-popup").addClass("show");
    $("#buy-credit-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.getPackages();
  }

  getPackages() {
    this.spinner.show('chat');
    this.apiService.getCreditPackages(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.packages = res.data;
          this.packagee = this.packages[0].id;

        }
        this.dataLoaded = true;
        this.spinner.hide('chat');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('chat');
      }
    );
  }

  openConnection() {
    this.socket = new WebSocket(environment.chatBaseUrl + localStorage.getItem('token'));
    this.socket.onopen = () => {
      this.socket.onmessage = (res: any) => {
        console.log(res);
        console.log('res.data  console.log(res.data);');
        console.log(this.requests);
        var new_res = JSON.parse(res.data);
        console.log(new_res.data);
        console.log(this.chatSegment);
        if (new_res.cmd === 'connection') {

        } else if (new_res.status) {
          this.appService.showSuccessToast(new_res.data.message);
        } else {
          this.appService.showErrorToast(new_res.data.message);
        }
        this.addCouncilor();
      }
    };
  }

  addCouncilor() {
    if (this.appService.getUserType() === 'Counselor') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Counselor", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
      this.socket.onmessage = (res: any) => {
        console.log(res.data);
        // this.getChatUsers();
      };
    } else if (this.appService.getUserType() === 'Admin') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Admin", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
      this.socket.onmessage = (res: any) => {
        console.log(res.data);
        // this.getChatUsers();
      };
    }
    // this.addUser('');
    // this.getChatUsers();
  }

  onActiveTabClick() {
    var oo =this;
    setTimeout(() => {
      oo.class='margin-setts';
    }, 1500);
    this.isPending= false;
    this.isHistoryTab = false;
    this.isActiveTab = true;
    if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      this.getChatReqByUserId();
    } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
      this.getChatReq();
    }
  }
  onPendingTabClick() {
    this.requestedUsers =[]
    this.isPending= true;
    this.isHistoryTab = false;
    this.isActiveTab = false;
    if (this.appService.getUserType() === 'User' || this.appService.getUserType() === 'Anonymous') {
      this.getChatReqByUserId();
    } else if ((this.appService.getUserType() == 'Admin' || this.appService.getUserType() == 'Counselor')) {
      this.getChatReq();
    }
  }
  onHistoryTabClick() {
    this.chatHistory =[];
    this.isPending= false;
    this.isActiveTab = false;
    this.isHistoryTab = true;
    // this.getChatUsers();
    this.getChatHistory();
  }

  getChatHistory() {
    this.dataLoaded = false;
    this.spinner.show('chat');
    this.apiService.getChatHistory(localStorage.getItem('token'), this.appService.getProfile().id, this.appService.getUserType()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.chatHistory = res.data;
          this.dataLoaded = true;
        }else{
          this.dataLoaded = false;
        }
        this.spinner.hide('chat');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('chat');
        this.dataLoaded = false;
      }
    );
  }


  getChatUsers() {

    const data = { cmd: 'chat-users', data: {} };
    this.socket.send(JSON.stringify(data));
    this.socket.onmessage = (res: any) => {
      res = JSON.parse(res.data);
      console.log(res.data);
      this.chatUsers = [];
      this.chatUsers.push(...res.data);
      console.log(this.chatUsers);
      this.socket.onmessage = (res: any) => {
        console.log(res.data);
        res = JSON.parse(res.data);
        if (res.cmd === 'chat-request-accepted') {
          this.requests = [];
        } else if (res.cmd == 'chat-request') {
          this.requests.push(res.data);
          console.log(this.requests);
        } else if (res.cmd === 'counselor-joined-chat') {
          this.chatReqId = res.data.chatRequestId;
          this.recpId = res.data.counselor.id;
          this.requests.push(res.data);
        }
      }
    };
  }

  onuserCardClick() {

  }

  addUser(category: any) {
    if (this.appService.getUserType() === 'User') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "User", "profilePic": userProfile.profilePic, "caseTopic": category }
      };
      this.socket.send(JSON.stringify(data));
      this.socket.onmessage = (res: any) => {
        console.log(res.data);
        this.sendChatRequest();
      };
    } else if (this.appService.getUserType() === 'Anonymous') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Anonymous", "profilePic": userProfile.profilePic, "caseTopic": category }
      };
      this.socket.send(JSON.stringify(data));
      this.socket.onmessage = (res: any) => {
        console.log(res.data);
        this.sendChatRequest();
      };
    }
  }

  getChatReq() {
    var msg;
    this.requestedUsers = [];
    let requests = [];
    this.dataLoaded =false;
    this.spinner.show('chat');
    this.apiService.getChatRequests(localStorage.getItem('token')).subscribe(
      (result: any) => {
        console.log(result);
        if (result.status) {
          let requests = [];
          this.requestedUsers = [];
          requests = result.data;
          this.server_time = result.current_time;
         
          requests.forEach(req => {
            if(req.status == 'Sent' && this.isPending){
              if(this.appService.getUserType() == 'User'){
                 msg = "Chat request sent to Employee Advocates";
              }else{
                 msg = req.user.fullname + " has requested a chat.";
              }
            const data = {
              message: msg,
              chatRequestId: req.id,
              status: req.status,
              createdAt: req.createdAt,
              updatedAt: req.updatedAt,
              chatRoomName: req.chatRoomName,
              caseTopic: req.caseTopic,
              user: {
                name: req.user.fullname,
                userType: 'User',
                profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
                caseTopic: req.caseTopic,
                userId: req.user.id,
                chatRequestId: req.id
              }
            }

            if (req.status == 'Sent' || req.status == 'Accepted') {
              if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
              } else {
                if (req.counselor) {
                  this.requestedUsers.push(data);
                } else {
                  this.requestedUsers.push(data);
                }
              }
            } else if (req.status == 'Start') {
              if (this.appService.isTimeAway(req.updatedAt)) {
              } else {
                if (req.counselor) {
                  this.requestedUsers.push(data);
                } else {
                }
              }
            }
          }
          if(this.isActiveTab){
            if(this.appService.getUserType() == 'User'){
              msg = "Chat request sent to Employee Advocates";
           }else{
              msg = req.user.fullname + " has requested a chat.";
           }
            const data = {
              message: msg,
              chatRequestId: req.id,
              status: req.status,
              createdAt: req.createdAt,
              updatedAt: req.updatedAt,
              chatRoomName: req.chatRoomName,
              caseTopic: req.caseTopic,
              user: {
                name: req.user.fullname,
                userType: 'User',
                profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
                caseTopic: req.caseTopic,
                userId: req.user.id,
                chatRequestId: req.id
              }
            }

            if ( req.status == 'Accepted') {
              if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
              } else {
                if (req.counselor) {
                  this.requestedUsers.push(data);
                } else {
                  this.requestedUsers.push(data);
                }
              }
            } else if (req.status == 'Start') {
              if (this.appService.isTimeAway(req.updatedAt)) {
              } else {
                if (req.counselor) {
                  this.requestedUsers.push(data);
                } else {
                }
              }
            }
          }
          
          });
          this.dataLoaded =true;
        }else{
          this.dataLoaded =false;
        }
        console.log('--------------------------------------') 
        console.log( this.requestedUsers)
        this.spinner.hide('chat');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('chat');
        this.dataLoaded =false;
      }
    );
  }

  getChatReqByUserId() {
    var msg;
    this.dataLoaded =false;
    this.spinner.show('chat');
    this.apiService.getChatRequestsByUserId(localStorage.getItem('token')).subscribe(
      (result: any) => {
        console.log(result);
        if (result.status) {
          console.log('-------------------------------------')
          let requests = [];
          this.requestedUsers = [];
          requests = result.data;
          this.server_time = result.current_time;
          requests.forEach(req => {

            if (req.status == 'Sent') {
              if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
              } else {

                if(this.appService.getUserType() == 'User'){
                  msg = "Chat request sent to Employee Advocates";
               }else{
                  msg = req.user.fullname + " has requested a chat.";
               }
                const data = {
                  message: msg,
                  chatRequestId: req.id,
                  status: req.status,
                  createdAt: req.createdAt,
                  updatedAt: req.updatedAt,
                  chatRoomName: req.chatRoomName,
                  caseTopic: req.caseTopic,
                  user: {
                    name: req.user.fullname,
                    userType: 'Counselor',
                    profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
                    caseTopic: req.caseTopic,
                    userId: req.user.id,
                    chatRequestId: req.id
                  }
                }
                this.requestedUsers.push(data);
              }
            } else if (req.status == 'Accepted') {
              if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
              } else {
                const data = {
                  message: req.counselor.fullname + " accepted your chat request.",
                  chatRequestId: req.id,
                  status: req.status,
                  createdAt: req.createdAt,
                  updatedAt: req.updatedAt,
                  chatRoomName: req.chatRoomName,
                  caseTopic: req.caseTopic,
                  counselor: {
                    name: req.counselor.fullname,
                    userType: 'Counselor',
                    profilePic: this.appService.profilePicBaseUrl + req.counselor.profile_pic,
                    caseTopic: req.caseTopic,
                    userId: req.counselor.id,
                    chatRequestId: req.id
                  },
                  user: {
                    name: req.user.fullname,
                    userType: 'Counselor',
                    profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
                    caseTopic: req.caseTopic,
                    userId: req.user.id,
                    chatRequestId: req.id
                  }
                }
                this.requestedUsers.push(data);
              }
            } else if (req.status == 'Start') {
              if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
              } else {
                const data = {
                  message: req.counselor.fullname + " accepted your chat request.",
                  chatRequestId: req.id,
                  status: req.status,
                  createdAt: req.createdAt,
                  updatedAt: req.updatedAt,
                  chatRoomName: req.chatRoomName,
                  caseTopic: req.caseTopic,
                  counselor: {
                    name: req.counselor.fullname,
                    userType: 'Counselor',
                    profilePic: this.appService.profilePicBaseUrl + req.counselor.profile_pic,
                    caseTopic: req.caseTopic,
                    userId: req.counselor.id,
                    chatRequestId: req.id
                  }
                }
                this.requestedUsers.push(data);
              }
            }

          });
          this.dataLoaded =true;
        }else{
          this.dataLoaded =false;
        }
        this.spinner.hide('chat');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('chat');
        this.dataLoaded =false;
      }
    );
  }

  sendChatRequest() {
    var msg;
    this.dataLoaded =false;
    this.spinner.show('chat');
    this.apiService.creditTransaction(this.appService.getToken(), 1, 19.99, 'chat').subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.spendOneCredit();
          // const data = { cmd: 'send-chat-request', data: {} }
          // this.socket.send(JSON.stringify(data));
          this.apiService.sendChatRequest(this.appService.getToken(), this.selectedCategory, 'Sent', this.appService.getChatRoomName()).subscribe(
            (res: any) => {
              console.log(res);
              if (res.status) {
                this.appService.showSuccessToast(res.message);
              }
              // this.isRequestSent = true;
              this.apiService.getChatRequestsByUserId(localStorage.getItem('token')).subscribe(
                (result: any) => {
                  console.log(result);
                  if (result.status) {
                    let requests = [];
                    this.requestedUsers = [];
                    requests = result.data;
                    this.server_time = result.current_time;
                    requests.forEach(req => {

                      if (req.status == 'Sent') {
                        if (this.appService.isChatTimeAway(req.createdAt,this.server_time)) {
                        } else {
                          if(this.appService.getUserType() == 'User'){
                             msg = "Chat request sent to Employee Advocates";
                          }else{
                             msg = req.user.fullname + " has requested a chat.";
                          }

                          const data = {
                            message: msg,
                            chatRequestId: req.id,
                            status: req.status,
                            createdAt: req.createdAt,
                            updatedAt: req.updatedAt,
                            chatRoomName: req.chatRoomName,
                            caseTopic: req.caseTopic,
                            user: {
                              name: req.user.fullname,
                              userType: 'Counselor',
                              profilePic: this.appService.profilePicBaseUrl + req.user.profile_pic,
                              caseTopic: req.caseTopic,
                              userId: req.user.id,
                              chatRequestId: req.id
                            }
                          }
                          this.requestedUsers.push(data);
                        }
                      } else if (req.status == 'Accepted') {
                        if (this.appService.isTimeAway(req.createdAt)) {
                        } else {
                          const data = {
                            message: req.counselor.fullname + " accepted your chat request.",
                            chatRequestId: req.id,
                            status: req.status,
                            createdAt: req.createdAt,
                            updatedAt: req.updatedAt,
                            chatRoomName: req.chatRoomName,
                            caseTopic: req.caseTopic,
                            counselor: {
                              name: req.counselor.fullname,
                              userType: 'Counselor',
                              profilePic: this.appService.profilePicBaseUrl + req.counselor.profile_pic,
                              caseTopic: req.caseTopic,
                              userId: req.counselor.id,
                              chatRequestId: req.id
                            }
                          }
                          this.requestedUsers.push(data);
                        }
                      }

                    });
                    this.dataLoaded =true;
                  }else{
                    this.dataLoaded = false;
                  }
                  this.onConfirmationCallClose();
                  this.spinner.hide('chat');
                },
                (err) => {
                  console.log(err.error);
                  this.spinner.hide('chat');
                  this.dataLoaded =false;
                }
              );
            },
            (err: any) => {
              console.log(err);
              this.onConfirmationCallClose();

              this.spinner.hide('chat');
              this.dataLoaded =false;
            }
          );
        }
      },
      (err: any) => {
        this.spinner.hide('chat');
        console.log(err);
      }
    );
    // this.socket.onmessage = (res: any) => {
    //   console.log(res);
    //   res = JSON.parse(res.data);
    //   if (res.cmd === 'counselor-joined-chat') {
    //     this.chatReqId = res.data.chatRequestId;
    //     this.recpId = res.data.counselor.id;
    //     this.requests.push(res.data);
    //   } if (res.cmd === 'end-chat') {
    //     this.appService.showSuccessToast(res.data.message);
    //   } else
    //     if (res.status) {
    //       this.appService.showSuccessToast(res.data.message);
    //     } else {
    //       console.log('res.data');
    //       console.log(res.data);
    //       if (res.cmd === 'end-chat') {
    //         this.requests = [];
    //       }
    //     }
    // };
  }

  showDoc(file: any) {
    console.log(file);
    const url = environment.chatFileBaseUrl + file;
    window.open(url, '_blank');
  }

  acceptChatRequest(req: any) {
    console.log('aaaaaaaaaareq');
    console.log(req);
    // this.requestedUser = req.user;
    // this.chatRequestId = req.user.chatRequestId;
    // const data = { "cmd": "accept-chat-request", "data": { "chatRequestId": reqUser.user.chatRequestId } };
    // socket.send(JSON.stringify(data));
    if (this.appService.getUserType() === 'Admin' || this.appService.getUserType() === 'Counselor') {
    this.spinner.show('chat');
    this.apiService.acceptChatRequest(this.appService.getToken(), req.chatRequestId, req.user.userId).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status && res.userStarted) {
          console.log('00222222222222222')
          this.router.navigate(['/chat'],
            {
              queryParams: {
                user_id: req.user.userId,
                user_img: req.user.profilePic,
                user_name: req.user.name,
                date_time: req.updatedAt,
                user_topic: req.user.caseTopic,
                islive: true,
                chat_req_id: req.chatRequestId,
                recp_id: (this.appService.getUserType() == 'User') ? req.counselor.userId : req.user.userId,
                chatRoomName: req.chatRoomName
              }
            }
          );
        } else if (res.status) {
          var obj =this;
          setTimeout(() => {

            // $("body").addClass("modal-open");
             $("#pending-tab").removeClass("active");
             $("#active-tab").addClass("active");
            // $("#active-tab").css("display", "block");
            obj.isPending= false;
            obj.isHistoryTab = false;
            obj.isActiveTab = true;
            obj.dataLoaded =true;
            obj.getChatReq();
            setTimeout(() => {
              // obj.class='margin-setts';
              // obj.myScrollContainer.nativeElement.scrollTop = 500;
              
            }, 1500);
          }, 2000);
      
        //  $(".active-tab").trigger('click');
          this.appService.showSuccessToast(res.message);

        }
        this.spinner.hide('chat');
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide('chat');
      }
    );
    }else{
      if(req.status == 'Accepted' || req.status == 'Start'){
        this.apiService.startChatByUser(this.appService.getToken(), req.chatRequestId, req.counselor.userId).subscribe(
          (res: any) => {
          
    if(res.status){      
                  this.router.navigate(['/chat'],
                  {
                    queryParams: {
                      user_id: req.counselor.userId,
                      user_img: req.counselor.profilePic,
                      user_name: req.counselor.name,
                      date_time: res.chat_request.updatedAt,
                      user_topic: req.caseTopic,
                      islive: true,
                      chat_req_id: req.chatRequestId,
                      recp_id: (this.appService.getUserType() == 'User') ? req.counselor.userId : req.user.userId,
                      chatRoomName: req.chatRoomName
                    }
                  }
                );
          }else{

          }
  })
      }
    }
  }

  onchatSegment(ev: any) {
    console.log(this.chatSegment);
    if (ev.target.value == 'Active') {
      $("#user_case").show();
      this.show_history = 'false';
      this.chatSegment = 'Active';
    } else {
      $("#user_case").hide();
      this.chatSegment = 'History';
      this.show_history = 'true';
      this.getChatUsers();
    }
  }

  // async getSuccess(msg) {
  //   this.success_message = msg;
  //   var obj = this;
  //   // setTimeout(function () { obj.success_message = ''; }, 6000);
  // }
  // async getErr(msg) {
  //   this.error_message = msg;
  //   var obj = this;
  //   //  setTimeout(function () { obj.error_message = ''; }, 6000);
  // }

  go_for_conversation_history(history: any, user: any, topic: any, chatRoomName: any) {
    console.log('00000000000000000000000')
    this.router.navigate(['/chat'], {
      queryParams: {
        user_id: user.id,
        user_img: user.profile_pic,
        user_name: user.fullname,
        chat_req_id: history.id,
        recp_id: (this.appService.getUserType() == 'User') ? history.counselor.id : history.user.id,
        user_topic: topic,
        isHistory: true,
        chatRoomName: chatRoomName
      }
    });
  }

  go_for_conversation_history_live(req: any) {

    console.log(req);
    // const data = { "cmd": "user-joined-chat", "data": { "counselor": reqUser.counselor } };
    // socket.send(JSON.stringify(data));


    this.spinner.show('chat');
    if(req.status != 'Start'){
      this.apiService.startChatByUser(this.appService.getToken(), req.chatRequestId, req.counselor.userId).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            console.log('000000000000000000000001111111111')
            this.router.navigate(['/chat'],
              {
                queryParams: {
                  user_id: req.counselor.userId,
                  user_img: req.counselor.profilePic,
                  user_name: req.counselor.name,
                  user_topic: req.counselor.caseTopic,
                  date_time: res.chat_request.updatedAt,
                  islive: true,
                  chat_req_id: req.chatRequestId,
                  recp_id: req.counselor.userId,
                  chatRoomName: req.chatRoomName
  
                }
              }
            );
          }
          this.spinner.hide('chat');
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide('chat');
        }
      );
    }else{
      this.spinner.hide('chat');
      this.router.navigate(['/chat'],
      {
        queryParams: {
          user_id: req.counselor.userId,
          user_img: req.counselor.profilePic,
          user_name: req.counselor.name,
          user_topic: req.counselor.caseTopic,
          date_time: req.updatedAt,
          islive: true,
          chat_req_id: req.chatRequestId,
          recp_id: req.counselor.userId,
          chatRoomName: req.chatRoomName

        }
      }
    );
    }
   
  
  }
  go_pending(req: any) {

    console.log(req);
    // const data = { "cmd": "user-joined-chat", "data": { "counselor": reqUser.counselor } };
    // socket.send(JSON.stringify(data));


    this.spinner.show('chat');
    this.apiService.startChatByUser(this.appService.getToken(), req.chatRequestId, req.counselor.userId).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          console.log('000000000000000000000001111111111')
          // this.router.navigate(['/chat'],
          //   {
          //     queryParams: {
          //       user_id: req.counselor.userId,
          //       user_img: req.counselor.profilePic,
          //       user_name: req.counselor.name,
          //       user_topic: req.counselor.caseTopic,
          //       date_time: req.updatedAt,
          //       islive: true,
          //       chat_req_id: req.chatRequestId,
          //       recp_id: req.counselor.userId,
          //       chatRoomName: req.chatRoomName

          //     }
          //   }
          // );
        }
        this.spinner.hide('chat');
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide('chat');
      }
    );
    // console.log('asdfer');
    // if (this.appService.getUserType() == 'User') {
    //   const data = { "cmd": "user-joined-chat", "data": { "counselor": counselor } };
    //   this.socket.send(JSON.stringify(data));

    // }

    // this.router.navigate(['/chat'], {
    //   queryParams: {
    //     user_id: id,
    //     user_img: img,
    //     user_name: name,
    //     user_topic: topic,
    //     islive: true,
    //     chat_req_id: this.chatReqId,
    //     recp_id: this.recpId
    //   }
    // });
  }
  getHistoryConversation(id, img) {
    this.id_ForConversation = id;
    const data = { cmd: 'chat-history', "data": { "page": 1, chatConversationId: id } };
    this.socket.send(JSON.stringify(data));
    this.socket.onmessage = (res: any) => {
      res = JSON.parse(res.data);
      console.log(res);
      // this.ScrollToTop();
      if (res.cmd === 'message') {
        this.conversation.push(res.data);
        console.log(this.conversation);
      } else if (res.cmd === 'chat-history') {
        this.conversation = [];
        this.conversation.unshift(...res.data.messages);
        this.fileBaseUrl = res.data.fileBaseUrl;
        this.thumbBaseUrl = res.data.thumbBaseUrl;
        //history mn latest msg uper aa rha tha is liye array ko reverse kiya ha
        // this.conversation = this.conversation.reverse();
        for (let index = 0; index < this.conversation.length; index++) {
          const element = this.conversation[index];
          if (element.recipientId === id) {
            element.class = 'right';
            console.log(element);
          }
        }
        console.log('this.conversation');
        console.log(this.conversation);
      } else if (res.cmd === 'end-chat') {
        this.showFirst = 'false'; $("#user_case").show();
        this.show_history = 'false';
        this.go_conversation = 'false';
        this.requests = [];
        this.chatUsers = [];
        this.appService.showSuccessToast(res.data.message);
      }
      //  if(img){
      //   this.sender_profile_pic = 'http://ec2-18-222-251-217.us-east-2.compute.amazonaws.com:8081/profile/images/'+img;
      //  }else{
      //   this.sender_profile_pic = '';
      //  }

    };
  }

  onEndChatBtnClick() {
    const data = { "cmd": "end-chat", "data": { "chatRequestId": this.chatReqId, "recipient": this.recpId } };
    this.socket.send(JSON.stringify(data));
    this.showFirst = 'false';
    $("#user_case").show();
    this.show_history = 'false';
    this.go_conversation = 'false';
    this.requests = [];
    this.chatUsers = [];
  }


  add_first_message() {

    this.conversation.push({ message: this.message, class: 'right' });
    console.log(this.conversation);
    // this.ScrollToTop();
    const data = { "cmd": "message", "data": { "messageType": "Text", "message": this.message, "recipient": this.id_ForConversation } }
    this.socket.send(JSON.stringify(data));
    this.socket.onmessage = (res: any) => {
      res = JSON.parse(res.data);
      // this.ScrollToTop();
      console.log(res);
      if (res.status) {
        // this.getSuccess(res.data.message);
      } else {
        this.sender_profile_pic = res.data.sender.profilePic;
        this.name = res.data.sender.name;
        this.conversation.push(res.data);
        console.log(this.conversation);

        //  this.getSuccess(res.data.message);
        //this.getErrr(res.data.message+'fffffffffff');
      }
    };
    this.message = '';
  }

  getMessages(page = 1, event?: any) {
    if (event) {
      this.messageListingPage += 1;
      const data = { cmd: 'chat-history', "data": { "page": this.messageListingPage, chatConversationId: this.id_ForConversation } };
      this.socket.send(JSON.stringify(data));
      this.socket.onmessage = (res: any) => {
        res = JSON.parse(res.data);
        for (let index = 0; index < res.data.messages.length; index++) {
          const element = res.data.messages[index];
          if (element.recipientId === this.id_ForConversation) {
            element.class = 'right';
            console.log(element);
          }
        }
        this.conversation.unshift(...res.data.messages);
        console.log(this.conversation);
      };
      event.target.complete();
    }
  }
}
