import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notifications: any[];
  notificationCount: any;
  notificationListingPage = 1;
  showMore: boolean = true;
  constructor(private route: ActivatedRoute, public appService: AppService, private router: Router,
    private apiService: ApiService, private spinner: NgxSpinnerService) {
    this.getNotifications();
  }

  ngOnInit() {
  }

  getNotifications(page = 1, event?: any) {
    this.spinner.show('notification');
    if (event) {
      this.notificationListingPage += 1;
      this.apiService.getNotificationList(this.appService.getToken(), this.notificationListingPage.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            if (result.notifications.length === 0) {
              this.showMore = false;
            }
            this.notifications.push(...result.notifications);
            this.notificationCount = result.count;
            // event.target.complete();
            this.spinner.hide('notification');
          }
          console.log(result);
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('notification');
        }
      );
    } else {
      this.apiService.getNotificationList(this.appService.getToken(), page.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            if (result.notifications.length === 0) {
              this.showMore = false;
            }
            this.notifications = result.notifications;
            this.notificationCount = result.count;
          }
          console.log(result);
          this.spinner.hide('notification');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('notification');
        }
      );

    }
  }

  onNotificationClick(not: any) {
    // this.componentService.getAlert(not.title, not.notification).then(
    //   (alert) => {
    //     alert.present();
    //   }
    // );
  }
}
