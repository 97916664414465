import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import * as firebase from 'firebase';
import * as $ from "jquery";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  videoUrl: any;
  url: any = 'assets/images/empty-image.png';
  previewType: any = '';
  notificationCount: any;
  advisorName: any;
  sender_profile_pic: any;
  conversation = [];
  message: any = '';
  messageListingPage = 1;
  thumbBaseUrl: any;
  fileBaseUrl = environment.chatFileBaseUrl;
  socket: any;
  typePlaceholder: any = 'Type your message here';
  isSendMsg: boolean = false;
  isRequest: boolean = false;

  timeInterval: any;
  timer: any = {
    min: 14,
    sec: 59
  };

  chatRoomName: any;
  reffirebase: any;
  messages: any[] = [];
  rate: any = 0;

  // recipient data
  userId: any = '';
  userTopic: any = '';
  userImage: any = '';
  userName: any = '';
  file: any;
  fileValue: any = '';
  formData: any;
  reqId: any;
  dateTime: any;

  msg_error: any;
  constructor(private router: Router, public route: ActivatedRoute, private spinner: NgxSpinnerService,
    private apiService: ApiService, public appService: AppService) {
    this.userId = this.route.snapshot.queryParams.user_id;
    console.log(this.userId);
    this.userImage = this.route.snapshot.queryParams.user_img;
    this.dateTime = this.route.snapshot.queryParams.date_time;
    this.userName = this.route.snapshot.queryParams.user_name;
    this.userTopic = this.route.snapshot.queryParams.user_topic;
    this.chatRoomName = this.route.snapshot.queryParams.chatRoomName;
    this.reqId = this.route.snapshot.queryParams.chat_req_id;
    console.log(this.userId);
    this.setTimer();
    this.isRequest = this.route.snapshot.queryParams.islive;
    if (this.route.snapshot.queryParams.islive) {
      this.userImage = this.userImage;
    } else {
      this.userImage = environment.profilePicBaseUrl + this.userImage;
    }
    this.SignInFirebase()
    this.reffirebase = firebase.database().ref('ChatRoom');
    console.log(this.reffirebase);
    // this.openConnection();
    this.get_messages();
  }
  SignInFirebase(){
    console.log('Logging in Firebase User');

    firebase.auth().signInWithEmailAndPassword("pukarpukar@gmail.com", "123456789")
        .then(function (callback)
        {
          console.log('callback');
          console.log(callback);
        })
        .catch(function(login_error)
        {
            let loginErrorCode = login_error.code;
            let loginErrorMessage = login_error.message;

            console.log(loginErrorCode);
            console.log(loginErrorMessage);

            if (loginErrorCode === 'auth/user-not-found')
            {
             
            }
        });
  }
  setTimer() {
    console.log(this.dateTime);
    console.log(this.appService.getMinutes(this.dateTime));
    this.timer.min = (+this.appService.getMinutes(this.dateTime) >= 0 ? this.appService.getMinutes(this.dateTime) : 0);
    this.timer.sec = 59;
    this.startTimer();
  }

  get_messages() {
    this.reffirebase.child(this.chatRoomName).on('value', resp => {
      this.messages = [];
      this.messages = snapshotToArray(resp);
      console.log('this.messages');
      console.log(this.messages);
      // setTimeout(() => {
      //   this.messages = snapshotToArray(resp);
      // }, 3000);
    });
  }


  onEndChatBtnClick() {

    // clearInterval(this.timeInterval);

    this.spinner.show('chat');
    this.apiService.endChat(this.appService.getToken(), this.reqId, this.userId, this.rate).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide('chat');
        if (res.status) {
          this.appService.showSuccessToast('You are successfully rated your chat.');
          this.router.navigate(['/chat-listing']);
        }
      },
      (err: any) => {
        this.spinner.hide('chat');
        console.log(err);
      }
    );
  }
  onEndChatBtnClickAdmin() {

    // clearInterval(this.timeInterval);

    this.spinner.show('chat');
    this.apiService.endChat(this.appService.getToken(), this.reqId, this.userId, this.rate).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide('chat');
        if (res.status) {
          this.router.navigate(['/chat-listing']);
        }
      },
      (err: any) => {
        this.spinner.hide('chat');
        console.log(err);
      }
    );
  }
  onRateChange(ev: any) {
    console.log(ev);
    this.rate = ev.newValue;
  }

  openRatePopUp() {
    $("#rate").addClass("show");
    $("#rate").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onClose(r: any) {
    this.rate = r;
    $("#rate").removeClass("show");
    $("#rate").css("display", "none");
    $(".modal-backdrop").remove();
    this.onEndChatBtnClick();
  }

  onClipIconClick(event: any) {
    this.url = 'assets/images/empty-image.png';
    console.log(event);

    console.log(this.fileValue);
    let type: string = event.target.files[0].type.toString();
    console.log(type);
    if (type.search('image') == 0) {
      this.previewType = 'image';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (ev: any) => { // called once readAsDataURL is completed
          this.url = ev.target.result;
        }
      }
    } else if (type.search('video') == 0) {
      this.previewType = 'video';
      this.url = 'assets/images/video-preview.png';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onloadend = (ev: any) => { // called once readAsDataURL is completed
          this.videoUrl = ev.target.result;
        }
      }
    } else {
      this.url = 'assets/images/doc-preview.png';
    }

    console.log(event);
    console.log('ajskdlg');
    console.log(event.target.files[0]);
    this.file = event.target.files[0];
    // this.uploadFiles(ev.target.files[0]);

    // this.appService.setDoc(ev.target.files[0]);
    this.formData = new FormData();
    // console.log(this.recpId);
    this.formData.append('recipient', this.userId);
    this.formData.append('userType', this.appService.getUserType());
    this.formData.append('media-file', this.file, this.file.name);
  }

  onPreviewClick() {
    // window.open(this.url, "_blank");
    // if (this.url != 'assets/images/doc-preview.png') {
    //   if (this.previewType == 'image') {
    //     // this.showPreview(this.url);
    //     window.open(this.url, '_blank');
    //   } else {
    //     // this.showPreview(undefined, this.videoUrl);
    //     // window.open(this.videoUrl, '_system');
    //     // this.previewAnyFile.preview(this.videoUrl)
    //     //   .then((res: any) => console.log(res))
    //     //   .catch((error: any) => console.error(error));
    //   }
    // }
  }

  onRemovePreviewClick() {
    this.file = undefined;
    console.log(this.fileValue);
    this.fileValue = '';
    // var input = document.getElementsByName('file-input');
    // console.log(input);
    // this.fileValue = null
  }

  clearData() {
    console.log('clear');
    console.log(this.fileValue);
    this.fileValue = null;
  }

  keyPressFunction(event) {
    if (event.keyCode === 13 && event.shiftKey) {
      this.message = this.message + "\n";
      return false;
    } else if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  uploadAttachment() {
    this.spinner.show('chat');
    this.formData.append('msg', this.message);
    this.apiService.uploadChatAttachment(this.appService.getToken(), this.formData).subscribe(
      (result: any) => {
        if (result.status) {
          const msg = result.message;
          const data = { "cmd": "message", "data": msg };
          this.socket.send(JSON.stringify(data));
          this.file = undefined;
          this.fileValue = '';
          this.message = '';
          // this.socket.onmessage = (res: any) => {
          //   var new_res = JSON.parse(res.data);
          //   console.log('res.data  -------------------');
          //   console.log(new_res);
          // }
        }
        // this.conversation.push({ message:result.message.media.filename,messageType: result.message.messageType, class: 'right' });
        // result.message.class = 'right';
        this.conversation.push(result.message);
        console.log(result);
        console.log(this.conversation);
        // loader.dismiss();
        this.spinner.hide('chat');
      },
      (err) => {
        console.log(err.error);
        // loader.dismiss();
        this.spinner.hide('chat');
      }
    );
  }

  ngOnInit() {
  }

  openConnection() {
    this.socket = new WebSocket(environment.chatBaseUrl + localStorage.getItem('token'));
    this.socket.onmessage = (res: any) => {
      console.log(res);
      console.log('opened');
      if (this.appService.getUserType() == 'User') {
        if (this.isRequest) {
          this.startTimer();
        }
      }
      this.getConversation();
    };
  }

  sendMessage() {
    this.msg_error = '';
    if ((this.message === '' || this.message === undefined) && !this.file) {
      this.msg_error = 'write something to send';
      return false;
    }
    if (this.file) {
      // console.log('file exist');
      // this.uploadAttachment();

      // const loading = await this.loadingCtrl.create({
      // });
      // await loading.present();
      this.spinner.show('chat');
      const fileName = this.file.name;
      let type: string = this.file.type.toString();
      console.log(type);
      var fileRef;
      if (type.search('image') == 0) {
        type = 'Image';
        fileRef = firebase.storage().ref('images/' + fileName);
      } else if (type.search('video') == 0) {
        type = 'Video';
        fileRef = firebase.storage().ref('videos/' + fileName);
      } else {
        type = 'Doc';
        fileRef = firebase.storage().ref('documents/' + fileName);
      }



      const uploadTask = fileRef.put(this.file);
      const obj = this;
      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
        }, error => {
          reject(error);
        }, () => {
          // tslint:disable-next-line: only-arrow-functions
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('teste resolve url ' + downloadURL);
            const newData = obj.reffirebase.child(obj.chatRoomName).push();

            newData.set({
              message: obj.message,
              sender_id: +obj.appService.getProfile().id,
              recipientId: +obj.userId,
              media: downloadURL,
              createdAt: new Date().toISOString(),
              category: obj.userTopic,
              messageType: type
            });
            obj.message = '';
            obj.spinner.hide('chat');
            obj.sendNotificationToUser(+obj.userId);

            obj.file = undefined;
            resolve({ fileName, downloadURL });
          });
        });
      });
    } else {
      const newData = this.reffirebase.child(this.chatRoomName).push();

      newData.set({
        message: this.message,
        sender_id: +this.appService.getProfile().id,
        recipientId: +this.userId,
        media: '',
        createdAt: new Date().toISOString(),
        category: this.userTopic,
        messageType: 'Text'
      });
      this.message = '';
      this.sendNotificationToUser(+this.userId);




      //   console.log(this.conversation);
      //   console.log(this.message);
      //   // this.ScrollToTop();
      //   const data = { "cmd": "message", "data": { "messageType": "Text", "message": ((this.message === '' || this.message === undefined) ? '' : this.message), "recipient": this.userId, "senderId": this.appService.getProfile().id, "recipientId": this.userId } }
      //   this.socket.send(JSON.stringify(data));
      //   this.conversation.push(data.data);
      //   this.message = '';
      //   this.socket.onmessage = (res: any) => {
      //     res = JSON.parse(res.data);
      //     // this.ScrollToTop();
      //     console.log(res);
      //     if (res.cmd === 'end-chat') {
      //       this.router.navigate(['/chat-listing']);
      //       // this.getSuccess(res.data.message);
      //     } else {
      //       console.log(res.data.sender.name);
      //       this.sender_profile_pic = res.data.sender.profilePic;
      //       console.log('send message');
      //       // this.conversation.push({ message: res.data.message, class: 'left' });
      //       console.log(this.conversation);
      //       // this.name = res.data.sender.name;
      //       // this.conversation.push(res.data);
      //       // console.log(this.conversation);

      //       //  this.getSuccess(res.data.message);
      //       //this.getErrr(res.data.message+'fffffffffff');
      //     }
      //     if (res.cmd === 'message') {
      //       // if (res.data.media) {
      //       //   this.conversation.push(res.data);
      //       // } else {
      //       //   this.conversation.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: this.userId });
      //       //   // this.conversationContent.scrollToBottom();
      //       // }
      //       if (res.data.media) {
      //         this.conversation.push(res.data);
      //         console.log(this.conversation);
      //       } else {
      //         this.conversation.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: this.userId, recipient: this.userId });
      //         // this.conversationContent.scrollToBottom();
      //       }
      //       console.log(this.conversation);
      //     }
      //   };
      //   this.message = '';
    }
  }

  sendNotificationToUser(id: any) {
    this.apiService.sendChatMsgNotificationToUser(this.appService.getToken(), this.reqId, this.userId).subscribe(
      (res: any) => {
        console.log(res);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getMessages(page = 1) {
    this.spinner.show('chat');
    this.messageListingPage += 1;
    const data = { cmd: 'chat-history', "data": { "page": this.messageListingPage, chatConversationId: this.userId } };
    this.socket.send(JSON.stringify(data));
    this.socket.onmessage = (res: any) => {
      res = JSON.parse(res.data);
      for (let index = 0; index < res.data.messages.length; index++) {
        const element = res.data.messages[index];
        if (element.recipientId === this.route.snapshot.queryParams.recp_id) {
          element.class = 'right';
          console.log(element);
        }
      }
      let arr = [];
      arr.push(...res.data.messages);
      console.log(arr);
      arr.reverse();
      this.conversation.unshift(...arr);

      this.spinner.hide('chat');
      console.log(this.conversation);
    };
  }

  getConversation(page = 1, event?: any) {
    let data: any;
    if (this.route.snapshot.queryParams.islive) {
      data = { cmd: 'chat-history', "data": { "page": 1 } };
    } else {
      data = { cmd: 'chat-history', "data": { "page": 1, chatConversationId: this.userId } };
    }
    this.socket.send(JSON.stringify(data));
    this.socket.onmessage = (res: any) => {
      res = JSON.parse(res.data);
      console.log(res);
      // this.ScrollToTop();
      if (res.cmd === 'message') {
        // if (res.data.media) {
        //   this.conversation.push(res.data);
        // } else {
        //   this.conversation.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: this.userId });
        //   // this.conversationContent.scrollToBottom();
        // }
        if (res.data.media) {
          this.conversation.push(res.data);
          console.log(this.conversation);
        } else {
          this.conversation.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: this.userId, recipient: this.userId });
          // this.conversationContent.scrollToBottom();
        }
        console.log(this.conversation);
      } else if (res.cmd === 'chat-history') {
        console.log('chat history');
        this.conversation = [];
        this.conversation.push(...res.data.messages);
        this.conversation.reverse();
        console.log(this.conversation);
        this.fileBaseUrl = res.data.fileBaseUrl;
        this.thumbBaseUrl = res.data.thumbBaseUrl;
        //history mn latest msg uper aa rha tha is liye array ko reverse kiya ha
        // this.conversation = this.conversation.reverse();
        // for (let index = 0; index < this.conversation.length; index++) {
        //   const element = this.conversation[index];
        //   if (element.recipientId === this.userId) {
        //     element.class = 'right';
        //     console.log(element);
        //   }
        // }
        console.log('this.conversation');
        console.log(this.conversation);
      } else if (res.cmd === 'end-chat') {
        this.router.navigate(['/chat-listing']);
      } else if (res.cmd === 'ack-user-joined-chat') {
        this.typePlaceholder = 'Type your message here';
        this.isSendMsg = false;
        if (this.isRequest) {
          this.startTimer();
        }
      }
      //  if(img){
      //   this.sender_profile_pic = 'http://ec2-18-222-251-217.us-east-2.compute.amazonaws.com:8081/profile/images/'+img;
      //  }else{
      //   this.sender_profile_pic = '';
      //  }

    };
  }

  startTimer() {
    this.timeInterval = setInterval(() => {
      --this.timer.sec;
      if (this.timer.sec == 0) {
        if (this.timer.min == 0) {
          clearInterval(this.timeInterval);
          this.showPopUp();
        } else {
          this.timer.min = this.timer.min - 1;
          this.timer.sec = 59;
        }
      }
    }, 1000);
  }

  showPopUp() {
    this.appService.showSuccessToast('Your time is over.');
    if (this.appService.getUserType() == 'User') {
      this.openRatePopUp();
    } else {
      this.router.navigate(['/chat-listing']);
      this.apiService.endChat(this.appService.getToken(), this.reqId, this.userId, this.rate).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            // this.appService.showSuccessToast('Status successfully submitted.');
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  showPicture(img: any) {
    //this.fileBaseUrl +
    window.open( img, '_blank');
  }

  showDoc(file: any) {
    //this.fileBaseUrl +
    window.open( file, '_blank');
  }

  async setNotificationCount() {
    const res = await this.appService.getNotificationCount();
    this.notificationCount = res.count;
  }

}


export const snapshotToArray = snapshot => {
  const returnArr = [];

  snapshot.forEach(childSnapshot => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};
