import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { CaseListingComponent } from './components/case-listing/case-listing.component';
import { CasesDetailsComponent } from './components/cases-details/cases-details.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatListingComponent } from './components/chat-listing/chat-listing.component';
import { CouncillorHomeComponent } from './components/councillor-home/councillor-home.component';
import { CouncillorHomeAfterLoginComponent } from './components/councillor-home-after-login/councillor-home-after-login.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UserListingComponent } from './components/user-listing/user-listing.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { WhatsKeepingComponent } from './components/whats-keeping/whats-keeping.component';
import { SelfhelpComponent } from './components/selfhelp/selfhelp.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AddcaseComponent } from './components/addcase/addcase.component';
import { AllCasesComponent } from './components/all-cases/all-cases.component';
import { InviteComponent } from './components/invite/invite.component';
import { CreditComponent } from './components/credit/credit.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { CallsComponent } from './components/calls/calls.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { CaseChatComponent } from './components/case-chat/case-chat.component';
import { NotesComponent } from './components/notes/notes.component';
import { PaymentComponent } from './components/payment/payment.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './/components/signup/signup.component';


const routes: Routes = [
  { path: '', redirectTo: 'case-listing', pathMatch: 'full' },
  { path: 'counselor-home', component: CouncillorHomeComponent },

  { path: 'login', component: LoginComponent },
  { path: 'login-s', component: SignupComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'counselor-home-login', component: CouncillorHomeComponent },
  { path: 'counselor-home-signup', component: CouncillorHomeComponent },
  { path: 'addcase', component: AddcaseComponent, canActivate: [AuthGuardService] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'case-listing', component: CaseListingComponent, canActivate: [AuthGuardService] },
  { path: 'case-details', component: CasesDetailsComponent, canActivate: [AuthGuardService] },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuardService] },
  { path: 'case-chat', component: CaseChatComponent, canActivate: [AuthGuardService] },
  { path: 'calls', component: CallsComponent, canActivate: [AuthGuardService] },
  { path: 'chat-listing', component: ChatListingComponent, canActivate: [AuthGuardService] },
  { path: 'councillor-home-after-login', component: CouncillorHomeAfterLoginComponent, canActivate: [AuthGuardService] },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuardService] },
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuardService] },
  { path: 'user-listing', component: UserListingComponent, canActivate: [AuthGuardService] },
  { path: 'notes', component: NotesComponent, canActivate: [AuthGuardService] },

  { path: 'whats-keeping', component: WhatsKeepingComponent},
  {path: 'whats-keeping-self', component: WhatsKeepingComponent},
  // { path: 'selfhelp', component: SelfhelpComponent, canActivate: [AuthGuardService] },
  { path: 'selfhelp', component: SelfhelpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'all-cases', component: AllCasesComponent, canActivate: [AuthGuardService] },
  { path: 'invite', component: InviteComponent, canActivate: [AuthGuardService] },
  { path: 'credit', component: CreditComponent, canActivate: [AuthGuardService] },
  { path: 'transfer', component: TransferComponent, canActivate: [AuthGuardService] },
  { path: 'video-call', component: VideoCallComponent, canActivate: [AuthGuardService] },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
