import { AppService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
@Component({
  selector: 'app-whats-keeping',
  templateUrl: './whats-keeping.component.html',
  styleUrls: ['./whats-keeping.component.css']
})
export class WhatsKeepingComponent implements OnInit {

  selfHelpTopics: any[];
  // error_message: any; success_message: any;
  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, private router: Router,
    private appService: AppService, private route: ActivatedRoute) {
    if (this.route.snapshot.queryParams.noquestion) {
      this.appService.showErrorToast('Not Help Available!');
    } else if (this.route.snapshot.queryParams.solutionPosted) {
      this.appService.showSuccessToast('Your response has been posted!');
    }
    // if (this.appService.getUserType() === 'Anonymous') {
    //   console.log('wokring');
    //   this.menuCtrl.enable(false);
    // } else {
    //   this.menuCtrl.enable(true);
    // }
    if(this.router.url.includes('/whats-keeping-self')){
      this.onSelfHelpItemClick()
    }else{
      this.getTopics();
    }

  }

  onTopicClick(topic: any) {
    this.router.navigate(['/selfhelp'], {
      queryParams: {
        self_help_topic_id: topic.id,
        self_help_topic_name: topic.name,
      }
    });
  }
  onSelfHelpItemClick() {
    this.spinner.show('councillor-home');
    this.apiService.registerAnonymousUser('browser', 'browser', 'browser').subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.setToken(res.jwtoken);
          this.appService.setAnonymousUser(res.user);
          this.spinner.hide('councillor-home');
          this.router.navigate(['/whats-keeping']);
        }
      },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide('councillor-home');
      }
    );
  }
  getTopics() {
    this.spinner.show('whats-keeping');
    this.apiService.getSelfHelpTopics(this.appService.getToken()).subscribe(
      (result: any) => {
        if (result.status) {
          this.selfHelpTopics = result.help_topics;
        }
        this.spinner.hide('whats-keeping');
      },
      (err: any) => {
        console.log(err.error);
        this.spinner.hide('whats-keeping');
      }
    );
  }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 });
  }

  // async getSuccess(msg) {
  //   this.success_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.success_message = ''; }, 6000);
  // }
  // async getErrr(msg) {
  //   this.error_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.error_message = ''; }, 6000);
  // }

}
