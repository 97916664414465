import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import $ from "jquery";

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {

  transferHistory: any[] = [];
  users: any[] = [];
  isHistory: boolean = true;
  dataLoaded: boolean = false;
  user: any;
  search: any = '';
  search_error: any = '';
  credit: number;
  credit_error: any = '';
  userListingPage = 1;
  showMore: boolean = false;

  constructor(private apiService: ApiService, public appService: AppService, private spinner: NgxSpinnerService) {

  }

  onCancelBtnClick() {
    $("#credit-popup").removeClass("show");
    $("#credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onMakeTransferClick() {
    this.dataLoaded = false;
    this.isHistory = !this.isHistory;
    this.getTransferHistory();
  }
  onTransferBtnClick(user: any) {
    this.user = user;
    $("#credit-popup").addClass("show");
    $("#credit-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onTransferNowBtnClick() {
    this.credit_error = '';
    if (this.credit > 0 && this.credit != null) {
      this.spinner.show('transfer');
      this.apiService.transferCredit(this.appService.getToken(), this.credit, this.appService.getProfile().id, this.appService.getUserType(), this.user.id, this.user.userType).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.appService.showSuccessToast(res.message);
            var credit = +this.appService.getCredit();
            console.log(credit);
            var c = credit - this.credit;
            console.log(c);
            localStorage.setItem('credit', c.toString());
          } else {
            this.appService.showErrorToast('Your credit is not enough to transfer.');
          }
          this.dataLoaded = true;
          this.spinner.hide('transfer');
          this.onCancelBtnClick();
        },
        (err: any) => {
          console.log(err);
          this.dataLoaded = true;
          this.spinner.hide('transfer');
          this.onCancelBtnClick();
        }
      );
    } else {
      this.credit_error = 'Invalid credit';
    }

  }

  onSearchBtnClick() {
    if (this.search != '' && this.appService.getUserType() == 'User') {
      this.getUsers();
    } else if (this.appService.getUserType() == 'Admin') {
      this.getUsers();
    }
  }
  onmakeTrBtnClick() {
    this.isHistory = !this.isHistory;
    if (this.appService.getUserType() == 'User') {
      this.getUsers();
    } else if (this.appService.getUserType() == 'Admin') {
      this.getUsers();
    }
  }
  ngOnInit() {
    this.getTransferHistory();
    if (this.appService.getUserType() == 'Admin') {
      //this.getUsers();
    }
  }

  getTransferHistory() {
    this.dataLoaded =  false;
    this.transferHistory =[];
    this.spinner.show('transfer');

    this.apiService.getCreditTransferHistoryByUser(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res && res.status) {
          this.transferHistory = res.data;
          this.transferHistory.reverse();
           //this.dataLoaded = true;
        }else{
    
          this.dataLoaded = true;
        }

        this.spinner.hide('transfer');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('transfer');
      }
    );
  }

  getUsers(page = 1, event?: any) {
    this.dataLoaded = false;
    if (event) {
      this.spinner.show('transfer');
      this.apiService.getUsersBySearch(this.appService.getToken(), this.search, ++this.userListingPage).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            if (res.users.length < 10) {
              this.showMore = false;
            } else {
              this.showMore = true;
            }
            this.users.push(...res.users);
          }else{
            
          }
          this.dataLoaded = true;
          this.spinner.hide('transfer');
        },
        (err: any) => {
          console.log(err);
          this.dataLoaded = true;
          this.spinner.hide('transfer');
        }
      );
    } else {
      this.spinner.show('transfer');
      this.apiService.getUsersBySearch(this.appService.getToken(), this.search, 1).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            if (res.users.length < 10) {
              this.showMore = false;
            } else {
              this.showMore = true;
            }
            this.users = res.users;
          }
          this.dataLoaded = true;
          this.spinner.hide('transfer');
        },
        (err: any) => {
          console.log(err);
          this.dataLoaded = true;
          this.spinner.hide('transfer');
        }
      );
    }
  }
}
