import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";
import { TimeInterface } from 'src/app/app.interface';
import * as firebase from 'firebase';

@Component({
  selector: 'app-cases-details',
  templateUrl: './cases-details.component.html',
  styleUrls: ['./cases-details.component.css']
})
export class CasesDetailsComponent implements OnInit {
  // Message Fields
  messageSegment: any;
  inboxCaseMessages = [];
  inboxCaseMessagesCount: any;
  sentCaseMessages = [];
  sentCaseMessagesCount: any;
  inboxListingPage = 1;
  sentListingPage = 1;
  socket: any;
  messagesCount: any;
  formData: any;
  file: any;
  fileValue: any = '';
  videoUrl: any;
  url: any = 'assets/images/empty-image.png';
  previewType: any = '';
  allMsgLoaded = false;

  // Case Fields
  case: any = {};
  profilePic = 'http://ec2-18-222-251-217.us-east-2.compute.amazonaws.com:3000/profile/images/noimage.png';
  userName = '';
  caseName = '';
  caseDescription = '';

  // Notification Fields
  notificationCount: any;

  isDetailTab: boolean = true;
  isMessageTab: boolean = false;
  isDocumentTab: boolean = false;

  isInboxTab: boolean = true;
  isSentTab: boolean = false;

  // Document Fields
  caseDocuments = [];
  caseDocumentsCount: any;
  documentListingPage = 1;
  // showDoc: boolean = false;
  docUrl = environment.caseDocBaseUrl;

  // Calls Fields
  isCallsTab: boolean = false;
  callsHistory: any[] = [];
  pendingCalls: any[] = [];
  isHistoryTab: boolean = false;
  isPendingTab: boolean = false;
  selectedDate: any;
  callSegment: any = 'live';
  callRequests: any[] = [];
  userEvents: any[] = [];
  userGenEvents: any[] = [];
  counselorEvents: any[] = [];
  counselorGenEvents: any[] = [];
  scheduleData: any[] = [];

  userImgPath = environment.profilePicBaseUrl;
  userImage: any;

  reqData: any;
  reqDataForUser: any;
  callData: any = {};
  isRequestAccept: boolean = true;
  isCallSchedule: boolean = false;

  startTime: any;
  endTime: any;
  times: any[] = new Array();
  timesarr: TimeInterface[] = new Array<TimeInterface>();
  occupiedSlot: any[] = [];

  selectedDay: any;
  selectedDayShort: any;
  slots: any[] = [];
  timeLoaded: boolean = false;

  caseCalls: any[] = [];
  caseCallsCount: any;
  callRequestCount: number = 0;
  callRequest = {
    counselor: '',
    img: ''
  };

  // modale fields
  message: any;
  m_message: any;
  replyId: any;

  subject: any;
  m_subject: any;

  messages = [];
  sender_profile_pic: any;
  conversation = [];
  message_chat: any = '';
  messageListingPage = 1;
  thumbBaseUrl: any = environment.chatThumbBaseUrl;
  fileBaseUrl = environment.chatFileBaseUrl;
  msg_error: any;

  chatRoomName: any;
  reffirebase: any;
  dataloaded:any;
  // error_message: any; success_message: any;
  constructor(public router: Router, public appService: AppService, private apiService: ApiService,
    public route: ActivatedRoute, private spinner: NgxSpinnerService) {
      this.SignInFirebase()
    this.reffirebase = firebase.database().ref('ChatRoom');
    console.log(this.reffirebase);
    this.getCase();

  }
  SignInFirebase(){
    console.log('Logging in Firebase User');

    firebase.auth().signInWithEmailAndPassword("pukarpukar@gmail.com", "123456789")
        .then(function (callback)
        {
          console.log('callback');
          console.log(callback);
        })
        .catch(function(login_error)
        {
            let loginErrorCode = login_error.code;
            let loginErrorMessage = login_error.message;

            console.log(loginErrorCode);
            console.log(loginErrorMessage);

            if (loginErrorCode === 'auth/user-not-found')
            {
             
            }
        });
  }
  get_messages() {
    this.dataloaded =false;
    this.reffirebase.child(this.case.chatRoomName).on('value', resp => {
      this.messages = [];
      this.messages = snapshotToArray(resp);
      console.log('this.messages');
      console.log(this.messages);
      this.dataloaded =true;
      // setTimeout(() => {
      //   this.messages = snapshotToArray(resp);
      // }, 3000);
    });
  }

  // Tab Function

  onDetailTabClick() {
    
    this.isMessageTab = false;
    this.isDocumentTab = false;
    this.isCallsTab = false;
    var obj =this;
    setTimeout(() => {
      obj.isDetailTab = true;
    }, 100);

  }

  onMessageTabClick() {
    this.isInboxTab = true;
    this.isSentTab = false;
    this.isDetailTab = false;
    this.isMessageTab = true;
    this.isDocumentTab = false;
    this.messageListingPage = 1;
    this.allMsgLoaded = false;
    // this.openConnection();
    // this.socket.onopen = (res: any) => {
    //   this.addUser();
    // }
    // this.get_messages();
  }

  onCallTabClick() {
    this.isCallsTab = true;
    this.isPendingTab = true;
    this.isHistoryTab = false;
    this.isDetailTab = false;
    this.isMessageTab = false;
    this.isDocumentTab = false;
    if (this.case.advisorId) {
      this.getCallRequests();
    }
  }

  onDocumentTabClick() {
    this.isDetailTab = false;
    this.isMessageTab = false;
    this.isDocumentTab = true;
    this.isCallsTab = false;
    this.getCaseDocuments(this.route.snapshot.queryParams.case_id, this.documentListingPage);
  }

  SetNewSize(textarea) {
    if (textarea.value.length > 5) {
      textarea.cols = 50;
      textarea.rows = 50;
    } else {
      textarea.cols = 10;
      textarea.rows = 15;
    }
  }

  oninput(ev: any) {
    $('#myTextArea').on('keyup paste', function () {
      var $el = $(this),
        offset = $el.innerHeight() - $el.height();

      if ($el.innerHeight() < this.scrollHeight) {
        // Grow the field if scroll height is smaller
        $el.height(this.scrollHeight - offset);
      } else {
        // Shrink the field and then re-set it to the scroll height in case it needs to shrink
        $el.height(1);
        $el.height(this.scrollHeight - offset);
      }
    });
  }

  addUser() {
    if (this.appService.getUserType() === 'Counselor') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Counselor", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    } else if (this.appService.getUserType() === 'Admin') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Admin", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    } else if (this.appService.getUserType() === 'User') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "User", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    }
  }

  openConnection() {
    this.socket = new WebSocket(environment.chatBaseUrl + localStorage.getItem('token'));
    this.socket.onmessage = (res: any) => {
      console.log(res);
      res = JSON.parse(res.data);
      if (res.cmd === 'connection') {
        this.getConversation();
      } else if (res.cmd === 'ack-user-joined-chat') {
        // this.typePlaceholder = 'Type your message here';
        // this.isSendMsg = false;
        // if (this.isRequest) {
        //   this.startTimer();
        // }
      } else if (res.cmd === 'end-chat') {
        // this.getSuccess(res.data.message);
        // this.navCtrl.navigateRoot('/leftmenu/tabs/chat');
      } else if (res.cmd === 'chat-history') {


        // console.log(this.messages);
        if (this.messageListingPage === 1) {
          this.messages = [];
          this.messages.push(...res.data.messages);
          this.messages.reverse();
          this.messagesCount = res.data.count;
          if (res.data.messages.length == 0) {
            this.allMsgLoaded = true;
          }
        } else {
          if (res.data.messages.length == 0) {
            this.allMsgLoaded = true;
          } else {
            this.messages.unshift(...res.data.messages.reverse());
          }
        }
        this.spinner.hide('cases-details');
      } else if (res.cmd === 'message') {
        if (res.data.media) {
          this.messages.push(res.data);
          if (this.appService.getUserType() == 'User') {
            this.appService.showSuccessToast('You have a new message from Employee Advocate');

          } else {
            this.appService.showSuccessToast('You have a new message from User');

          }

          console.log(this.messages);
        } else {
          if (this.case.id.toString() == res.data.caase.toString()) {
            this.messages.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: ((this.appService.getUserType() == 'User' ? this.case.advisor.id.toString() : this.case.user.id.toString())) });
            if (this.appService.getUserType() == 'User') {
              this.appService.showSuccessToast('You have a new message from Employee Advocate');

            } else {
              this.appService.showSuccessToast('You have a new message from User');

            }


          }
          // this.conversationContent.scrollToBottom();
        }
      }
    }
  }

  onPreviewClick() {
    // window.open(this.url, "_blank");
    // if (this.url != 'assets/images/doc-preview.png') {
    //   if (this.previewType == 'image') {
    //     // this.showPreview(this.url);
    //     window.open(this.url, '_blank');
    //   } else {
    //     // this.showPreview(undefined, this.videoUrl);
    //     // window.open(this.videoUrl, '_system');
    //     // this.previewAnyFile.preview(this.videoUrl)
    //     //   .then((res: any) => console.log(res))
    //     //   .catch((error: any) => console.error(error));
    //   }
    // }
  }

  onClipIconClick(event: any) {
    this.url = 'assets/images/empty-image.png';
    console.log(event);
    let type: string = event.target.files[0].type.toString();
    console.log(type);
    if (type.search('image') == 0) {
      this.previewType = 'image';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (ev: any) => { // called once readAsDataURL is completed
          this.url = ev.target.result;
        }
      }
    } else if (type.search('video') == 0) {
      this.previewType = 'video';
      this.url = 'assets/images/video-preview.png';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onloadend = (ev: any) => { // called once readAsDataURL is completed
          this.videoUrl = ev.target.result;
        }
      }
    } else {
      this.url = 'assets/images/doc-preview.png';
    }

    console.log(event);
    console.log('ajskdlg');
    console.log(event.target.files[0]);
    this.file = event.target.files[0];

    this.formData = new FormData();
    this.formData.append('recipient', ((this.appService.getUserType() == 'User' ? this.case.advisor.id.toString() : this.case.user.id.toString())));
    this.formData.append('userType', this.appService.getUserType());
    this.formData.append('media-file', this.file, this.file.name);
    this.formData.append('caseId', this.case.id);
  }

  onRemovePreviewClick() {
    this.file = undefined;
    console.log(this.fileValue);
    this.fileValue = '';
  }

  uploadAttachment() {
    this.spinner.show('case-chat');
    this.formData.append('msg', this.message_chat);
    this.apiService.uploadCaseChatAttachment(this.appService.getToken(), this.formData).subscribe(
      (result: any) => {
        if (result.status) {
          const msg = result.message;
          const data = { "cmd": "message", "data": msg };
          this.socket.send(JSON.stringify(data));
          this.file = undefined;
          this.fileValue = '';
          this.message_chat = '';

        }
        this.messages.push(result.message);
        console.log(result);
        console.log(this.conversation);
        this.spinner.hide('case-chat');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('case-chat');
      }
    );
  }

  async sendMessage() {
    this.msg_error = '';
    if ((this.message_chat === '' || this.message_chat === undefined) && !this.file) {
      this.msg_error = 'write something to send';
      return false;
    }
    if (this.file) {
      // console.log('file exist');
      // this.uploadAttachment();
      // const loading = await this.loadingCtrl.create({
      // });
      // await loading.present();
      this.spinner.show('cases-details');

      const fileName = this.file.name;
      let type: string = this.file.type.toString();
      console.log(type);
      var fileRef;
      if (type.search('image') == 0) {
        type = 'Image';
        fileRef = firebase.storage().ref('images/' + fileName);
      } else if (type.search('video') == 0) {
        type = 'Video';
        fileRef = firebase.storage().ref('videos/' + fileName);
      } else {
        type = 'Doc';
        fileRef = firebase.storage().ref('documents/' + fileName);
      }



      const uploadTask = fileRef.put(this.file);
      const obj = this;
      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', snapshot => {
        }, error => {
          reject(error);
        }, () => {
          // tslint:disable-next-line: only-arrow-functions
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log('teste resolve url ' + downloadURL);
            const newData = obj.reffirebase.child(obj.case.chatRoomName).push();

            newData.set({
              message: obj.message_chat,
              sender_id: +obj.appService.getProfile().id,
              recipientId: (obj.appService.getUserType() == 'User') ? obj.case.advisor.id : obj.case.user.id,
              media: downloadURL,
              createdAt: new Date().toISOString(),
              category: obj.case.name,
              messageType: type
            });
            obj.message_chat = '';
            obj.spinner.hide('cases-details');

            obj.sendNotificationToUser((obj.appService.getUserType() == 'User') ? obj.case.advisor.id : obj.case.user.id);

            obj.file = undefined;
            resolve({ fileName, downloadURL });
          });
        });
      });
    } else {
      // this.messages.push({ message: this.message_chat, messageType: "Text", recipientId: ((this.appService.getUserType() == 'User' ? this.case.advisor.id.toString() : this.case.user.id.toString())), senderId: this.appService.getProfile().id });
      // console.log(this.messages);
      // const data = { "cmd": "message", "data": { "messageType": "Text", "message": this.message_chat, "senderId": this.appService.getProfile().id, "recipientId": ((this.appService.getUserType() == 'User' ? this.case.advisor.id.toString() : this.case.user.id.toString())), "caase": this.case.id } };
      // this.socket.send(JSON.stringify(data));
      // this.message_chat = '';
      const newData = this.reffirebase.child(this.case.chatRoomName).push();

      newData.set({
        message: this.message_chat,
        sender_id: +this.appService.getProfile().id,
        recipientId: (this.appService.getUserType() == 'User') ? this.case.advisor.id : this.case.user.id,
        media: '',
        createdAt: new Date().toISOString(),
        category: this.case.name,
        messageType: 'Text'
      });
      this.message_chat = '';
      this.sendNotificationToUser((this.appService.getUserType() == 'User') ? this.case.advisor.id : this.case.user.id);
    }
  }

  sendNotificationToUser(id: any) {
    this.apiService.sendCaseMsgNotificationToUser(this.appService.getToken(), id.toString(), this.case.id.toString()).subscribe(
      (res: any) => {
        console.log(res);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getConversation() {
    this.spinner.show('cases-details');
    const data = { cmd: 'chat-history', "data": { "page": 1, "case": this.case.id } };
    this.socket.send(JSON.stringify(data));
  }

  getConversationPrevious() {
    this.spinner.show('cases-details');
    this.messageListingPage = this.messageListingPage + 1;
    const data = { cmd: 'chat-history', "data": { "page": this.messageListingPage, "case": this.case.id } };
    this.socket.send(JSON.stringify(data));
  }

  showPicture(img: any) {
    window.open(img, '_blank');
  }

  showDoc(file: any) {
    window.open(file, '_blank');
  }

  clearData() {
    console.log('clear');
    console.log(this.fileValue);
    this.fileValue = null;
  }

  onSentTabClick() {
    console.log('sent tab');
    this.isSentTab = true;
    this.isInboxTab = false;
    this.isDetailTab = false;
    this.isMessageTab = true;
    this.isDocumentTab = false;
    this.getSentCaseMessages(this.route.snapshot.queryParams.case_id, this.sentListingPage);
  }



  onPendingTabClick() {
    this.isCallsTab = true;
    this.isPendingTab = true;
    this.isHistoryTab = false
    if (this.case.advisorId) {
      this.getCallRequests();
    }
  }

  onHistoryTabClick() {
    this.isCallsTab = true;
    this.isPendingTab = false;
    this.isHistoryTab = true;
    this.getCallsHistory();
  }

  // Click Events

  onDocClick(doc: any) {
    window.open(environment.caseDocBaseUrl + doc.filename, '_blank');
  }

  onNotesClick() {
    this.router.navigate(['/notes'], {
      queryParams: {
        case_id: this.case.id
      }
    });
  }

  onAddBtnClick() {
    console.log('clciked');
  }

  // modal functions

  onReplyClose() {
    this.m_message = '';
    this.message = '';
    $("#reply-popup").removeClass("show");
    $("#reply-popup").css("display", "none");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  }

  onReplyBtnClick(replyid: any) {
    $("body").addClass("modal-open");
    $("#reply-popup").addClass("show");
    $("#reply-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.replyId = replyid;
  }

  onNewMessageClose() {
    this.m_message = '';
    this.message = '';
    $("body").removeClass("modal-open");
    $("#new-message-popup").removeClass("show");
    $("#new-message-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onNewMessageBtnClick() {
    $("body").addClass("modal-open");
    $("#new-message-popup").addClass("show");
    $("#new-message-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    // this.replyId = replyid;
  }

  onNewCallBtnClick() {
    if (this.case.advisor == null) {
      this.appService.showErrorToast('You can not make a call. No counsellor assigned to this case.');
    } else {
      this.goToCallSchedule();
    }
  }

  goToCallSchedule() {
    $("body").addClass("modal-open");
    $("#new-call-popup").addClass("show");
    $("#new-call-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");

    this.timeLoaded = false;
    this.slots = [];

    this.selectedDate = this.appService.getTodayDate();
    this.selectedDay = this.appService.getDayName(this.selectedDate);
    this.selectedDayShort = this.appService.getShortDayName(this.selectedDate);

    console.log(this.scheduleData);
    this.getSlots();
  }

  getSlots() {
    this.slots = [];
    if (this.scheduleData != null) {

      this.scheduleData.forEach(data => {
        const day = data.substring(0, 3);

        if (day == this.selectedDayShort) {
          const a = data.split(';')
          let start_time = a[1];
          let end_time = a[2];

          start_time = this.appService.getEventDateTime(this.selectedDate, start_time);
          end_time = this.appService.getEventDateTime(this.selectedDate, end_time);

          this.slots.push({
            t: start_time,
          });

          for (let index = 1; index < 500; index++) {
            this.slots.push({
              t: this.appService.add15Minutes(this.slots[index - 1].t),
            });
            if (this.appService.getTime24Format(this.slots[index].t) == this.appService.getTime24Format(end_time)) {
              break;
            }
          }
        }
      });
    }
    console.log(this.slots);

    this.disablePassedTime();
    this.slots.splice(-1,1)
  }

  disablePassedTime() {
    this.timeLoaded = false;

    // remove passed time slots
    var swapped;
    do {
      swapped = false;
      for (var i = 0; i < this.slots.length; i++) {
        if (this.appService.isPassed(this.slots[i].t, this.selectedDate)) {
          this.slots.splice(this.slots.indexOf(this.slots[i], 0), 1);
          swapped = true;
          break;
        }

      }
    } while (swapped);

    console.log(this.slots);

    // check user events
    console.log(this.userEvents);
    if (this.userEvents.length != 0) {
      var temp_slots1 = [];
      temp_slots1 = this.slots;
      this.slots = [];
      var isSame1 = false;
      for (const slot of temp_slots1) {
        isSame1 = false;
        for (const call of this.userEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame1 = true;
            break;
          }
        }
        if (!isSame1) {
          this.slots.push(slot);
        }
      }
    }

    // check user general events

    if (this.userGenEvents.length != 0) {
      var temp_slots2 = [];
      temp_slots2 = this.slots;
      this.slots = [];
      var isSame2 = false;
      for (const slot of temp_slots2) {
        isSame2 = false;
        for (const call of this.userGenEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame2 = true;
            break;
          }
        }
        if (!isSame2) {
          this.slots.push(slot);
        }
      }
    }

    // check counselor events

    if (this.counselorEvents.length != 0) {
      var temp_slots3 = [];
      temp_slots3 = this.slots;
      this.slots = [];
      var isSame3 = false;
      for (const slot of temp_slots3) {
        isSame3 = false;
        for (const call of this.counselorEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame3 = true;
            break;
          }
        }
        if (!isSame3) {
          this.slots.push(slot);
        }
      }
    }

    // check counselor general events

    if (this.counselorGenEvents.length != 0) {
      var temp_slots4 = [];
      temp_slots4 = this.slots;
      this.slots = [];
      var isSame4 = false;
      for (const slot of temp_slots4) {
        isSame4 = false;
        for (const call of this.counselorGenEvents) {
          if (this.appService.isDateTimeSameee(call.eventDate, slot.t)) {
            isSame4 = true;
            break;
          }
        }
        if (!isSame4) {
          this.slots.push(slot);
        }
      }
    }

    console.log(this.slots);

    this.timeLoaded = true;
  }

  onScheduleCallClose() {
    $("body").removeClass("modal-open");
    $("#new-call-popup").removeClass("show");
    $("#new-call-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  sendNew_message() {
    this.m_message = ''; this.m_subject = '';
    // if (this.subject === undefined || this.subject === '') {
    //   this.m_message = 'Subject required';
    //   return false;
    // }
    if (this.message === undefined || this.message === '') {
      this.m_message = 'Message required';
      return false;
    } else {
      this.spinner.show('cases-details');
      var form = new FormData();
      form.append('case_id', this.route.snapshot.queryParams.case_id);
      form.append('subject', this.subject);
      form.append('message_text', this.message);
      this.apiService.addNewMessage(this.appService.getToken(), form).subscribe(
        (result: any) => {

          console.log(result);
          this.spinner.hide('cases-details');
          if (result.status) {
            this.appService.showSuccessToast('Message send successfully.');

          } else {
            this.appService.showErrorToast(result.message);
          }
          this.onNewMessageClose();

        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('cases-details');
          this.onNewMessageClose();
        }
      );
    }
  }

  ngOnInit() {
  }

  keyPressFunction(event) {
    if (event.keyCode === 13 && event.shiftKey) {
      this.message_chat = this.message_chat + "\n";
      return false;
    } else if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  onSelectedDateChange(ev: any) {
    this.selectedDay = this.appService.getDayName(ev.target.value);
    this.selectedDate = ev.target.value;
    this.selectedDayShort = this.appService.getShortDayName(ev.target.value);
    this.getSlots();
  }

  onTimeClick(t: any) {
    console.log(t);
    var eventDate = this.appService.getEventDateTime(this.selectedDate, t);

    this.sendCallRequestToCounselor(eventDate);
  }


  sendCallRequestToCounselor(eventDate) {
    var loader = this.spinner.show('cases-details');
    this.apiService.sendCaseCallRequestByUser(this.appService.getToken(), this.case.id, this.case.advisor.id, this.appService.getProfile().id, eventDate).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.appService.showSuccessToast(res.message);
          this.onScheduleCallClose();
          this.getCallRequests(loader);
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide('cases-details');
      }
    );
  }

  getCase() {
    this.spinner.show('cases-details');
    this.apiService.getCaseById(this.appService.getToken(), +this.route.snapshot.queryParams.case_id).subscribe(
      (result: any) => {
        if (result.status) {
          console.log(result);
          this.case = result.case;
          if (this.appService.getUserType() == 'User') {
            if (this.case.advisor) {
              this.scheduleData = result.case.advisor.schedule;
            }
          }
          this.get_messages();
          console.log(this.scheduleData);
          this.caseName = result.case.name;
          this.caseDescription = result.case.description;


          if (this.appService.getUserType() === 'Admin' || this.appService.getUserType() === 'Counselor') {
            this.userName = result.case.user.fullname;
            this.profilePic = result.case.user.profile_pic;
          } else {
            if (result.case.advisor !== null) {
              this.userName = result.case.advisor.fullname;
              this.profilePic = result.case.advisor.profile_pic;
            }
          }
        }
        this.spinner.hide('cases-details');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('cases-details');
      }
    );
  }

  getCaseDocuments(caseId: any, page = 1, event?: any) {
    this.dataloaded =false;
    if (event) {
      this.documentListingPage += 1;
      console.log(this.documentListingPage);
      this.apiService.getCaseDocuments(this.appService.getToken(), +caseId, this.documentListingPage.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            console.log(this.caseDocuments);
            this.caseDocuments.push(...result.documents);
            this.caseDocumentsCount = result.count;
            // event.target.complete();
            this.dataloaded =true;
          }else{
            this.dataloaded =true;
          }
        },
        (err: any) => {
          console.log(this.caseDocuments);
          this.caseDocumentsCount = 0;
          console.log(err.error);
          this.dataloaded =true;
        }
      );
    } else {
      this.apiService.getCaseDocuments(this.appService.getToken(), +caseId, page.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            console.log(this.caseDocuments);
            this.caseDocuments = result.documents;
            this.caseDocumentsCount = result.count;
            this.dataloaded =true;
          }else{
            this.dataloaded =true;
          }
          // loader.dismiss();
        },
        (err: any) => {
          console.log(this.caseDocuments);
          this.caseDocumentsCount = 0;
          console.log(err.error);
          this.dataloaded =true;
          // loader.dismiss();
        }
      );
    }
  }

  getInboxCaseMessages(caseId: any, page = 1, event?: any) {
    if (event) {
      this.inboxListingPage += 1;
      console.log(this.inboxListingPage);
      this.apiService.getInboxMessages(this.appService.getToken(), +caseId, this.inboxListingPage.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            this.inboxCaseMessages.push(...result.messages);
            this.inboxCaseMessagesCount = result.count;
          }
        },
        (err: any) => {
          this.inboxCaseMessagesCount = '0';
          console.log(err.error);
        }
      );
    } else {
      this.apiService.getInboxMessages(this.appService.getToken(), +caseId, page.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            this.inboxCaseMessages = result.messages;
            this.inboxCaseMessagesCount = result.count;
          }
          // loader.dismiss();
        },
        (err: any) => {
          this.inboxCaseMessagesCount = '0';
          console.log(err.error);
          // loader.dismiss();
        }
      );
    }
  }

  getSentCaseMessages(caseId: any, page = 1, event?: any) {
    if (event) {
      this.sentListingPage += 1;
      console.log(this.sentListingPage);
      this.apiService.getSentMessages(this.appService.getToken(), +caseId, this.sentListingPage.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            this.sentCaseMessages.push(...result.messages);
            this.sentCaseMessagesCount = result.count;
            // event.target.complete();
          }
        },
        (err: any) => {
          this.sentCaseMessagesCount = '0';
          console.log(err.error);
        }
      );
    } else {

      this.apiService.getSentMessages(this.appService.getToken(), +caseId, page.toString()).subscribe(
        (result: any) => {
          if (result.status) {
            this.sentCaseMessages = result.messages;
            this.sentCaseMessagesCount = result.count;
          }
          // loader.dismiss();
        },
        (err: any) => {
          this.sentCaseMessagesCount = '0';
          console.log(err.error);
          // loader.dismiss();
        }
      );
    }
  }

  startChatClick() {
    if (this.appService.getUserType() == 'User') {
      this.router.navigate(['/case-chat'], {
        queryParams: {
          user_id: this.case.advisor.id,
          user_name: this.case.advisor.fullname,
          user_profile_pic: this.case.advisor.profile_pic,
          user_topic: this.case.name,
          case_id: this.case.id
        }
      });
    } else {
      this.router.navigate(['/case-chat'], {
        queryParams: {
          user_id: this.case.user.id,
          user_name: this.case.user.fullname,
          user_profile_pic: this.case.user.profile_pic,
          user_topic: this.case.name,
          case_id: this.case.id
        }
      });
    }

  }

  seeChatClick() {
    if (this.appService.getUserType() == 'User') {
      this.router.navigate(['/case-chat'], {
        queryParams: {
          user_id: this.case.advisor.id,
          user_name: this.case.advisor.fullname,
          user_profile_pic: this.case.advisor.profile_pic,
          user_topic: this.case.name,
          case_id: this.case.id,
          isAdminView: true,
        }
      });
    } else {
      this.router.navigate(['/case-chat'], {
        queryParams: {
          user_id: this.case.user.id,
          user_name: this.case.user.fullname,
          user_profile_pic: this.case.user.profile_pic,
          user_topic: this.case.name,
          case_id: this.case.id,
          isAdminView: true,
        }
      });
    }

  }

  uploadDocument(ev: any) {
    let formData = new FormData();
    formData.append('case_id', this.route.snapshot.queryParams.case_id);
    formData.append('document_name', ev.target.files[0].name);
    formData.append('document', ev.target.files[0], ev.target.files[0].name);
    if (this.appService.getUserType() == 'User') {
      formData.append('counselorId', this.case.advisor.id);
      formData.append('userId', this.appService.getProfile().id);
    } else {
      formData.append('userId', this.case.user.id);
      formData.append('counselorId', this.appService.getProfile().id);
    }
    this.spinner.show('cases-details');
    this.apiService.uploadCaseDocument(localStorage.getItem('token'), formData).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.apiService.getCaseDocuments(this.appService.getToken(), this.route.snapshot.queryParams.case_id, 1).subscribe(
            (result: any) => {
              if (result.status) {
                this.caseDocuments = result.documents;
                this.caseDocumentsCount = result.count;
              }
              this.appService.showSuccessToast('Document uploaded successfully');
              this.spinner.hide('cases-details');
            },
            (err: any) => {
              this.caseDocumentsCount = 0;
              this.spinner.hide('cases-details');
            }
          );
        }
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide('cases-details');
      }
    );
  }

  getCallsHistory() {
    this.dataloaded =false;
    console.log(this.case);
    this.spinner.show('cases-details');
    this.apiService.getCaseCallsHistory(this.appService.getToken(), this.case.id).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.callsHistory = res.data;
          this.dataloaded =true;
        }else{
          this.dataloaded =true;
        }
        this.spinner.hide('cases-details');
      },
      (err: any) => {
        console.log(err);
        this.spinner.hide('cases-details');
        this.dataloaded =true;
      }
    );
  }

  getCallRequests(l?: any) {
    this.dataloaded =false;
    if (l) {
      this.apiService.getCaseCallsRequestOfUser(this.appService.getToken(), this.case.id, this.case.advisorId.id, ((this.appService.getUserType() == 'User') ? this.appService.getProfile().id : this.case.user.id)).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.callRequests = res.data;
            this.counselorEvents = res.counselorEvents;
            this.counselorGenEvents = res.counselorGenEvents;
            this.userEvents = res.userEvents;
            this.userGenEvents = res.userGenEvents;
            this.scheduleData = res.counselor.schedule;
            this.dataloaded =true;
          }else{
            this.dataloaded =true;
          }
          this.spinner.hide('cases-details');
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide('cases-details');
          this.dataloaded =true;
        }
      );
    } else {
      this.spinner.show('cases-details');
      this.apiService.getCaseCallsRequestOfUser(this.appService.getToken(), this.case.id, this.case.advisorId.id, ((this.appService.getUserType() == 'User') ? this.appService.getProfile().id : this.case.user.id)).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status) {
            this.dataloaded =true;
            this.callRequests = res.data;
            this.counselorEvents = res.counselorEvents;
            this.counselorGenEvents = res.counselorGenEvents;
            this.userEvents = res.userEvents;
            this.userGenEvents = res.userGenEvents;
            this.scheduleData = res.counselor.schedule;
          }else{
            this.dataloaded =true;
          }
          this.spinner.hide('cases-details');
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide('cases-details');
          this.dataloaded =true;
        }
      );
    }
  }

  getCallRequestsInterval() {
    this.apiService.getCaseCallsRequestOfUser(this.appService.getToken(), this.case.id, this.case.advisorId.id, ((this.appService.getUserType() == 'User') ? this.appService.getProfile().id : this.case.user.id)).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.callRequests = res.data;
          this.counselorEvents = res.counselorEvents;
          this.counselorGenEvents = res.counselorGenEvents;
          this.userEvents = res.userEvents;
          this.userGenEvents = res.userGenEvents;
          this.scheduleData = res.counselor.schedule;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }


  startCall(call: any) {
    if (this.appService.getUserType() == 'User') {
      this.spinner.show('cases-details');
      this.apiService.caseCallStartedByUser(this.appService.getToken(), call.id).subscribe(
        (res: any) => {
          console.log(res);
          this.spinner.hide('cases-details');
          this.router.navigate(['/video-call'], {
            queryParams: {
              session: call.session_id,
              token: call.counselor_token,
              isCaseCall: true,
              callId: call.id.toString()
            }
          });
        },
        (err: any) => {
          this.spinner.hide('cases-details');
          console.log(err);
        }
      );
    } else {
      this.spinner.show('cases-details');

      this.apiService.caseCallStartedByCounselor(this.appService.getToken(), call.id).subscribe(
        (res: any) => {
          this.spinner.hide('cases-details');
          console.log(res);
          this.router.navigate(['/video-call'], {
            queryParams: {
              session: call.session_id,
              token: call.counselor_token,
              isCaseCall: true,
              callId: call.id.toString()
            }
          });
        },
        (err: any) => {
          this.spinner.hide('cases-details');
          console.log(err);
        }
      );
    }
  }

}


export const snapshotToArray = snapshot => {
  const returnArr = [];

  snapshot.forEach(childSnapshot => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};
