import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	newPassword: any; old_error: any = ''; new_error: any = ''; confirm_error: any = '';
	oldPassword: any; confirm_password: any;
	userName: any;
	// success_message: any = ''; error_message: any = '';
	userProfilePic: any = '';
	userEmail: any;
	userPhone: any;
	userCity: any;
	userState: any; education: any;
	userAddress: any;
	Country: any; userType: any;
	ch_password: any = false;
	Company: any;
	position: any;

	scheduleData: any[] = [];
	disableSaveBtn: boolean = true;
	scheduleDataPerDay: any[] = [];
	startTime: any; startTime_error: any; sameTime_error: any;
	endTime: any; endTime_error: any;
	selectedDay = { d: 'Sun', class: 'active' };
	days: any[] = [
		{ d: 'Sun', class: 'active' },
		{ d: 'Mon', class: '' },
		{ d: 'Tue', class: '' },
		{ d: 'Wed', class: '' },
		{ d: 'Thu', class: '' },
		{ d: 'Fri', class: '' },
		{ d: 'Sat', class: '' }
	]

	profileSegment: any = 'general';

	constructor(private route: ActivatedRoute, public appService: AppService, private router: Router,
		private apiService: ApiService, private spinner: NgxSpinnerService) {
		this.userType = localStorage.getItem('userType');
		this.userProfilePic = this.appService.getProfile().profilePic;
		this.Company = this.appService.getProfile().company;
		this.position = this.appService.getProfile().position;
		this.userName = this.appService.getProfile().fullName;
		this.education = this.appService.getProfile().education_name;
		this.userEmail = this.appService.getProfile().email;
		this.userPhone = this.appService.getProfile().phon;
		this.userCity = this.appService.getProfile().city_name;
		this.userState = this.appService.getProfile().userState_name;
		this.userAddress = this.appService.getProfile().address;
		this.Country = this.appService.getProfile().country_name;
		this.ch_password = false;

		this.selectedDay = this.days[0];
		const raw = this.appService.getProfile().scheduleData;
		this.scheduleData = JSON.parse(raw);
		if (this.scheduleData == null) {
			this.scheduleData = [];
		}
		console.log(this.scheduleData);
		this.scheduleData.forEach(data => {
			if (data.substring(0, 3) == this.selectedDay.d) {
				this.scheduleDataPerDay.push(data);
			}
		});
		console.log(this.scheduleDataPerDay);
	}

	onEditClick() {
		this.router.navigate(['/edit-profile']);
	}

	onDayClick(day: any) {
		const index = this.days.indexOf(day);
		this.days.forEach(day => {
			day.class = ''
		});
		this.days[index].class = 'active';
		this.selectedDay = day;
		console.log(this.days);
		this.startTime = undefined;
		this.endTime = undefined;
		this.scheduleDataPerDay = [];
		this.scheduleData.forEach(data => {
			if (data.substring(0, 3) == this.selectedDay.d) {
				this.scheduleDataPerDay.push(data);
			}
		});
		console.log(this.scheduleData);
		console.log(this.scheduleDataPerDay);
	}

	onEndTimeChange(ev: any) {
		console.log(ev);
		this.endTime = ev.target.value;
	}

	onStartTimeChange(ev: any) {
		console.log(ev);
		this.startTime = ev.target.value;
	}

	onAddTimeIconClick() {
		console.log('akdf');
		console.log(this.startTime)
		console.log(this.endTime)

		this.startTime_error = ''; this.endTime_error = ''; this.sameTime_error = '';

		if (this.startTime == undefined || this.startTime == '') {
			console.log('akew');
			this.startTime_error = 'Start time is required';
			return false;
		} else if (this.endTime == undefined || this.endTime == '') {
			this.endTime_error = 'End time is required';
			return false;
		}
		const start = this.appService.makeDate(this.startTime);
		const end = this.appService.makeDate(this.endTime);
		console.log(start);
		console.log(end);

		if (!this.appService.compareTime(end, start)) {
			console.log(true);
			this.endTime_error = 'End time is invalid, should be greater than start time.';
			return false;
		}
		let isUpdate: boolean;
		let isSame: boolean;
		this.scheduleDataPerDay.forEach(data => {
			const arr = data.split(';')
			console.log(arr);
			const start1 = arr[1];
			const end1 = arr[2];
			console.log(start);
			console.log(this.startTime);
			if (this.appService.getTime24Format(start).toString() == this.appService.getTime24Format(start1).toString()
				&& this.appService.getTime24Format(end).toString() == this.appService.getTime24Format(end1).toString()) {
				console.log('same time now allowed');
				this.sameTime_error = 'Same time is not allowed!';
				isSame = true;
			} else if (this.appService.getTime24Format(start).toString() == this.appService.getTime24Format(start1).toString()) {
				console.log('same time now allowed');
				this.sameTime_error = 'Same time is not allowed!';
				isSame = true;
			} else if (this.appService.getTime24Format(end).toString() == this.appService.getTime24Format(end1).toString()) {
				console.log('same time now allowed');
				this.sameTime_error = 'Same time is not allowed!';
				isSame = true;
			} else if (this.appService.isAfter(start, start1) && this.appService.isBefore(end, end1)) {
				console.log('same time now allowed');
				this.sameTime_error = 'Same time is not allowed!';
				isSame = true;
			} else if (this.appService.isAfter(start, start1) && this.appService.isAfter(end, end1)) {
				const index = this.scheduleDataPerDay.indexOf(data);
				const indexx = this.scheduleData.indexOf(data);
				console.log(indexx);
				this.scheduleDataPerDay[index] = this.selectedDay.d + ';' + start + ';' + end;
				this.scheduleData[indexx] = this.selectedDay.d + ';' + start + ';' + end;
				isSame = true;
				isUpdate = true;
			} else if (this.appService.isBefore(start, start1) && this.appService.isBefore(end, end1)) {
				const index = this.scheduleDataPerDay.indexOf(data);
				const indexx = this.scheduleData.indexOf(data);
				console.log(indexx);
				this.scheduleDataPerDay[index] = this.selectedDay.d + ';' + start + ';' + end;
				this.scheduleData[indexx] = this.selectedDay.d + ';' + start + ';' + end;
				isSame = true;
				isUpdate = true;
			}
			console.log(this.appService.compareTime(this.endTime, end));
		});
		if (!isSame) {
			this.scheduleData.push(this.selectedDay.d + ';' + start + ';' + end);
			this.scheduleDataPerDay.push(this.selectedDay.d + ';' + start + ';' + end);
			this.disableSaveBtn = false;
		}
		if (isUpdate) {
			this.disableSaveBtn = false;
		}
		console.log(this.scheduleData);
		console.log(this.scheduleDataPerDay);
		this.startTime = undefined;
		this.endTime = undefined;
	}

	onDeleteClick(data: any) {
		this.scheduleData.splice(this.scheduleData.indexOf(data, 0), 1);
		this.scheduleDataPerDay.splice(this.scheduleDataPerDay.indexOf(data, 0), 1);
		console.log(this.scheduleData);
		console.log(this.scheduleDataPerDay);
		this.disableSaveBtn = false;
	}

	onSubmitSchedule() {
		const g = JSON.stringify(this.scheduleData);
		console.log(g);
		const p = JSON.parse(g);
		console.log(p);
		this.spinner.show('profile');
		this.apiService.setSchedule(this.appService.getToken(), this.scheduleData).subscribe(
			(res: any) => {
				console.log(res);
				if (res.status) {
					localStorage.setItem('schedule', g);
					this.appService.showSuccessToast(res.message);
				}
				this.spinner.hide('profile');
			},
			(err: any) => {
				this.spinner.hide('profile');
				console.log(err);
			}
		);
	}

	ngOnInit() {
	}
	updatePassword() {
		this.old_error = ''; this.new_error = ''; this.confirm_error = '';
		if ((this.oldPassword == null) || (this.oldPassword == '')) {
			this.old_error = "Current Password is required.";
			return false;
		}

		if ((this.newPassword == null) || (this.newPassword == '')) {
			this.new_error = "New Password is required.";
			return false;
		}
		// let newPasswordCheck = this.validator.isPasswordValid(this.newPassword);
		// if (newPasswordCheck == false) {
		// 	this.new_error = "Please enter minimum 6 digits as a password.";
		// 	return false;
		// }
		if (this.oldPassword != localStorage.getItem("password")) {
			this.old_error = 'Old Password is miss match.';
			return false;
		}
		if ((this.confirm_password == null) || (this.confirm_password == '')) {
			this.confirm_error = "Confirm Password is required.";
			return false;
		}
		if (this.confirm_password != this.newPassword) {
			this.confirm_error = 'Confirm Password is miss match.';
			return false;
		}

		let token = this.appService.getToken();

		this.apiService.changeUserPassword(token, this.oldPassword, this.newPassword).subscribe(
			(result: any) => {
				this.appService.setProfile_password(this.newPassword);
				//	this.getSuccess('Password Changed Successfully');

			},
			(err: any) => {

				//this.getErrr('Some error occured.');
			});


	}
}
