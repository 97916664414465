import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import * as $ from "jquery";

declare var OT: any;
declare var window: any;

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css']
})
export class VideoCallComponent implements OnInit {

  callSession: any;
  publisher: any;
  subscriber: any;
  rate: any = 0;

  timeInterval: any;
  timer: any = {
    min: 14,
    sec: 59
  };
  call_type:boolean = false;
  ismute: any = "true";
  muteSrc = "icon-ygoinIcons-10";
  whichCamera: any = 'front';

  constructor(private route: ActivatedRoute, public appService: AppService, private router: Router,
    private apiService: ApiService, private ngZone: NgZone) {
     this.call_type = this.route.snapshot.queryParams.isCaseCall;
  }

  ngOnInit() {
    this.startCall();

  }

  startCall() {
    this.initializeSession();
  }

  initializeSession() {
    if (OT.checkSystemRequirements() == 1) {
      this.callSession = OT.initSession(environment.opentok.api_key, this.route.snapshot.queryParams.session);
      this.publisher = OT.initPublisher('publisher', {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      });

      this.callSession.connect(this.route.snapshot.queryParams.token, function (error) {
        if (error) {
          console.log("Error connecting: ", error.name, error.message);
        } else {
          console.log("Connected to the session.");

        }
      });

      this.callSession.on({
        streamCreated: (event: any) => {
          console.log('stream created');
          this.callSession.subscribe(event.stream, 'subscriber', {
            insertMode: 'append',
            width: '100%',
            height: '100%'
          });
          this.startTimer();
        },
        streamDestroyed: async (event: any) => {
          console.log('stream destroyed');
          this.callSession.disconnect();
        },
        sessionConnected: (event: any) => {
          console.log("conected=====");

          this.callSession.publish(this.publisher);

        },
        sessionDisconnected: (event: any) => {
          // Adjust user interface.
          console.log('session disconnected');
         
          if (this.appService.getUserType() == 'User') {
            this.openRatePopUp();
          } else {
            
            if (this.route.snapshot.queryParams.isCaseCall == true) {
              this.apiService.completeCaseCallFromBothSide(this.appService.getToken(), this.route.snapshot.queryParams.callId,this.call_type).subscribe(
                (res: any) => {
                  console.log(res);
                  this.ngZone.run(
                    () => {
                      this.router.navigate(['/calls'], {
                        queryParams: {
                          isEndCall: true
                        }
                      });
                    }
                  );
                },
                (err: any) => {
                  console.log(err);
                  this.ngZone.run(
                    () => {
                      this.router.navigate(['/calls'], {
                        queryParams: {
                          isEndCall: true
                        }
                      });
                    }
                  );
                }
              );
            } else {
      
              this.apiService.completeGenCallFromBothSide(this.appService.getToken(), this.route.snapshot.queryParams.callId,this.call_type).subscribe(
                (res: any) => {
                  console.log(res);
                  this.ngZone.run(
                    () => {
                      this.router.navigate(['/calls'], {
                        queryParams: {
                          isEndCall: true
                        }
                      });
                    }
                  );
                },
                (err: any) => {
                  console.log(err);
                  this.ngZone.run(
                    () => {
                      this.router.navigate(['/calls'], {
                        queryParams: {
                          isEndCall: true
                        }
                      });
                    }
                  );
                }
              );
            }
          }
        }
      });

    } else {
      console.log('The client does not support WebRTC');
    }
  }

  disconnectSession() {
    if(this.callSession){
      this.callSession.disconnect();
      this.router.navigate(['/calls'], {
        queryParams: {
          isEndCall: true
        }
      });
    }else{
      this.router.navigate(['/calls'], {
        queryParams: {
          isEndCall: true
        }
      });
      this.callSession.forceDisconnect();
    }
   
    
  }

  onRateChange(ev: any) {
    console.log(ev);
    this.rate = ev.newValue;
  }

  openRatePopUp() {
    $("#rate").addClass("show");
    $("#rate").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
  }

  onClose(r: any) {
    this.rate = r;
    $("#rate").removeClass("show");
    $("#rate").css("display", "none");
    $(".modal-backdrop").remove();
    if (this.route.snapshot.queryParams.isCaseCall == true) {
      this.apiService.completeCaseCallFromBothSide(this.appService.getToken(), this.route.snapshot.queryParams.callId, this.rate,this.call_type).subscribe(
        (res: any) => {
          console.log(res);
          this.ngZone.run(
            () => {
              this.appService.showSuccessToast('You are successfully rated your call.');
              //chat-listing
              this.router.navigate(['/calls'], {
                queryParams: {
                  isEndCall: true
                }
              });
            }
          );
        },
        (err: any) => {
          console.log(err);
          this.ngZone.run(
            () => {
              //  this.router.navigate(['/calls']);
              this.router.navigate(['/calls'], {
                queryParams: {
                  isEndCall: true
                }
              });
            }
          );
        }
      );
    } else {
      this.apiService.completeGenCallFromBothSide(this.appService.getToken(), this.route.snapshot.queryParams.callId, this.rate,this.call_type).subscribe(
        (res: any) => {
          console.log(res);
          this.ngZone.run(
            () => {
              this.appService.showSuccessToast('You are successfully rateed your call.');
              this.router.navigate(['/calls'], {
                queryParams: {
                  isEndCall: true
                }
              });
            }
          );
        },
        (err: any) => {
          console.log(err);
          this.ngZone.run(
            () => {
              this.router.navigate(['/calls'], {
                queryParams: {
                  isEndCall: true
                }
              });
            }
          );
        }
      );
    }

  }

  startTimer() {
    console.log('chalaye');
    this.timeInterval = setInterval(() => {
      --this.timer.sec;
      if (this.timer.sec == 0) {
        if (this.timer.min == 0) {
          clearInterval(this.timeInterval);
          this.stopCallByUser();
        } else {
          this.timer.min = this.timer.min - 1;
          this.timer.sec = 59;
        }
      }
    }, 1000);
  }

  stopCallByCounselor() {
    clearInterval(this.timeInterval);
    this.disconnectSession();
  }

  stopCallByUser() {
    clearInterval(this.timeInterval);
    this.disconnectSession();
  }
}
