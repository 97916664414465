import { Component, OnInit } from '@angular/core';
import { ValidatorService } from 'src/app/services/validator.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email: any;
  m_email: any;
  // success_message: any; error_message: any;
  isOTPAlert: boolean = true;
  otp: any;
  m_otp: any;
  password: any;
  m_password: any;
  constructor(private router: Router, public validator: ValidatorService, private spinner: NgxSpinnerService,
    public apiService: ApiService, private appService: AppService) { }

  onSend() {
    this.m_email = '';
    if (this.email === '' || this.email === undefined) {
      this.m_email = 'Email is required.';
      return false;
    }
    if (!this.validator.isEmailValid(this.email)) {
      this.m_email = 'Enter a valid email.';
      return false;
    }
    this.spinner.show('forgotpassword');
    this.apiService.passwordResetRequest(this.email).subscribe(
      (result: any) => {
        this.appService.setSecurityToken(result.security_token);
        this.appService.showSuccessToast(result.message);
        this.spinner.hide('forgotpassword');
        $("#forgotpassword-popup").addClass("show");
        $("#forgotpassword-popup").css("display", "block");
        $("body").append("<div class='modal-backdrop fade show'></div>");
      },
      (err: any) => {
        this.spinner.hide('forgotpassword');
        this.appService.showErrorToast(err.error.message);
   
        // $("#forgotpassword-popup").addClass("show");
        // $("#forgotpassword-popup").css("display", "block");
        // $("body").append("<div class='modal-backdrop fade show'></div>");
      }
    );
  }

  onClose() {
    this.m_otp = '';
    this.isOTPAlert = true;
    $("#forgotpassword-popup").removeClass("show");
    $("#forgotpassword-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onSendOTP() {
    this.m_otp = '';
    if (this.otp === undefined || this.otp === '') {
      this.m_otp = 'OTP required';
      return false;
    } else {
      this.spinner.show('forgotpassword');
      this.setNewPassword(this.otp);
    }
  }

  ngOnInit() {
  }

  showOTPAlert() {

  }

  showError(msg: any, type: any) {

  }


  setNewPassword(otp: any) {
    console.log('working');
    this.apiService.passwordResetToken(otp, this.appService.getSecurityToken()).subscribe(
      (result: any) => {
        if (result.status) {
          this.appService.setSecureToken(result.secure_token);
          this.isOTPAlert = false;
        }
        console.log(result);
        this.spinner.hide('forgotpassword');
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('forgotpassword');
        alert(err.error.message);
      }
    );
  }

  confirmNewPassword() {
    this.m_password = '';
    if (this.password === undefined || this.password === '') {
      this.m_password = 'Password Required';
      return false;
    } else if (!this.validator.isPasswordValid(this.password)) {
      this.m_password = 'Minimum Length 6 Characters';
      return false;
    } else {
      this.spinner.show('forgotpassword');
      this.apiService.PasswordReset(this.password, this.appService.getSecureToken()).subscribe(
        (result: any) => {
          if (result.status) {
            alert(result.message);
            this.email = '';
          }
          this.email = '';
          this.onClose();
          this.spinner.hide('forgotpassword');
          this.router.navigate(['/councillor-home']);
        },
        (err) => {
          alert(err.error);
          this.onClose();
          this.spinner.hide('forgotpassword');
        }
      );
    }
  }

  // async getSuccess(msg) {
  //   this.success_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.success_message = ''; }, 6000);
  // }
  // async getErrr(msg) {
  //   this.error_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.error_message = ''; }, 6000);
  // }

}
