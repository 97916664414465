// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.myzolve.com/api/v1/',
  profilePicBaseUrl: 'https://api.myzolve.com/profile/images/',
  caseDocBaseUrl: 'https://api.myzolve.com/cases/document/',

  chatBaseUrl: 'wss://myzolve.com:8081/?AuthToken=',
  //chatFileBaseUrl: 'https://apis.myzolve.com:8081/chat-media/',
  chatFileBaseUrl:  'https://apis.myzolve.com/dist/uploads/chatmedia/',
  chatFileUploadUrl: 'https://apis.myzolve.com:8081/',
 // chatThumbBaseUrl: 'https://apis.myzolve.com:8081/chat-media/thumbs/',
 chatThumbBaseUrl:'https://apis.myzolve.com/dist/uploads/chatmedia/thumbs/',

  stripeKey: 'pk_live_ewtrmEigNJw17paoPOClfpgy',
  opentok: {
    api_key: '46800744',
    secret_key: '26938eea53a86d84da51118e18d29481093146d4'
  },

  firebase: {
    apiKey: "AIzaSyA6AGQOG9pBdb5J_U7s5EhDbfNxQ-52BXQ",
    authDomain: "myzolve-3cadf.firebaseapp.com",
    databaseURL: "https://myzolve-3cadf.firebaseio.com",
    projectId: "myzolve-3cadf",
    storageBucket: "myzolve-3cadf.appspot.com",
    messagingSenderId: "319449065662",
    appId: "1:319449065662:web:0347c7bad5819b76bed6ef",
    measurementId: "G-RQNX1E5YXN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
