import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  caseListingPage = 1;
  notificationCount: any;
  UserType: any;
  paymentCount: any;
  payments: any[];
  creditHistory: any[] = [];
  dataLoaded: boolean = false;
  showMore:boolean;
  success_message: any; error_message: any;
  alldata:any=[];
  constructor(public appService: AppService, private apiService: ApiService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute) {
      this.UserType = localStorage.getItem('userType');
      if (localStorage.getItem('userType') == 'Admin') {
        this.get_admin_all_cases_payments();
      } else {
        this.getCreditHistory();
      }
     }

  ngOnInit() {
    // this.UserType = localStorage.getItem('userType');
    // if (localStorage.getItem('userType') == 'Admin') {
    //   this.get_admin_all_cases_payments();
    // } else {
    //   this.getCreditHistory();
    // }
  }

  get_admin_all_cases_payments(page = 1, event?: any) {
    this.payments=[];
  
              this.spinner.show('payment');
              this.apiService.getAllCasesByAdminnew(this.appService.getToken(), page.toString(), '').subscribe(
                (result: any) => {
                 // this.payments = result.cases;
                  this.paymentCount = result.count;
                  this.alldata = result.cases;
                  console.log(this.alldata)
                  if(this.alldata.length > 10){
                    this.payments.push(...this.alldata.splice(0, 10));
                    this.showMore =true;
                  }else{
                    this.payments = this.alldata;
                    this.showMore =false;
                  }
                  this.dataLoaded =true;
                  this.spinner.hide('payment');
                },
                (err) => {
                  this.dataLoaded =true;
                  this.spinner.hide('payment');
                //        this.appService.showSuccessToast(res.message);
                this.appService.showErrorToast(err.error.message);
                 
                }
              );
      
       
  }


  getCreditHistory() {
    this.dataLoaded = false;
  
    this.spinner.show('payment');
            this.apiService.payment_history(localStorage.getItem('token')).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status) {
                  this.alldata = res.data;
                  if(this.alldata.length > 10){
                    this.creditHistory.push(...this.alldata.splice(0, 10));
                    this.showMore =true;
                  }else{
                    this.creditHistory =this.alldata;
                    this.showMore =false;
                  }
                  
                  //this.creditHistory = res.data;
                 
                  localStorage.setItem('credit', (res.user.credit));
                }
                this.dataLoaded = true;
                this.spinner.hide('payment');
              },
              (err: any) => {
                console.log(err);
                this.spinner.hide('payment');
                this.dataLoaded = true;
          
              }
            );
        

    
  }
  showNext(){
    console.log(this.alldata);
    if(this.creditHistory){

    
    console.log(this.alldata);
    if(this.alldata.length > 10){
      this.creditHistory.push(...this.alldata.splice(0, 10));
      this.showMore =true;
    }else{
      this.creditHistory.push(...this.alldata);
      this.showMore =false;
    }
  }else{
    if(this.alldata.length > 10){
      this.payments.push(...this.alldata.splice(0, 10));
      this.showMore =true;
    }else{
      this.payments.push(...this.alldata);
      this.showMore =false;
    }
  }

    console.log(this.creditHistory);
  }

}
