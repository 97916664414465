import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";
import { StripeService, Element as StripeElement, ElementOptions, ElementsOptions } from "@nomadreservations/ngx-stripe";

@Component({
  selector: 'app-addcase',
  templateUrl: './addcase.component.html',
  styleUrls: ['./addcase.component.css']
})
export class AddcaseComponent implements OnInit {

  price: any;
  caseType: any = 'represent';
  incidentDate: any; incidentDate_end: any;
  mIncidentDate: any; mIncidentDate_end: any;
  desired_outcome: any; mdesired_outcome: any;
  hr_notified: any = 'Yes'; m_hr_notified: any;
  management_notified: any = 'Yes'; m_management_notified: any;
  issueDetails: any;
  mIssueDetails: any;
  issueCategory: any;
  mIssueCategory: any;
  categories: any[];
  chooseFiles = new Array<any>();
  docFile: any;

  mDocDate: any; docDate: any;
  docType: any; mDocType: any;
  docRecipient: any; mDocRecipient: any;

  cardNumber: any = '4242424242424242';
  cardExpMonth: number = 12;
  cardExpYear: number = 2021;
  cardCVC: number = 220;
  m_cardNumber: any;
  m_cardExpMonth: any;
  m_cardExpYear: any;
  m_cardCVC: any;

  dataLoaded: boolean = false;

  stripeKey = '';
  error: any;
  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  isPackageTab: boolean = true;
  isCreditsTab: boolean = false;

  creditHistory: any[] = [];
  packages: any[] = [];
  packagee_error: any = '';
  packagee: any = '';
  credit: number = 0;
  credit_error: any = '';
  myCredits:any;
  label:any='Pay Now';
  constructor(private apiService: ApiService, private spinner: NgxSpinnerService, public appService: AppService,
    private stripe: StripeService) {
      this.myCredits = this.appService.getCredit();
      if(this.myCredits > 20){
        this.label = 'Proceed';
    }
    if(this.myCredits > 4 && this.caseType != 'represent'){
      this.label = 'Proceed';
  }
    if(this.myCredits < 4 && this.caseType == 'represent'){
      this.label = 'Pay Now';
    }
    if(this.myCredits < 4 && this.caseType != 'represent'){
      this.label = 'Pay Now';
    }
    if(this.myCredits < 20 && this.caseType == 'represent'){
      this.label = 'Pay Now';
  }
    this.initializeData();
  }
  ChangeCase(){
    if(this.myCredits > 20){
      this.label = 'Proceed';
  }
    if(this.myCredits < 20 && this.caseType == 'represent'){
      this.label = 'Pay Now';
  }
  if(this.myCredits > 4 && this.caseType != 'represent'){
    this.label = 'Proceed';
}
  if(this.myCredits < 4 && this.caseType == 'represent'){
    this.label = 'Pay Now';
  }
  if(this.myCredits < 4 && this.caseType != 'represent'){
    this.label = 'Pay Now';
  }
  }
  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  onCancel() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onRemoveIconClick() {
    this.chooseFiles = [];
  }

  onConfirm() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
    this.spinner.show('addcase');
    this.stripe.createToken(this.element, {
    }).subscribe(
      (res: any) => {
        console.log(res);
        if(res.error){
          this.spinner.hide('addcase');
          this.appService.showErrorToast(res.error.message);
          return false
        }else{
        console.log(res.token.id);
        if (this.isPackageTab) {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), this.packages[this.packagee - 1].cost, this.packages[this.packagee - 1].credit, res.token.id, this.packages[this.packagee - 1].name).subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              localStorage.setItem('credit', (this.packages[this.packagee - 1].credit + +this.appService.getCredit()).toString());
              this.spinner.hide('addcase');
              this.onCancel();
              this.onCancelPopup();
              this.onPayBtnClick();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('addcase');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        } else {
          this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), (this.credit * 19.99), this.credit, res.token.id, 'no_package').subscribe(
            (res: any) => {
              console.log(res);
              this.appService.showSuccessToast(res.message);
              var credit = +this.credit + +this.appService.getCredit();
              localStorage.setItem('credit', credit.toString());
              this.spinner.hide('addcase');
              this.onCancelPopup();
              this.onCancel();
              this.onPayBtnClick();
            },
            (err: any) => {
              console.log(err);
              this.spinner.hide('addcase');
              this.onCancelPopup();
              this.onCancel();
            }
          );
        }
      }
      },
      (err) => {
        console.log(err);
        this.spinner.hide('addcase');
        this.onCancelPopup();
        this.onCancel();
      }
    );
  }

  onCancelPopup() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }


  resetFields() {
    this.issueCategory = '';
    this.incidentDate = undefined;
    this.incidentDate_end = undefined;
    this.issueDetails = '';
    this.desired_outcome = '';
  }

  onPayBtnClick() {
    if (this.validateFields()) {
      var caseType = this.caseType;
      var amount = 1;
      if (caseType == 'represent') {
        amount = 20;
      } else {
        amount = 4;
      }
      if (+this.appService.getCredit() >= amount) {
        this.spinner.show('addcase');
        this.apiService.creditTransaction(this.appService.getToken(), amount, 75, 'add case').subscribe(
          (res: any) => {
            console.log(res);
            if (res.status) {
              this.apiService.addNewCase(this.appService.getToken(), this.caseType, this.issueCategory, this.incidentDate, this.issueDetails,
                this.incidentDate_end, this.management_notified, this.hr_notified, this.desired_outcome, this.docRecipient, this.docDate, this.docType, this.appService.getChatRoomName()).subscribe(
                  (res: any) => {
                    console.log(res.case.id);
                    if (this.chooseFiles.length !== 0) {
                      this.uploadDocument(res);
                    } else {
                      this.resetFields();
                      var credit = +this.appService.getCredit() - amount;
                      localStorage.setItem('credit', credit.toString());
                      this.spinner.hide('addcase');
                      this.appService.showSuccessToast('Case added successfully');
                    }
                  },
                  (err: any) => {
                    console.log(err.error);
                    this.spinner.hide('addcase');
                    this.appService.showErrorToast('Error!');
                  }
                );
            }
          },
          (err: any) => {
            console.log(err);
          }
        );

      } else {
        this.appService.showErrorToast('Your credit is not enough to add new case.');
        this.onAddCreditBtnClick();

      }
    }

  }

  onAddCreditBtnClick() {
    $("#buy-credit-popup").addClass("show");
    $("#buy-credit-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.getPackages();
  }

  getPackages() {
    this.spinner.show('addcase');
    this.apiService.getCreditPackages(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.packages = res.data;
          this.packagee = this.packages[0].id;

        }
        this.dataLoaded = true;
        this.spinner.hide('addcase');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('addcase');
      }
    );
  }

  onBuyNowBtnClick() {
    console.log(this.packagee);
    this.credit_error = '';
    if (this.isPackageTab) {
      this.onCancelBtnClick();
      $("#card-popup").addClass("show");
      $("#card-popup").css("display", "block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      if (this.credit > 0) {
        this.onCancelBtnClick();

        $("#card-popup").addClass("show");
        $("#card-popup").css("display", "block");
        $("body").append("<div class='modal-backdrop fade show'></div>");
      } else {
        this.credit_error = 'Invalid credit';
      }
    }
  }


  onCancelBtnClick() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onUploadIconClick(ev: any) {
    console.log(ev.target.files);
    this.chooseFiles = ev.target.files;
  }

  onPacakgeTabClick() {
    this.isPackageTab = true;
    this.isCreditsTab = false;
  }

  onCreditsTabClick() {
    this.isCreditsTab = true;
    this.isPackageTab = false;
  }

  uploadDocument(res: any) {
    let formData = new FormData();
    formData.append('case_id', res.case.id);
    for (const file of this.chooseFiles) {
      console.log(file);
      formData.append('document_name', file.name);
      formData.append('document', file, file.name);
      if (this.appService.getUserType() == 'User') {
        formData.append('counselorId', res.case.advisor.id);
        formData.append('userId', this.appService.getProfile().id);
      } else {
        formData.append('userId', res.case.user.id);
        formData.append('counselorId', this.appService.getProfile().id);
      }
    };
    this.apiService.uploadCaseDocument(localStorage.getItem('token'), formData).subscribe(
      (result: any) => {
        console.log(result);
        this.resetFields();
        this.spinner.hide('addcase');
        this.appService.showSuccessToast('Case added successfully');

        this.onRemoveIconClick();
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('addcase');

        this.onRemoveIconClick();
      }
    );
  }

  ngOnInit() {

  }

  initializeData() {
    this.spinner.show('addcase');
    this.apiService.getCasePrice(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.price = res.price;
        }
        this.apiService.getCaseCategories(this.appService.getToken()).subscribe(
          (res: any) => {
            console.log(res);
            this.categories = res.categories;
            this.issueCategory = this.categories[0].id;
            this.spinner.hide('addcase');
          },
          (err: any) => {
            console.log(err.error);
            this.spinner.hide('addcase');
          }
        );
      },
      (err) => {
        console.log(err.error);
        this.spinner.hide('addcase');
      }
    );
  }

  validateFields() {
    this.mIssueDetails = undefined; this.mIssueCategory = undefined; this.mIncidentDate_end = undefined;
    this.mIncidentDate = undefined; this.mdesired_outcome = undefined; this.mDocDate = undefined;
    this.mDocRecipient = undefined; this.mDocType = undefined;

    if (this.caseType == 'represent') {

      if (this.incidentDate === undefined) {
        this.mIncidentDate = 'Please Choose Date';
        return false;
      }
      if (this.incidentDate_end === undefined) {
        this.mIncidentDate_end = 'Please Choose End Date';
        return false;
      }
      if (this.issueCategory === undefined) {
        this.mIssueCategory = 'Please Choose Category';
        return false;
      }
      if (this.issueDetails === undefined || this.issueDetails === '') {
        this.mIssueDetails = 'Add Issue Details';
        return false;
      }
      if (this.desired_outcome === undefined || this.desired_outcome === '') {
        this.mdesired_outcome = 'Add Issue Desired Outcome';
        return false;
      }
      return true;

    } else if (this.caseType == 'document') {

      if (this.docDate === undefined) {
        this.mDocDate = 'Please Choose Document Date';
        return false;
      }
      if (this.docType === undefined) {
        this.mDocType = 'Please add doc type';
        return false;
      }
      if (this.issueCategory === undefined) {
        this.mIssueCategory = 'Please Choose Category';
        return false;
      }
      if (this.issueDetails === undefined || this.issueDetails === '') {
        this.mIssueDetails = 'Add Issue Details';
        return false;
      }
      if (this.docRecipient === undefined) {
        this.mDocRecipient = 'Choose Document Recipient';
        return false;
      }
      return true;

    } else if (this.caseType == 'issue') {

      if (this.incidentDate === undefined) {
        this.mIncidentDate = 'Please Choose Date';
        return false;
      }
      if (this.incidentDate_end === undefined) {
        this.mIncidentDate_end = 'Please Choose End Date';
        return false;
      }
      if (this.issueCategory === undefined) {
        this.mIssueCategory = 'Please Choose Category';
        return false;
      }
      if (this.issueDetails === undefined || this.issueDetails === '') {
        this.mIssueDetails = 'Add Issue Details';
        return false;
      }
      if (this.desired_outcome === undefined || this.desired_outcome === '') {
        this.mdesired_outcome = 'Add Issue Desired Outcome';
        return false;
      }
      return true;

    }
  }
}
