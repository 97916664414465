import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-case-chat',
  templateUrl: './case-chat.component.html',
  styleUrls: ['./case-chat.component.css']
})
export class CaseChatComponent implements OnInit {

  videoUrl: any;
  url: any = 'assets/images/empty-image.png';
  previewType: any = '';
  messagesCount: any;
  notificationCount: any;
  advisorName: any;
  messages = [];
  sender_profile_pic: any;
  conversation = [];
  message: any = '';
  messageListingPage = 1;
  thumbBaseUrl: any = environment.chatThumbBaseUrl;
  fileBaseUrl = environment.chatFileBaseUrl;
  socket: any;
  typePlaceholder: any = 'Please wait for user';
  isSendMsg: boolean = true;
  isRequest: boolean = false;

  timeInterval: any;
  timer: any = {
    min: 14,
    sec: 59
  };

  user = {
    userId: '',
    userName: '',
    userTopic: '',
    userProfilePic: undefined
  };
  caseId: any;

  // recipient data
  userId: any = '';
  userTopic: any = '';
  userImage: any = '';
  userName: any = '';
  file: any;
  formData: any;

  msg_error: any;
  constructor(private router: Router, public route: ActivatedRoute, private spinner: NgxSpinnerService,
    private apiService: ApiService, public appService: AppService) {

  }

  onEndChatBtnClick() {
    const data = { "cmd": "end-chat", "data": { "chatRequestId": this.route.snapshot.queryParams.chat_req_id, "recipient": this.route.snapshot.queryParams.recp_id } };
    this.socket.send(JSON.stringify(data));
    this.router.navigate(['/chat-listing']);
  }

  onClipIconClick(event: any) {
    this.url = 'assets/images/empty-image.png';
    console.log(event);
    let type: string = event.target.files[0].type.toString();
    console.log(type);
    if (type.search('image') == 0) {
      this.previewType = 'image';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (ev: any) => { // called once readAsDataURL is completed
          this.url = ev.target.result;
        }
      }
    } else if (type.search('video') == 0) {
      this.previewType = 'video';
      this.url = 'assets/images/video-preview.png';
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onloadend = (ev: any) => { // called once readAsDataURL is completed
          this.videoUrl = ev.target.result;
        }
      }
    } else {
      this.url = 'assets/images/doc-preview.png';
    }

    console.log(event);
    console.log('ajskdlg');
    console.log(event.target.files[0]);
    this.file = event.target.files[0];
    // this.uploadFiles(ev.target.files[0]);

    // this.appService.setDoc(ev.target.files[0]);
    this.formData = new FormData();
    // console.log(this.recpId);
    this.formData.append('recipient', this.user.userId);
    this.formData.append('userType', this.appService.getUserType());
    this.formData.append('media-file', this.file, this.file.name);
    this.formData.append('caseId', this.caseId);
  }

  onPreviewClick() {
    // window.open(this.url, "_blank");
    // if (this.url != 'assets/images/doc-preview.png') {
    //   if (this.previewType == 'image') {
    //     // this.showPreview(this.url);
    //     window.open(this.url, '_blank');
    //   } else {
    //     // this.showPreview(undefined, this.videoUrl);
    //     // window.open(this.videoUrl, '_system');
    //     // this.previewAnyFile.preview(this.videoUrl)
    //     //   .then((res: any) => console.log(res))
    //     //   .catch((error: any) => console.error(error));
    //   }
    // }
  }

  onRemovePreviewClick() {
    this.file = undefined;
    var input = document.getElementsByTagName('input')[0];
    console.log(input);
    input.value = null;
  }

  uploadAttachment() {
    this.spinner.show('case-chat');
    this.formData.append('msg', this.message);
    this.apiService.uploadCaseChatAttachment(this.appService.getToken(), this.formData).subscribe(
      (result: any) => {
        if (result.status) {
          const msg = result.message;
          const data = { "cmd": "message", "data": msg };
          this.socket.send(JSON.stringify(data));
          this.file = undefined;
          this.message = '';
          // this.socket.onmessage = (res: any) => {
          //   var new_res = JSON.parse(res.data);
          //   console.log('res.data  -------------------');
          //   console.log(new_res);
          // }
        }
        // this.conversation.push({ message:result.message.media.filename,messageType: result.message.messageType, class: 'right' });
        // result.message.class = 'right';
        this.messages.push(result.message);
        console.log(result);
        console.log(this.conversation);
        // loader.dismiss();
        this.spinner.hide('case-chat');
      },
      (err) => {
        console.log(err.error);
        // loader.dismiss();
        this.spinner.hide('case-chat');
      }
    );
  }

  ngOnInit() {
    this.user = {
      userId: this.route.snapshot.queryParams.user_id,
      userName: this.route.snapshot.queryParams.user_name,
      userProfilePic: this.route.snapshot.queryParams.user_profile_pic,
      userTopic: this.route.snapshot.queryParams.user_topic
    };
    this.caseId = this.route.snapshot.queryParams.case_id;
    console.log(this.user);
    console.log(this.caseId);

    this.openConnection();
    this.socket.onopen = (res: any) => {
      this.addUser();
    }
  }

  addUser() {
    if (this.appService.getUserType() === 'Counselor') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Counselor", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    } else if (this.appService.getUserType() === 'Admin') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "Admin", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    } else if (this.appService.getUserType() === 'User') {
      const userProfile = this.appService.getProfile();
      const data = {
        "cmd": "add-user", "data":
          { "name": userProfile.fullName, "userType": "User", "profilePic": userProfile.profilePic }
      };
      this.socket.send(JSON.stringify(data));
    }
  }

  openConnection() {
    this.socket = new WebSocket(environment.chatBaseUrl + localStorage.getItem('token'));
    this.socket.onmessage = (res: any) => {
      console.log(res);
      res = JSON.parse(res.data);
      if (res.cmd === 'connection') {
        this.getConversation();
      } else if (res.cmd === 'ack-user-joined-chat') {
        this.typePlaceholder = 'Type your message here';
        this.isSendMsg = false;
        if (this.isRequest) {
          this.startTimer();
        }
      } else if (res.cmd === 'end-chat') {
        // this.getSuccess(res.data.message);
        // this.navCtrl.navigateRoot('/leftmenu/tabs/chat');
      } else if (res.cmd === 'chat-history') {
        this.messages = res.data.messages;
        this.messagesCount = res.data.count;


        // console.log(this.messages);
        // if (this.messagesListingPage === 1) {
        //   this.messages.push(...res.data.messages);
        //   this.messages.reverse();
        // setTimeout(() => {
        //   this.conversationContent.scrollToBottom(300);
        //   this.conversationScroll.disabled = false;
        // }, 200);
        // } else {
        //   this.messages.unshift(...res.data.messages.reverse());
        // }
      } else if (res.cmd === 'message') {
        if (res.data.media) {
          this.messages.push(res.data);
          console.log(this.messages);
        } else {
          if (this.caseId.toString() == res.data.caase.toString()) {
            this.messages.push({ message: res.data.message, messageType: res.data.messageType, recipientId: this.appService.getProfile().id, senderId: this.user.userId });
          }
          // this.conversationContent.scrollToBottom();
        }
      }
    }
  }

  sendMessage() {
    this.msg_error = '';
    if ((this.message === '' || this.message === undefined) && !this.file) {
      this.msg_error = 'write something to send';
      return false;
    }
    if (this.file) {
      console.log('file exist');
      this.uploadAttachment();
    } else {
      this.messages.push({ message: this.message, messageType: "Text", recipientId: this.user.userId, senderId: this.appService.getProfile().id });
      console.log(this.messages);
      const data = { "cmd": "message", "data": { "messageType": "Text", "message": this.message, "senderId": this.appService.getProfile().id, "recipientId": this.user.userId.toString(), "caase": this.caseId } };
      this.socket.send(JSON.stringify(data));
      this.message = '';
    }
  }

  getConversation() {

    const data = { cmd: 'chat-history', "data": { "page": 1, "case": this.caseId } };
    this.socket.send(JSON.stringify(data));
  }

  startTimer() {
    this.timeInterval = setInterval(() => {
      --this.timer.sec;
      if (this.timer.sec == 0) {
        if (this.timer.min == 0) {
          clearInterval(this.timeInterval);
          this.showPopUp();
        } else {
          this.timer.min = this.timer.min - 1;
          this.timer.sec = 59;
        }
      }
    }, 1000);
  }

  showPopUp() {

    // this.componentService.getAlertEndChat('Your time is over.', '', false).then(
    //   (alert) => {
    //     alert.present();
    //     alert.onDidDismiss().then(
    //       (data) => {
    //         this.onEndChatBtnClick();
    //       }
    //     );
    //   }
    // );
  }

  showPicture(img: any) {
    window.open(this.fileBaseUrl + img, '_blank');
  }

  showDoc(file: any) {
    window.open(this.fileBaseUrl + file, '_blank');
  }

  async setNotificationCount() {
    const res = await this.appService.getNotificationCount();
    this.notificationCount = res.count;
  }

}
