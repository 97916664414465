import { Component, OnInit, ɵConsole, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from "jquery";
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
//import { FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angular-6-social-login';
import { NgxAutoScroll } from "ngx-auto-scroll";
import { ValidatorService } from 'src/app/services/validator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService, SocialUser, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastrService } from 'ngx-toastr';
//import { setInterval } from 'timers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(NgxAutoScroll, { static: false }) ngxAutoScroll: NgxAutoScroll;

  email: any;
  password: any;
  isLogin: boolean = false;
  isLoginPopUpShow: boolean = false;
  profileImage = '';
  userName = '';

  // success_message: any; error_message: any; login_error: any; signup_error: any;

  // signup Fields
  device_id: any;
  device_type: any;
  firstName: any;
  lastName: any;
  phone: any;
  confirmpassword: any;
  firstName_error: any;
  lastName_error: any;
  phone_error: any;
  email_error: any;
  password_error: any;
  confirmpassword_error: any;
  termsC: boolean = false;
  termsC_error: any;
  show_btn_login:any='login';
  mycredits:any='';
  constructor(private route: ActivatedRoute, public appService: AppService, private router: Router, private toastr: ToastrService,
    private apiService: ApiService, public authService: AuthService, private validatorService: ValidatorService,
    private spinner: NgxSpinnerService, private notificationService: NotificationService) {
setInterval(() => {
  var token = this.appService.getToken();
  if(token){
    this.isLogin =true;
   // if(!this.profileImage){
      this.profileImage = this.appService.getProfile().profilePic;
      this.userName = this.appService.getProfile().fullName;
      this.mycredits = this.appService.getCredit()
  //  }
  }

  if(this.router.url.includes('login')){
    this.show_btn_login ='login';
    
      }
      if(this.router.url.includes('signup')){
        this.show_btn_login ='signup';
    
          }
          if(this.router.url.includes('login-s')){
            this.show_btn_login ='signup';
        
              }
              if(!this.router.url.includes('login') && appService.getUserType() === 'Anonymous' && !this.router.url.includes('signup') && !this.router.url.includes('login-s')){
                this.show_btn_login ='signup';
                
                  }
}, 1000);



}

  onLogoutBtnClick() {
    const not_token = localStorage.getItem('notificationToken');

    this.spinner.show('header');
    this.apiService.logout(this.appService.getToken(), localStorage.getItem('notificationToken')).subscribe(
      (res: any) => {
        console.log(res);
        this.spinner.hide('header');
        if (res.status) {
          localStorage.clear();
          localStorage.setItem('notificationToken', not_token);
          this.isLogin = false;
          this.router.navigate(['/login']);
        }
      },
      (err: any) => {
        this.spinner.hide('header');
        console.log(err);
      }
    );
  }

  notificationClick() {
    this.router.navigate(['/notification']);
  }
  FeedbackClick() {
    this.router.navigate(['/feedback']);
  }
  ProfileClick() {
    this.router.navigate(['/profile']);
  }

  onLogoClick() {
    if (this.isLogin) {
      if (this.appService.getUserType() === 'Admin' || this.appService.getUserType() === 'Counselor') {
        this.router.navigate(['/chat-listing']);
      } else {
        this.router.navigate(['/case-listing']);
      }
    } else {
  //    this.router.navigate(['/counselor-home']);
  this.router.navigate(['/login']);
    }
  }
  onCloseBtnClickSignup() {
    this.resetSignupFields();
  }
  onCloseBtnClickLoginNew() {
    this.router.navigate(['/login']);
  }
  onCloseBtnClickSignuNew() {
    this.router.navigate(['/signup']);
  }
  resetSignupFields() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = '';
    this.confirmpassword = '';
    this.phone = '';
    this.termsC = false;
    this.termsC_error = '';
    this.phone_error = '';
    this.confirmpassword_error = '';
    this.password_error = '';
    this.email_error = '';
    this.lastName_error = '';
    this.firstName_error = '';
  }

  // onCasesClick() {
  //   this.router.navigate(['/case-listing']);
  // }
  // onChatClick() {
  //   this.router.navigate(['/chat-listing']);
  // }
  public gottoBottom(): void {
    this.ngxAutoScroll.forceScrollDown();
  }

  validateFields() {
    this.firstName_error = ''; this.lastName_error = '';
    this.email_error = ''; this.password_error = ''; this.confirmpassword_error = '';
    this.phone_error = ''; this.termsC_error = '';
    // this.signup_error = '';
    if (this.firstName === undefined || this.firstName === '') {
      this.firstName_error = 'First Name required';
      return false;
    }
    if (this.lastName === undefined || this.lastName === '') {
      this.lastName_error = 'Last Name required';
      return false;
    }
    if (this.phone === undefined || this.phone === '') {
      this.phone_error = 'Phone Number required';
      return false;
    }
    if (!this.validatorService.isPhoneValid(this.phone)) {
      this.phone_error = 'Enter Valid Phone Number';
      return false;
    }
    if (this.email === undefined || this.email === '') {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter Valid Email Address';
      return false;
    }
    if (this.password === undefined || this.password === '') {
      this.password_error = 'Password required';
      return false;
    }
    if (!this.validatorService.isPasswordValid(this.password)) {
      this.password_error = 'Minimum 6 Chatacter required';
      return false;
    }
    if (this.password !== this.confirmpassword) {
      this.confirmpassword_error = 'Confirm Password missmatch. Try Again';
      return false;
    }
    if (this.termsC === false) {
      this.termsC_error = 'You need to agree with our Terms & Conditions';
      return false;
    }

    return true;
  }

  onSignUpBtnClick() {
    if (this.validateFields()) {
      this.spinner.show('header');
      this.apiService.registerUser(this.firstName + ' ' + this.lastName, this.email, this.phone, this.password, localStorage.getItem('notificationToken'), 'browser', localStorage.getItem('notificationToken')).subscribe(
        (result: any) => {
          if (result.status) {
            this.appService.setToken(result.jwtoken);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                if (res.status) {
                  this.appService.setUserType('User');
                  this.isLogin = true;
                  this.profileImage = res.user.profile_pic;
                  this.userName = res.user.fullname;
                  this.appService.setProfile(res.user);
                  this.appService.setProfile_password(this.password);
                  this.spinner.hide('header');
                  console.log('asdjgkl');
                  this.router.navigate(['/home']);
                  $("#sign-popup .close").trigger('click');
                }
              },
              (err) => {
                console.log(err.error);
                this.spinner.hide('header');
                // this.signup_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              })
          } else {
            this.spinner.hide('header');
            // this.signup_error = result.message;
            this.appService.showErrorToast(result.message);
          }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('header');
          // this.signup_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }

  validateLoginFields() {
    this.email_error = ''; this.password_error = '';
    // this.login_error = '';
    if (this.email === '' || this.email === undefined) {
      this.email_error = 'Email Address required';
      return false;
    }
    if (!this.validatorService.isEmailValid(this.email)) {
      this.email_error = 'Enter valid email address';
      return false;
    }
    if (this.password === '' || this.password === undefined) {
      this.password_error = 'Password required';
      return false;
    }
    return true;
  }

  onLoginClick() {
    localStorage.clear();
   // this.router.navigate(['/home']);
    //this.router.navigate(['/counselor-home']);\
    this.router.navigate(['/login']);
  }

  onLoginBtnClick() {
    if (this.validateLoginFields()) {
      this.spinner.show('header');
      this.apiService.login(this.email, this.password, localStorage.getItem('notificationToken'), 'browser').subscribe(
        (result: any) => {
          if (result.status) {
            this.isLogin = true;
            this.appService.setToken(result.jwtoken);
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                // this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');

                  this.router.navigate(['/home']);
                  this.spinner.hide('header');

                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/chat-listing']);
                    this.spinner.hide('header');

                  } else {
                    $("#login-popup .close").trigger('click');

                    this.router.navigate(['/profile']);
                    this.spinner.hide('header');

                  }
                }
                this.email = '';
                this.password = '';
              }, (err: any) => {
                this.spinner.hide('header');
                // alert(err.error);
                // this.login_error = err.error.message;
                this.appService.showErrorToast(err.error.message);
              });
          }
        }, (err: any) => {
          this.spinner.hide('header');
          // this.login_error = err.error.message;
          this.appService.showErrorToast(err.error.message);
        }
      );
    }
  }

  socialSignIn(socialProvider: string) {
    let socialPlatformProvider;
    if (socialProvider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialProvider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.authService.signIn(socialPlatformProvider).then(socialusers => {
      $("#login-popup .close").trigger('click');
      $("#sign-popup .close").trigger('click');
      console.log('yesss');
      console.log(socialusers);
      this.register(socialusers);
    }, (err: any) => {
      console.log('errrrrrrrrr');
      console.log(err);

    });
  }

  onForgotBtnClick() {
    $("#login-popup .close").trigger('click');
    this.router.navigate(['/forgot-password']);
  }

  // onsignupbutton() {
  //   setTimeout(function(){
  //     $(".modal-backdrop").remove();
  //     $('body').addClass('modal-open');
  //   }, 100);
  // }
  // onloginbutton() {
  //   setTimeout(function(){
  //     $("#sign-popup").hide();
  //   }, 100);
  // }

  ngOnInit() {
    this.isTokenValid();
  }

  async isTokenValid() {
    if (localStorage.getItem('token')) {
      const res = await this.appService.getTokenVerificationResult();
      console.log(res);
      if (res.status) {
        this.isLogin = true;
        this.profileImage = this.appService.getProfile().profilePic;
        this.userName = this.appService.getProfile().fullName;
      } else {
        this.isLogin = false;
      }
    } else {
      this.isLogin = false;
    }
  }
  register(userData: any) {
    this.spinner.show('header');
    this.apiService.socialLogin(userData.name, 'browser', userData.email,
      '', localStorage.getItem('notificationToken'), 'browser', '', '').subscribe(
        (result: any) => {
          if (result.status) {
            this.isLogin = true;
            this.appService.setToken(result.jwtoken);
            this.appService.setFirstLogin(result.firstLogin);
            this.apiService.getUserProfile(result.jwtoken).subscribe(
              (res: any) => {
                this.appService.setUserType(result.userType);
                this.appService.setProfile(res.user);
                this.profileImage = res.user.profile_pic;
                this.userName = res.user.fullname;
                console.log(this.password);
                this.appService.setProfile_password(this.password);
                this.spinner.hide('header');
                if (result.userType == 'User') {
                  $("#login-popup .close").trigger('click');
                  this.router.navigate(['/home']);
                }
                if (result.userType == 'Counselor' || result.userType == 'Admin') {
                  if (result.firstLogin == false) {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/chat-listing']);
                  } else {
                    $("#login-popup .close").trigger('click');
                    this.router.navigate(['/profile']);
                  }
                }
                this.email = '';
                this.password = '';
              },
              (err: any) => {
                this.spinner.hide('header');
                this.appService.showErrorToast(err.error.message);
              }
            );
          }
        },
        (err: any) => {
          this.appService.showErrorToast(err.error.message);
        }
      );
  }

  buyCredits() {
    this.router.navigate(['/credit']);
  }

  transferCredits() {
    this.router.navigate(['/transfer']);
  }
  
  payments() {
    this.router.navigate(['/payment']);
  }
  onTCClick() {
    const url = 'https://app.myzolve.com/term-of-service.html';
    window.open(url, "_system", "location=yes,enableViewportScale=yes,hidden=no");
  }
  // async getSuccess(msg) {
  //   this.success_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.success_message = ''; }, 6000);
  // }
  // async getErrr(msg) {
  //   this.error_message = msg;
  //   var obj = this;
  //   setTimeout(function () { obj.error_message = ''; }, 6000);
  // }
}
