import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.css']
})
export class UserListingComponent implements OnInit {

  // Users Fields
  users = [];
  userId: any;
  user_type:any;
  userListingPage = 1;
  usersCount: any;
  userImgPath = environment.profilePicBaseUrl;
  isUsersDetailOpen: boolean;
  caseSearch = '';
  showMore: boolean = false;
  constructor(private spinner: NgxSpinnerService, private apiService: ApiService, private appService: AppService,private notificationService: NotificationService,
    private router: Router) {
     this.user_type = appService.getUserType();
  
     if( appService.getUserType() == 'Admin'){
      this.GetAllUsers(this.userListingPage);
     }else{
      this.getUsers(this.userListingPage);
     }
    
  }

  onUserCardClick(userId: any) {
    this.router.navigate(['/case-listing'], {
      queryParams: {
        user_id: userId
      }
    });
    // this.isUsersDetailOpen = false;
    // this.userId = userId;
    // console.log(userId);
    // this.getCases(1, null, userId);
  }

  ngOnInit() {
    this.notificationService.requestPermission();
  }

  getUsers(page = 1, event?: any) {

    this.spinner.show('spinner');
    if (event) {
      this.userListingPage += 1;
      console.log(this.userListingPage);
      this.apiService.getUsers(this.appService.getToken(), this.userListingPage.toString(), this.caseSearch).subscribe(
        (result: any) => {
          if(result.status){


          console.log(result.users);
          if (result.users.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.users.push(...result.users);
          console.log(this.users);
          this.usersCount = result.count;
          this.spinner.hide('spinner');
        }else{
          this.spinner.hide('spinner');
          this.usersCount = 0;
     
        }
        },
        (err) => {
          console.log(err.error);
          this.usersCount = 0;
          this.spinner.hide('spinner');
   
        }
      );
    } else {

      this.apiService.getUsers(this.appService.getToken(), page.toString(), this.caseSearch).subscribe(
        (result: any) => {
          if(result.status){
          if (result.users.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.users = result.users;
          this.usersCount = result.count;
          console.log(result);
          this.spinner.hide('spinner');
        }else{
          this.spinner.hide('spinner');
          this.usersCount = 0;
          
        }
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner');
          this.usersCount = 0;
        }
      );
    }
  }
  GetAllUsers(page = 1, event?: any) {
    this.spinner.show('spinner');
    if (event) {
      this.userListingPage += 1;
      console.log(this.userListingPage);
      this.apiService.getAllUsersByAdmin(this.appService.getToken(), this.userListingPage.toString(), this.caseSearch).subscribe(
        (result: any) => {
          console.log(result.users);
          if (result.users.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.users.push(...result.users);
          console.log(this.users);
          this.usersCount = result.count;
          this.spinner.hide('spinner');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner');
        }
      );
    } else {
      this.apiService.getAllUsersByAdmin(this.appService.getToken(), page.toString(), this.caseSearch).subscribe(
        (result: any) => {
          if (result.users.length !== 0 && result.count > 10) {
            this.showMore = true;
          } else {
            this.showMore = false;
          }
          this.users = result.users;
          this.usersCount = result.count;
          console.log(result);
          this.spinner.hide('spinner');
        },
        (err) => {
          console.log(err.error);
          this.spinner.hide('spinner');
        }
      );
    }
  }
}
