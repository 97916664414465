import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import * as $ from "jquery";
import { StripeService, Element as StripeElement, ElementOptions, ElementsOptions } from "@nomadreservations/ngx-stripe";


@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.css']
})
export class CreditComponent implements OnInit {

  creditHistory: any[] = [];
  packages: any[] = [];
  packagee_error: any = '';
  packagee: any = '';
  credit: number = 0;
  credit_error: any = '';

  isPackageTab: boolean = true;
  isCreditsTab: boolean = false;

  dataLoaded: boolean = false;

  stripeKey = '';
  error: any;
  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  constructor(private spinner: NgxSpinnerService, private apiService: ApiService,
    public appService: AppService, private stripe: StripeService) {

  }

  cardUpdated(result) {
    console.log('asdg');
    this.element = result.element;
    this.complete = result.card.complete;
    this.error = undefined;
  }

  onCancel() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onCancelPopup() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onConfirm() {
    $("#card-popup").removeClass("show");
    $("#card-popup").css("display", "none");
    $(".modal-backdrop").remove();
    this.spinner.show('credit');
    this.stripe.createToken(this.element, {
    }).subscribe(
      (res: any) => {
        console.log(res);
        if (res.error) {
          this.appService.showErrorToast(res.error.message);
          this.spinner.hide('credit');
        } else {
          if (this.isPackageTab) {
            this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), this.packages[this.packagee - 1].cost, this.packages[this.packagee - 1].credit, res.token.id, this.packages[this.packagee - 1].name).subscribe(
              (res: any) => {
                console.log(res);
                this.appService.showSuccessToast(res.message);
                localStorage.setItem('credit', (this.packages[this.packagee - 1].credit + +this.appService.getCredit()).toString());
                this.spinner.hide('credit');
                this.onCancel();
                this.onCancelPopup();
                this.getCreditHistory();
              },
              (err: any) => {
                console.log(err);
                this.spinner.hide('credit');
                this.onCancelPopup();
                this.onCancel();
              }
            );
          } else {
            this.apiService.purchaseCredit(this.appService.getToken(), this.appService.getProfile().id, this.appService.getUserType(), (this.credit * 19.99), this.credit, res.token.id, 'no_package').subscribe(
              (res: any) => {
                console.log(res);
                this.appService.showSuccessToast(res.message);
                var credit = +this.credit + +this.appService.getCredit();
                localStorage.setItem('credit', credit.toString());
                this.spinner.hide('credit');
                this.onCancelPopup();
                this.onCancel();
                this.getCreditHistory();
              },
              (err: any) => {
                console.log(err);
                this.spinner.hide('credit');
                this.onCancelPopup();
                this.onCancel();
              }
            );
          }
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide('credit');
        this.onCancelPopup();
        this.onCancel();
      }
    );
  }

  onAddCreditBtnClick() {
    $("#buy-credit-popup").addClass("show");
    $("#buy-credit-popup").css("display", "block");
    $("body").append("<div class='modal-backdrop fade show'></div>");
    this.getPackages();
  }

  onBuyNowBtnClick() {
    console.log(this.packagee);
    this.credit_error = '';
    if (this.isPackageTab) {
      this.onCancelBtnClick();
      $("#card-popup").addClass("show");
      $("#card-popup").css("display", "block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      if (this.credit > 0) {
        this.onCancelBtnClick();

        $("#card-popup").addClass("show");
        $("#card-popup").css("display", "block");
        $("body").append("<div class='modal-backdrop fade show'></div>");
      } else {
        this.credit_error = 'Invalid credit';
      }
    }
  }

  onCancelBtnClick() {
    $("#buy-credit-popup").removeClass("show");
    $("#buy-credit-popup").css("display", "none");
    $(".modal-backdrop").remove();
  }

  onPacakgeTabClick() {
    this.isPackageTab = true;
    this.isCreditsTab = false;
  }

  onCreditsTabClick() {
    this.isCreditsTab = true;
    this.isPackageTab = false;
  }

  ngOnInit() {
    this.getCreditHistory();
  }

  getCreditHistory() {
    this.dataLoaded = false;
    this.spinner.show('credit');
    this.apiService.getCreditHistoryOfUser(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.creditHistory = res.data;
          this.creditHistory.reverse();
          localStorage.setItem('credit', (res.user.credit));
        }
        this.dataLoaded = true;
        this.spinner.hide('credit');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('credit');
      }
    );
  }

  getPackages() {
    this.spinner.show('credit');
    this.apiService.getCreditPackages(this.appService.getToken()).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status) {
          this.packages = res.data;
          this.packagee = this.packages[0].id;

        }
        this.dataLoaded = true;
        this.spinner.hide('credit');
      },
      (err: any) => {
        console.log(err);
        this.dataLoaded = true;
        this.spinner.hide('credit');
      }
    );
  }

}

